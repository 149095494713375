import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Button } from "react-bootstrap";

import { Wallet, Theme, PoolList, PoolExtended, UserPool } from "../../types";
import PoolCard from "./PoolCard";
import PoolRow from "./PoolRow";

import { PoolBalance } from "../../helper/crypto";
import * as actions from "../../redux/actions";
import { FromDecimals } from "../../helper/decimals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faList,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Utils from "xdc3-utils";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import { RoundUptoSignificant } from "../../helper/constant";
// import Skeleton from "react-loading-skeleton";
import { SEO } from "../common/SEO";


const View = [{ value: "card" },{ value: "row" }];

interface PropsInterface extends actions.ActionsInterface {
  wallet: Wallet;
  theme: Theme;
  poolList: PoolList;
}

interface StateInterface {
  poolWithBalance: UserPool[] | null;
  view: any;
  filter: string;
  width: number;
  height: number;
  loading: boolean;
}

class Pool extends React.Component<PropsInterface, StateInterface> {
  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      view: this.getViewCard(localStorage.getItem('POOL_VIEW')),
      filter: "",
      poolWithBalance: this.props.poolList.map((pool) => {
        return { ...pool, apy: "-", balance: null };
      }),
      width: 0,
      height: 0,
      loading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.getPoolTokens();
    this.getApy();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({
      view:this.getViewCard(localStorage.getItem('POOL_VIEW'))
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps: PropsInterface) {
    if (!_.isEqual(prevProps.wallet, this.props.wallet)) {
      this.getPoolTokens();
    }
    if (!_.isEqual(prevProps.poolList, this.props.poolList)) {
      this.setState(
        {
          poolWithBalance: this.props.poolList.map((pool) => {
            return { ...pool, apy: "-", balance: null };
          }),
        },
        () => this.getPoolTokens()
      );
    }
  }

  getViewCard(view: any) {
    if (view) {
      return View.filter(({ value }) => value === view)[0].value;
    } else {
      return View[0].value;
    }
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : "";
    return langText;
  };

  getApy() {
    const poolWithBalance = this.state.poolWithBalance?.map<UserPool>(
      (pool: UserPool, i: number): UserPool => {
        return { ...pool, apy: "50" };
      }
    );
    if (poolWithBalance) this.setState({ poolWithBalance });
  }

  getPoolTokens() {
    if (!this.props.wallet.connected) return;
    this.setState({ loading: true }, () => {
      Promise.all(this.props.poolList.map((pool) => PoolBalance(pool)))
        .then((balances) => {
          if (balances) {
            const poolWithBalance = this.props.poolList.map<UserPool>(
              (pool: PoolExtended, i: number): UserPool => {
                return {
                  ...pool,
                  apy: "50",
                  balance: RoundUptoSignificant(
                    FromDecimals(balances[i] as string)
                  ),
                };
              }
            );
            this.setState({ poolWithBalance, loading: false });
          }
        })
        .catch(console.error)
        .finally(() => this.setState({ loading: false }));
    });
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  // renderSkeletonRow() {
  //   return (
  //     <div className="pool-row-skeleton">
  //       <Skeleton count={3} height={80} width={"100%"} />
  //     </div>
  //   );
  // }

  // renderSkeltonCard() {
  //   return (
  //     <div className="pool-card">
  //       <div className="field header">
  //         <Skeleton width={150} height={40} />
  //       </div>
  //       <div>
  //         <Skeleton width={"100%"} height={40} />
  //       </div>
  //       <div>
  //         <div className="pool-card__footer">
  //           <div className="info">
  //             <Skeleton width={100} height={30} />
  //           </div>

  //           <div className="action">
  //             <Skeleton width={100} height={30} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  renderPoolList() {
    let poolList = this.state.poolWithBalance;

    if (!poolList || _.isEmpty(poolList)) {
      return "";
    }

    if (!_.isEmpty(this.state.filter)) {
      poolList = poolList.filter((pool) => {
        return (
          (pool.first.symbol).toLowerCase().includes(this.state.filter.toLowerCase()) ||
          (pool.second.symbol).toLowerCase().includes(this.state.filter.toLowerCase()) ||
          Utils.fromXdcAddress(this.state.filter) ===
          Utils.fromXdcAddress(pool.first.address) ||
          Utils.fromXdcAddress(this.state.filter) ===
          Utils.fromXdcAddress(pool.second.address)
        );
      });
    }
    if (this.state.view === "card") {
      return poolList.map((pool, i) => (
        <PoolCard
          loading={this.state.loading}
          key={`pool-card-${i}`}
          removePool={() => this.props.RemoveCustomPool(pool.address)}
          pool={pool}
        />
      ));
    } else {
      if (this.state.width >= 920) {
        return poolList.map((pool, i) => (
          <PoolRow
            loading={this.state.loading}
            key={`pool-row-${i}`}
            removePool={() => this.props.RemoveCustomPool(pool.address)}
            pool={pool}
          />
        ));
      } else {
        this.setState({ view: "card" });
      }
    }
  }


  handleViewType(type:any){
    const viewType = type;
    this.setState({
      view:viewType
    })
    localStorage.setItem('POOL_VIEW', viewType);
  }

  render() {
    this.text = GetLanguage(this.context.language);
    return (
      <div className="pool">
        <SEO
          keywords={["globiance", "globiancedex", "dex", "pool"]}
          title="Pool | GlobianceDex"
          pathSlug="pool"
          description="Join the GlobianceDEX pool will help users to search pool by token symbol or address."
        />
        <div className="action-bar">
          <div className="actions">
            <Button
              className="display-view-btn"
              onClick={() => this.handleViewType("row")}
            >
              <FontAwesomeIcon
                icon={faList}
                className={`${this.state.view === "row" ? "active" : ""}`}
              />
            </Button>

            <Button
              className="display-view-btn"
              onClick={() => this.handleViewType("card")}
            >
              <FontAwesomeIcon
                icon={faBorderAll}
                className={`${this.state.view === "card" ? "active" : ""}`}
              />
            </Button>
            <Link to="/create-pair"> {this.renderLang("create_pair")} </Link>
            <Link to="/import-pool"> {this.renderLang("import_pool")}</Link>
          </div>

          <div className="search-wrapper">
            <div className="icon-group-input">
              <FontAwesomeIcon icon={faSearch} className="fa-icon" />
              <input
                onChange={(e) => this.setState({ filter: e.target.value })}
                placeholder="Search Pool by Token Symbol or Address"
              />
            </div>
          </div>
        </div>
        <div className="pool-container">{this.renderPoolList()}</div>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  theme,
  poolList,
}: {
  wallet: Wallet;
  theme: Theme;
  poolList: PoolList;
}) {
  return { wallet, theme, poolList };
}

export default connect(mapStateToProps, actions)(Pool);
