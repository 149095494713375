import { faCube } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import react from "react";
import { DEX_WS } from "../../helper/constant";

interface StateInterface {
  blockNumber: number;
  connected: boolean;
  visible: boolean;
}

class BlockNumber extends react.Component<any, StateInterface> {
  state: StateInterface = {
    blockNumber: 0,
    connected: false,
    visible: false,
  };

  ws: WebSocket;

  interval: any;

  constructor(props: any) {
    super(props);

    this.ws = new WebSocket(DEX_WS);
  }

  componentDidMount() {
    this.initiateListener();
    this.wsReconn();
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
    this.ws.close();
  }

  initiateListener() {
    this.setState({ connected: false });

    this.ws = new WebSocket(DEX_WS);

    this.ws.onopen = () => {
      this.setState({ connected: true });
    };

    this.ws.onmessage = (ev: any) => {
      const [event, data] = JSON.parse(ev.data);
      if (event === "block-update") {
        this.setState({ blockNumber: data });
      }
    };

    this.ws.onclose = () => {
      this.setState({ connected: false });
    };
  }

  wsReconn() {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (this.ws.readyState === 3) {
        this.initiateListener();
      }
    }, 3000);
  }

  render() {
    let blockClass = "block-num";

    if (this.state.blockNumber === 0 || this.state.connected === false) {
      blockClass += " inactive";
    }

    return (
      <div className={blockClass}>
        <div
          className="blocks"
          style={
            this.state.visible === true ? { opacity: "1" } : { display: "0" }
          }
        >
          {this.state.blockNumber}
        </div>
        &nbsp;
        <FontAwesomeIcon
          onMouseEnter={() => {
            this.setState({ visible: true });
          }}
          onMouseLeave={() => {
            this.setState({ visible: false });
          }}
          icon={faCube}
          className="block-icon"
        />
      </div>
    );
  }
}

export default BlockNumber;
