import React, { Component } from "react";
import GBEX from "../../assets/dex/GBEX-lp-token.png";

class Stake extends Component {
  render() {
    return (
      <div className="stake-row-wrapper">
        <div className="stake-data">
          <div className="coin">
            <img src={GBEX} alt="" /> GBEX
          </div>
          <div className="">GBEX</div>
          <div>data 1</div>
        </div>
        <div className="stake-data">
          <div className="coin">
            <img src={GBEX} alt="" /> GBEX
          </div>
          <div className="">GBEX</div>
          <div>data 1</div>
        </div>
      </div>
    );
  }
}

export default Stake;
