import React from "react";
import { connect } from "react-redux";
import { Theme, Wallet, Lang } from "./types";
import { BrowserRouter as Router } from "react-router-dom";

import { LanguageContext } from "./Context/Language";
import LandingHeader from "./components/Landing/LandingHeader";
import Dashboard from "./components/Dashboard";
import LandingFooter from "./components/Landing/LandingFooter";

import CacheBuster from "./cacheBuster";

import {
  DEFAULT_CHAIN_ID,
  PROJECT_NAME,
  VALID_CHAIN_ID,
} from "./helper/constant";
import packageJson from "../package.json";
import { Language, DEFAULT_LANGUAGE } from "./helper/languages";
import { XdcConnect } from "xdc-connect";
import PROVIDERS from "./helper/providers";
import * as actions from "./redux/actions/index";
import { ToastContainer } from "react-toastify";
import WalletStatus from "./components/common/WalletStatus";
// import Maintenance from "./components/common/Maintenance";

interface PropsInterface extends actions.ActionsInterface {
  wallet: Wallet;
  theme: Theme;
  lang: Lang;
}

interface StateInterface {
  language: Language;
}

class App extends React.Component<PropsInterface, StateInterface> {
  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      language: DEFAULT_LANGUAGE,
    };

    this.updateLanguage = this.updateLanguage.bind(this);
  }

  updateLanguage(language: Language) {
    this.setState({ language });
  }

  renderMaintainance = () => {
    // const path = window.location.pathname;
    // if(path === '/maintenance')
    // {
    //   return(
    //     <Maintenance />
    //   )
    // }else{
      return (
        <>
            <LandingHeader />
            <Dashboard />
            <LandingFooter />
            <WalletStatus />
            </>
      )
    // }
  }


  render() {

    return (
      <div className={`${this.props.theme}-theme app`}>
        <ToastContainer />
        <XdcConnect
          showButton={false}
          addToastContainer={true}
          enabledProviders={["keystore", "privatekey", "xinpay", "dcent-inapp"]}
          defaultChainId={50}
          rpcProvider={PROVIDERS[DEFAULT_CHAIN_ID]}
          onAddressChange={(wallet) => {
            this.props.AccountChanged(wallet.address);
          }}
          onDisconnect={() => {
            this.props.WalletDisconnected();
          }}
          onConnect={(wallet) => {
            this.props.WalletConnected({
              address: wallet.address,
              chain_id: wallet.chain_id,
              loader: wallet.loader,
              provider: wallet.rpc_provider,
              valid_network: VALID_CHAIN_ID.includes(
                wallet.chain_id.toString() as any
              ),
            });
          }}
          theme={this.props.theme === "dark" ? "dark" : "light"}
        />
        <LanguageContext.Provider
          value={{
            language: this.props.lang,
            updateLanguage: this.updateLanguage,
          }}
        >
          <Router>
            {this.renderMaintainance()}
            <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }: any) => {
                if (loading) return null;
                console.log(`${PROJECT_NAME} UI Version:`, packageJson.version);
                if (!loading && !isLatestVersion) {
                  refreshCacheAndReload();
                }
                return null;
              }}
            </CacheBuster>
          </Router>
        </LanguageContext.Provider>
      </div>
    );
  }
}

function mapStateToProps({ wallet, theme, lang }: { wallet: Wallet; theme: Theme, lang: Lang }) {
  return { wallet, theme, lang };
}

export default connect(mapStateToProps, actions)(App);
