import * as React from "react";
import "./index.css";
import { widget } from "../../charting_library/charting_library.min";
import Datafeed from "./api";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export class TradingView extends React.PureComponent {
  static defaultProps = {
    interval: "240",
    width: "100%",
    containerId: "tv_chart_container",
    libraryPath: `/charting_library/`,
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "Globiance",
    userId: "Globiance",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  constructor(props) {
    super(props);

    this.Datafeed = Datafeed(this.props.address);
  }

  tvWidget = null;
  componentDidMount() {
    this.loadChart(this.props.currentPair);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentPair !== this.props.currentPair ||
      nextProps.theme !== this.props.theme
    ) {
      this.loadChart(nextProps.currentPair, nextProps.theme);
    }
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  loadChart(pair, nextTheme, address) {
    this.address = address;
    let themeBg ='#fff';
    if(this.props.theme === 'light'){
      themeBg='#fff';
    }else if(this.props.theme === 'dark'){
      themeBg="#1e1e1e";
    }else{
      themeBg='#fdf1f6';
    }
    const theme = nextTheme || this.props.theme || "light";
    const widgetOptions = {
      debug: false,
      symbol: pair,
      width: this.props.width,
      datafeed: this.Datafeed,
      interval: this.props.interval,
      timezone: "UTC",
      theme,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings", "left_toolbar"],
      enabled_features: ["study_templates"],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      overrides: {
        "mainSeriesProperties.showCountdown": true,
        "paneProperties.background": themeBg,
        "paneProperties.vertGridProperties.color": "#363c4e",
        "paneProperties.horzGridProperties.color": "#363c4e",
        "symbolWatermarkProperties.transparency": 10,
        "scalesProperties.textColor": "#AAA",
        "paneProperties.topMargin": 15,
        "paneProperties.bottomMargin": 15,
        "paneProperties.rightMargin": 5,
        "mainSeriesProperties.candleStyle.wickUpColor": "#333c8b",
        "mainSeriesProperties.candleStyle.wickDownColor": "#cb2228",
      },
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
  }

  render() {
    return (
      <div className="tradingview-widget-container">
        <div
          id={this.props.containerId}
          className={"TVChartContainer grid__item__top__xl"}
          // style={this.props.currentPair === "ETH-USDT" ? {display: "block"} : {display: "block"}}
        />
      </div>
    );
  }
}
