import { faArrowDown, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import DefaultLogo from "../../assets/img/default.png";
import Utils from "xdc3-utils";

import TokenList from "../TokenList";
import { Token, DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";

import {
  RemoveExpo,
  ROUND_DECIMALS,
  ToDecimals,
  FromDecimals,
} from "../../helper/decimals";
import * as actions from "../../redux/actions/index";
import {
  Wallet,
  Theme,
  Settings,
  SettingsModal,
  TokenList as TokenListType,
} from "../../types";
import {
  GetTknFromLiquidity,
  GeneralContractTx,
  GetLPFromTokens,
  ApproveToken,
  IsErc20Address,
} from "../../helper/crypto";
import SettingsComp from "../common/settings";
import AcknowledgeFee from "../common/acknowledgeFee";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import BlockNumber from "../common/BlockNumber";
import { SEO } from "../common/SEO";
import { ForceShowModal } from "xdc-connect";
import { RoundUptoSignificant } from "../../helper/constant";
import { RouteComponentProps, Link } from "react-router-dom";
import PinkGirl1 from '../../assets/dex/iri/landing-main-2-swap.png';


interface PropsInterface extends actions.ActionsInterface {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  history: RouteComponentProps["history"];
  tokenList: TokenListType;
}

interface StateInterface {
  firstCurrency: Token | null;
  secondCurrency: Token | null;
  showModal: boolean;
  modalContent: ReactElement | null;
  firstCurrencyAmount: string | number;
  secondCurrencyAmount: string | number;
  balance: number | null;
  approved: number | null;

  lpAmount: string;
  valid: boolean;
  error_message: string;
  button: ReactElement;
  exists: boolean;
  pairNotExist: boolean;
}

type CurrencyType = "firstCurrency" | "secondCurrency" | "lp";

class RemoveLiquidity extends React.Component<PropsInterface, StateInterface> {
  i: number = 0;
  getRateDebounce: _.DebouncedFunc<any>;
  polling_interval: any;
  static contextType = LanguageContext;
  text: any;

  constructor(props: any) {
    super(props);

    this.state = {
      firstCurrency: DEFAULT_TOKEN_LIST.filter((x) => x.symbol === "XDC")[0],
      secondCurrency: null,
      firstCurrencyAmount: 0,
      secondCurrencyAmount: 0,
      showModal: false,
      modalContent: null,
      balance: null,
      approved: null,

      lpAmount: "0",
      valid: false,
      error_message: "CONNECT WALLET",
      button: <button onClick={() => ForceShowModal()}>CONNECT WALLET</button>,
      exists: false,
      pairNotExist: false,
    };

    this.toggle = this.toggle.bind(this);
    this.showTokenList = this.showTokenList.bind(this);
    this.showSettings = this.showSettings.bind(this);
    this.removeLiquidity = this.removeLiquidity.bind(this);
    this.approve = this.approve.bind(this);
    this.onClickAcknowledgeFee = this.onClickAcknowledgeFee.bind(this);
    this.selectToken = this.selectToken.bind(this);
    this.loadToken = this.loadToken.bind(this);
    this.getRateDebounce = _.debounce(this.getRate, 500);
  }

  componentDidMount() {
    this.selectToken("firstCurrency")(
      this.props.tokenList.filter((x) => x.symbol === "XDC")[0],
      this.loadToken
    );
    if (this.state.firstCurrency && this.state.secondCurrency) {
      this.updateBalance();
    } else {
      this.checkValidity();
    }
  }

  componentDidUpdate(prevProps: PropsInterface) {
    if (!_.isEqual(prevProps.wallet, this.props.wallet)) {
      if (this.state.firstCurrency && this.state.secondCurrency) {
        this.updateBalance();
      } else {
        this.checkValidity();
      }
    }
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : "";
    return langText;
  };

  toggle() {
    const first = this.state.firstCurrency;
    const second = this.state.secondCurrency;
    const firstAmount = this.state.firstCurrencyAmount;
    const secondAmount = this.state.secondCurrencyAmount;
    this.setState(
      {
        firstCurrency: second,
        secondCurrency: first,
        firstCurrencyAmount: secondAmount,
        secondCurrencyAmount: firstAmount,
      },
      () => {
        this.calculateRate();
        if (this.state.firstCurrency && this.state.secondCurrency) {
          this.updateBalance();
        }
      }
    );
  }

  showSettings() {
    const settings: ReactElement = (
      <SettingsComp
        deadline={this.props.settings.deadline}
        slippage={this.props.settings.slippage}
        cb={(settings: SettingsModal) => this.updateSettings(settings)}
      />
    );
    this.setState({ showModal: true, modalContent: settings });
  }

  calculateRate() {
    if (this.state.firstCurrency == null || this.state.secondCurrency == null)
      return;

    this.getRateDebounce();
  }

  showTokenList(disabled: string[] = [], cb: (token: Token) => void) {
    const tokenList: ReactElement = (
      <TokenList disabled={disabled} onTokenSelect={cb} />
    );
    this.setState({ showModal: true, modalContent: tokenList });
  }

  updateSettings(settings: SettingsModal) {
    this.props.UpdateSettings(settings);
    this.setState({
      showModal: false,
    });
  }

  onClickAcknowledgeFee(token: Token, dontShowAgain: boolean) {
    if (dontShowAgain) {
      this.props.AcknowledgeFee(token.symbol);
    }
    this.showSettings();
  }

  renderAcknowledgeFee(token: Token) {
    this.setState({
      showModal: true,
      modalContent: (
        <AcknowledgeFee
          theme={this.props.theme}
          token={token}
          cb={this.onClickAcknowledgeFee}
        />
      ),
    });
  }

  updateBalance() {
    if (!this.state.firstCurrency || !this.state.secondCurrency) return;
    GetLPFromTokens(
      this.state.firstCurrency.address,
      this.state.secondCurrency.address
    )
      .then(([balance, approved]) => {
        if (balance) {
          this.setState(
            {
              balance,
              approved,
            },
            this.checkValidity
          );
        } else this.checkValidity();
      })
      .catch(console.error);
  }

  getLPBalance() {
    if (!this.props.wallet.connected) return;
    this.updateBalance();
  }

  getRate() {
    if (this.state.firstCurrency == null || this.state.secondCurrency == null)
      return;

    GetTknFromLiquidity(
      ToDecimals(this.state.lpAmount),
      this.state.firstCurrency.address,
      this.state.secondCurrency.address
    )
      .then(([exists, tokenAmountA, tokenAmountB]) => {
        if (
          tokenAmountA &&
          tokenAmountB &&
          this.state.firstCurrency &&
          this.state.secondCurrency
        ) {
          this.setState(
            {
              firstCurrencyAmount: FromDecimals(
                tokenAmountA,
                this.state.firstCurrency.decimals
              ),
              secondCurrencyAmount: FromDecimals(
                tokenAmountB,
                this.state.secondCurrency.decimals
              ),
              exists: true,
            },
            () => {
              this.checkValidity();
              this.getRateDebounce();
            }
          );
        } else {
          this.setState(
            {
              firstCurrencyAmount: 0,
              secondCurrencyAmount: 0,
              exists,
            },
            () => this.checkValidity()
          );
        }
      })
      .catch(console.error);
  }

  loadToken() {
    const location = this.props.history.location;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const tokenLoadList = [];

    let firstSelected = false;

    if (params.inputCurrency) {
      let isDefault: boolean = false;
      for (const token of this.props.tokenList) {
        if (
          params.inputCurrency.toLowerCase() === token.symbol.toLowerCase() ||
          params.inputCurrency.toLowerCase() === token.address.toLowerCase()
        ) {
          this.selectToken("firstCurrency")(token);
          isDefault = true;
          firstSelected = true;
          break;
        }
      }
      if (isDefault === false && Utils.isAddress(params.inputCurrency)) {
        tokenLoadList.push(params.inputCurrency);
      }
    }
    if (params.outputCurrency) {
      let isDefault: boolean = false;
      for (const token of this.props.tokenList) {
        if (
          params.outputCurrency.toLowerCase() === token.symbol.toLowerCase() ||
          params.outputCurrency.toLowerCase() === token.address.toLowerCase()
        ) {
          this.selectToken("secondCurrency")(token);
          isDefault = true;
          break;
        }
      }
      if (isDefault === false && Utils.isAddress(params.outputCurrency)) {
        tokenLoadList.push(params.outputCurrency);
      }
    }

    // show confirmation modal for new tokens - tokenLoadList

    if (tokenLoadList.length > 0) {
      Promise.all(tokenLoadList.map((address) => IsErc20Address(address))).then(
        (resp) => {
          const relevantToken = resp.filter(({ isErc20 }) => isErc20);
          if (relevantToken.length > 0) {
            const modalContent = (
              <div className="token-load-confirm">
              <div className={`${this.props.theme}`}>
                {/* <div className="note">{this.renderLang("note")}</div> */}
                <div className="load-token">{this.renderLang("loadToken")}</div>
                <div className="list">
                  {relevantToken.map((x, i) => (
                    <li key={`token-list-confirm-${x.name}-${i}`}>
                      <img className="logo" src={DefaultLogo} alt={x.symbol} />
                      <div className="body">
                        <div className="symbol">{x.symbol}</div>
                        <div className="name">{x.name}</div>
                      </div>
                    </li>
                  ))}
                </div>
                <div className="footer">
                  <button
                    onClick={() => {
                      relevantToken.forEach((token) => {
                        if (
                          token.name &&
                          token.symbol &&
                          token.address &&
                          token.decimals
                        ) {
                          const _token = {
                            name: token.name,
                            symbol: token.symbol,
                            address: token.address,
                            decimals: parseInt(token.decimals),
                            logo: DefaultLogo,
                          };

                          if (
                            params.inputCurrency.toLowerCase() ===
                            token.address.toLowerCase()
                          ) {
                            this.selectToken("firstCurrency")(_token);
                            firstSelected = true;
                          } else if (
                            params.outputCurrency.toLowerCase() ===
                            token.address.toLowerCase()
                          ) {
                            this.selectToken("secondCurrency")(_token);
                          }
                          this.props.AddCustomToken(_token);
                        }
                      });
                      this.setState({ showModal: false });
                    }}
                  >
                    {this.renderLang("import")}
                  </button>
                </div>
                </div>
              </div>
            );

            this.setState({ modalContent, showModal: true });
          }
        }
      );
    }

    if (firstSelected === false) {
      this.selectToken("firstCurrency")(
        this.props.tokenList.filter((x) => x.symbol === "XDC")[0]
      );
    }
  }

  selectToken = (type: CurrencyType) => (token: Token, cb?: any) => {
    const q: any = {
      showModal: false,
    };
    if (type === "firstCurrency") {
      if (this.state.secondCurrency?.symbol === token.symbol) {
        this.setState({ ...q }, () => {
          this.toggle();
          if (cb) cb();
        });
        return;
      }
    } else {
      if (this.state.firstCurrency?.symbol === token.symbol) {
        this.setState({ ...q }, () => {
          this.toggle();
          if (cb) cb();
        });
        return;
      }
    }
    q[type] = token;
    this.setState({ ...q }, () => {
      this.getRateDebounce.cancel();
      this.calculateRate();
      if (type === "firstCurrency") {
        this.updateBalance();
      } else this.checkValidity();
      if (cb) cb();
      if (
        token.feeOnTransfer &&
        !this.props.settings.acknowledgedFee.includes(token.symbol)
      ) {
        this.renderAcknowledgeFee(token);
      }
    });
  };

  renderTokenSelect(selected: Token | null, type: CurrencyType): ReactElement {
    const cb = (token: Token) => {
      const q: any = {
        showModal: false,
      };

      if (type === "firstCurrency") {
        if (this.state.secondCurrency?.symbol === token.symbol) {
          this.setState({ ...q }, () => this.toggle());
          return;
        }
      } else {
        if (this.state.firstCurrency?.symbol === token.symbol) {
          this.setState({ ...q }, () => this.toggle());
          return;
        }
      }

      q[type] = token;
      this.setState({ ...q }, () => {
        this.calculateRate();
        this.updateBalance();
        if (
          token.feeOnTransfer &&
          !this.props.settings.acknowledgedFee.includes(token.symbol)
        ) {
          this.renderAcknowledgeFee(token);
        }
      });
    };

    if (!selected)
      return (
        <div
          className="select-token"
          onClick={() => this.showTokenList(undefined, cb)}
        >
          <div className="token-name">
            {this.renderLang("select_token")}
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      );

    return (
      <div
        className="select-token"
        onClick={() => this.showTokenList([selected.symbol], cb)}
      >
        <div className="token-badge">
          <div className="token-name">
            <img src={selected.logo} alt={selected.symbol} />
            {selected.symbol}
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      </div>
    );
  }

  updateSwapAmount(type: CurrencyType, amount: string) {
    amount = amount.replace(/^0+/, "");
    amount = amount.replace(/^\./, "0.");

    if (_.isEmpty(amount)) amount = "0";

    if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]*)$/g.test(amount) === false) {
      return;
    }
    const q: any = {};
    q[`${type}Amount`] = amount;
    this.setState({ ...q }, () => {
      this.calculateRate();
      this.checkValidity();
    });
  }

  approve() {
    if (
      !this.props.wallet.address ||
      !this.state.firstCurrency ||
      !this.state.secondCurrency
    )
      return;

    ApproveToken(
      this.state.firstCurrency.address,
      this.state.secondCurrency.address
    )
      .then(() => this.updateBalance())
      .catch(console.error);
  }

  checkValidity() {
    if (this.props.wallet.connected === false)
      return this.setState({
        button: (
          <button onClick={() => ForceShowModal()}>
            {this.renderLang("landing_header_connect_button")}
          </button>
        ),
      });

    if (!this.state.firstCurrency || !this.state.secondCurrency)
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("select_pair")}
          </button>
        ),
      });

    if (
      this.state.balance &&
      this.state.balance < parseFloat(ToDecimals(this.state.lpAmount))
    )
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("insufficient_bal")}
          </button>
        ),
      });

    if (parseFloat(this.state.lpAmount) === 0) {
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("enter_valid_amt")}
          </button>
        ),
      });
    }

    if (this.state.exists === false)
      return this.setState({
        button: (
          <>
            <button className={"disabled"}>
              {this.renderLang("pair_not_exists")}
            </button>
            <div className="create-pair-link">
              <Link
                to={`/create-pair?outputCurrency=${this.state.secondCurrency?.symbol}&inputCurrency=${this.state.firstCurrency?.symbol}`}
              >
                Create this Pair{" "}
              </Link>
            </div>
          </>
        ),
        pairNotExist: true,
      });

    if (
      this.state.approved !== null &&
      parseFloat(ToDecimals(this.state.lpAmount)) > this.state.approved
    ) {
      this.setState({
        button: (
          <button onClick={this.approve} className={""}>
            {this.renderLang("approve")}
          </button>
        ),
      });
      return;
    }

    this.setState({
      button: (
        <button onClick={this.removeLiquidity} className={""}>
          {this.renderLang("remove")}
        </button>
      ),
    });
  }

  removeLiquidity() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.props.wallet.address
    )
      return;

    const deadline =
      parseFloat((Date.now() / 1000).toFixed(0)) + this.props.settings.deadline;
    const lpAmount = ToDecimals(this.state.lpAmount);

    if (
      this.state.firstCurrency.symbol === "XDC" ||
      this.state.secondCurrency.symbol === "XDC"
    ) {
      // method = removeLiquidityETH; payable
      let method = "removeLiquidityETH";
      let minToken, minEth, address;

      if (this.state.firstCurrency.symbol === "XDC") {
        minEth = ToDecimals(
          (parseFloat(this.state.firstCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100
        );

        minToken = ToDecimals(
          (parseFloat(this.state.secondCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100,
          this.state.secondCurrency.decimals
        );

        address = this.state.secondCurrency.address;
      } else {
        minEth = ToDecimals(
          (parseFloat(this.state.secondCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100
        );

        minToken = ToDecimals(
          (parseFloat(this.state.firstCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100,
          this.state.firstCurrency.decimals
        );

        address = this.state.firstCurrency.address;
      }

      if (
        this.state.firstCurrency.feeOnTransfer ||
        this.state.secondCurrency.feeOnTransfer
      ) {
        method = "removeLiquidityETHSupportingFeeOnTransferTokens";
      }

      GeneralContractTx("router", method, [
        address,
        lpAmount,
        minToken,
        minEth,
        this.props.wallet.address,
        deadline,
      ])
        .then(() => this.updateBalance())
        .catch(console.log);
    } else {
      // method = removeLiquidity
      const method = "removeLiquidity";
      const deadline =
        (Date.now() / 1000).toFixed(0) + this.props.settings.deadline;
      const minA = ToDecimals(
        (parseFloat(this.state.firstCurrencyAmount as string) *
          (100 - this.props.settings.slippage)) /
          100,
        this.state.firstCurrency.decimals
      );
      const minB = ToDecimals(
        (parseFloat(this.state.secondCurrencyAmount as string) *
          (100 - this.props.settings.slippage)) /
          100,
        this.state.secondCurrency.decimals
      );

      GeneralContractTx("router", method, [
        this.state.firstCurrency.address,
        this.state.secondCurrency.address,
        lpAmount,
        minA,
        minB,
        this.props.wallet.address,
        deadline,
      ])
        .then(() => this.updateBalance())
        .catch(console.log);
    }
  }

  renderInverseRate() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.state.firstCurrencyAmount ||
      !this.state.secondCurrencyAmount
    )
      return "";

    return (
      <>
        <div className="rate">
          1 {this.state.secondCurrency.symbol}&nbsp;&#8776;&nbsp;
          {RemoveExpo(
            (
              parseFloat(this.state.firstCurrencyAmount + "") /
              parseFloat(this.state.secondCurrencyAmount + "")
            ).toFixed(ROUND_DECIMALS)
          )}{" "}
          {this.state.firstCurrency.symbol}
        </div>
      </>
    );
  }

  renderBalanceInput() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.state.balance
    )
      return "";

    return (
      <>
        <div className="token-input">
          <div className="token-select lp-amt">
            {this.renderLang("lp_token_bal")}
          </div>
          <div className="token-amount">
            <div className="amount">
              <input
                className="form-control"
                disabled={true}
                value={RoundUptoSignificant(FromDecimals(this.state.balance))}
              />
            </div>
            <div className="sub-text"></div>
          </div>
        </div>
        <div className="token-input active-rmv">
          <div className="token-select lp-amt">
            {this.renderLang("lp_token_remove")}
          </div>
          <div className="token-amount">
            <div className="amount">
              <input
                autoFocus
                className="form-control"
                onChange={(e) => this.updateSwapAmount("lp", e.target.value)}
                value={this.state.lpAmount}
              />
            </div>
            <div className="sub-text"></div>
          </div>
        </div>
      </>
    );
  }

  render() {
    this.text = GetLanguage(this.context.language);

    return (
      <div className="dashboard">
        <div className="swap">
          <SEO
            keywords={["globiance", "globiancedex", "dex", "pool", "remove"]}
            title="Remove Liquidity From Pool | GlobianceDEX"
            pathSlug="/remove"
            description="The GlobianceDEX will allow you to add liquidity to a pool and the community can create new pools, add liquidity, and remove liquidity at any time."
          />
          <div className="header">
            <div className="text">
              <div className="text__primary">
                {this.renderLang("remove_liquidity")}
              </div>
              <div className="text__secondary">
                {this.renderLang("remove_liquidity_pool")}
              </div>
            </div>

            <div className="settings">
              <FontAwesomeIcon
                onClick={() => this.showSettings()}
                icon={faCog}
              />
            </div>
          </div>

          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.firstCurrency,
                "firstCurrency"
              )}
            </div>
            <div className="token-amount">
              <div className="amount">
                <input
                  disabled={true}
                  className="form-control"
                  onChange={(e) => {
                    this.updateSwapAmount("firstCurrency", e.target.value);
                  }}
                  value={this.state.firstCurrencyAmount}
                />
              </div>
              <div className="sub-text"></div>
            </div>
          </div>

          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.secondCurrency,
                "secondCurrency"
              )}
            </div>
            <div className="token-amount">
              <div className="amount">
                <input
                  disabled={true}
                  className="form-control"
                  onChange={(e) => {
                    this.updateSwapAmount("secondCurrency", e.target.value);
                  }}
                  value={this.state.secondCurrencyAmount}
                />
              </div>
              <div className="sub-text"></div>
            </div>
          </div>
          <div>{this.renderBalanceInput()}</div>
          {/* <div className="inverse-rate">{this.renderInverseRate()}</div> */}
          <div className="inverse-rate"></div>
          <div className="submit">{this.state.button}</div>
          <Modal
            className="u-fit-modal custom-modal-1"
            centered={true}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Body>{this.state.modalContent}</Modal.Body>
          </Modal>
          <BlockNumber />
        </div>
        <div className='landing-main-2' id="swap-pinkgirl">
          <img src={PinkGirl1} alt="" />
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  theme,
  settings,
  tokenList,
}: {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  tokenList: TokenListType;
}) {
  return { wallet, theme, settings, tokenList };
}

export default connect(mapStateToProps, actions)(RemoveLiquidity);
