import { useContext } from "react";
import { Link } from "react-router-dom";
import Utils from "xdc3-utils"
import { UserPool } from "../../types";
import { ChainIdToExplorer, DEFAULT_CHAIN_ID } from "../../helper/constant";

import PairLogo from "../common/PairLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
export interface PoolDetails {
  pool: UserPool;
  loading?: boolean;
  removePool: () => {};
}

function PoolRow(props: PoolDetails) {
  const language = useContext(LanguageContext);
  const text: any = GetLanguage(language.language);

  return (
    <div className="pool-row">
      <div className="field">
        <PairLogo
          frontImage={props.pool.first.logo}
          backImage={props.pool.second.logo}
        />
        <div className="pair">
          {props.pool.first.symbol}-{props.pool.second.symbol}
        </div>
      </div>

      <div className="field">
        {props.loading ? "..." : props.pool.balance || "-"}
      </div>

      <div className="field">
        <a
          className="btn pair-btn"
          target="_blank"
          rel="noreferrer"
          href={`${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/tokens/${Utils.toXdcAddress(props.pool.address)}`}
        >
          {text.pair_contract}
        </a>
      </div>

      <div className="field">
        <Link className="btn info-btn" to={`/info/pool/${Utils.toXdcAddress(props.pool.address)}`}>
          {text.info}
        </Link>
      </div>
      <div className="field">
        <Link className="btn info-btn" to={`/info/manage/${Utils.toXdcAddress(props.pool.address)}`} >
          {text.manage}
        </Link>
      </div>
      <div className="field">
        {props.pool.custom ? (
          <div className="action" onClick={() => props.removePool()}>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PoolRow;
