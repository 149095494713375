import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { toast } from "react-toastify";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";

import TokenList from "../TokenList";
import * as actions from "../../redux/actions/index";
import { Wallet, Theme, Settings, PoolList, Token } from "../../types";

import { CheckPairExists } from "../../helper/crypto";
import Utils from "xdc3-utils";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import { SEO } from "../common/SEO";
import TokenAddress from "../common/TokenAddress";
import { Link } from "react-router-dom";
import PinkGirl1 from '../../assets/dex/iri/landing-main-2-swap.png';

interface PropsInterface extends actions.ActionsInterface {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  poolList: PoolList;
}

interface StateInterface {
  firstCurrency: Token | null;
  secondCurrency: Token | null;
  showModal: boolean;
  modalContent: ReactElement | null;
  error_message: string;
  button: ReactElement;
  exists: boolean;
  pairAddress: string | null;
  path: string[] | null;
  pairNotExist: boolean;
}

type CurrencyType = "firstCurrency" | "secondCurrency";

class ImportPool extends React.Component<PropsInterface, StateInterface> {
  i: number = 0;
  polling_interval: any;
  static contextType = LanguageContext;
  text: any;

  constructor(props: any) {
    super(props);

    this.state = {
      firstCurrency: DEFAULT_TOKEN_LIST.filter((x) => x.symbol === "XDC")[0],
      secondCurrency: null,
      showModal: false,
      modalContent: null,
      error_message: "CONNECT WALLET",
      button: <button className={"disabled"}>SELECT PAIR</button>,
      exists: false,
      pairAddress: null,
      path: null,
      pairNotExist: false,
    };

    this.showTokenList = this.showTokenList.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.checkValidity();
  }

  componentDidUpdate(prevProps: PropsInterface) {
    if (!_.isEqual(prevProps.poolList, this.props.poolList)) {
      this.checkValidity();
    }
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : "";
    return langText;
  };

  showTokenList(disabled: string[] = [], cb: (token: Token) => void) {
    const tokenList: ReactElement = (
      <TokenList disabled={disabled} onTokenSelect={cb} />
    );
    this.setState({ showModal: true, modalContent: tokenList });
  }

  toggle() {
    const first = this.state.firstCurrency;
    const second = this.state.secondCurrency;
    this.setState({
      firstCurrency: second,
      secondCurrency: first,
    });
  }

  checkPairExists() {
    if (!this.state.firstCurrency || !this.state.secondCurrency) return;
    CheckPairExists(
      this.state.firstCurrency.address,
      this.state.secondCurrency.address
    )
      .then(([resp, pair_address, path]) => {
        if (resp === null) return;
        this.setState({ exists: resp, pairAddress: pair_address, path });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => this.checkValidity());
  }

  renderTokenSelect(selected: Token | null, type: CurrencyType): ReactElement {
    const cb = (token: Token) => {
      const q: any = {
        showModal: false,
      };

      if (type === "firstCurrency") {
        if (this.state.secondCurrency?.symbol === token.symbol) {
          this.setState({ ...q }, () => this.toggle());
          return;
        }
      } else {
        if (this.state.firstCurrency?.symbol === token.symbol) {
          this.setState({ ...q }, () => this.toggle());
          return;
        }
      }

      q[type] = token;
      this.setState({ ...q }, () => {
        this.checkPairExists();
      });
    };

    if (!selected)
      return (
        <div
          className="select-token"
          onClick={() => this.showTokenList(undefined, cb)}
        >
          <div className="token-name">
            {this.renderLang("select_token")}
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      );

    return (
      <div
        className="select-token"
        onClick={() => this.showTokenList([selected.symbol], cb)}
      >
        <div className="token-badge">
          <div className="token-name">
            <img src={selected.logo} alt={selected.symbol} />
            {selected.symbol} <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      </div>
    );
  }

  importPool() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.state.pairAddress ||
      !this.state.path
    )
      return;

    let first = this.state.firstCurrency;
    let second = this.state.secondCurrency;

    if (
      Utils.fromXdcAddress(this.state.path[0]) !==
      Utils.fromXdcAddress(first.address)
    ) {
      first = this.state.secondCurrency;
      second = this.state.firstCurrency;
    }

    this.props.AddCustomPool(first, second, this.state.pairAddress);
    toast("Pool Added", { autoClose: 1500 });
  }

  checkValidity() {
    if (!this.state.firstCurrency || !this.state.secondCurrency)
      return this.setState({
        button: <button className={"disabled"}>Select Pair</button>,
      });

    if (
      this.state.exists !== true ||
      !this.state.path ||
      !this.state.pairAddress
    )
      return this.setState({
        button: (
          <>
            <button className={"disabled"}>
              {this.renderLang("pair_not_exists")}
            </button>
            <div className="create-pair-link">
              <Link
                to={`/create-pair?outputCurrency=${this.state.secondCurrency?.symbol}&inputCurrency=${this.state.firstCurrency?.symbol}`}
              >
                Create this Pair{" "}
              </Link>
            </div>
          </>
        ),
        pairNotExist: true,
      });

    if (
      this.props.poolList.filter(
        ({ address }) =>
          Utils.fromXdcAddress(address) === this.state.pairAddress
      ).length > 0
    )
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("pair_exists")}
          </button>
        ),
      });

    this.setState({
      button: (
        <button onClick={() => this.importPool()} className={""}>
          {this.renderLang("import_pool")}
        </button>
      ),
    });
  }

  renderPairAddress() {
    if (!this.state.exists || !this.state.pairAddress) return "";

    return (
      <div className="import-address">
        <div className="label">{this.renderLang("pair_address")}</div>
        <div className="value u-overflow-trim">
          {" "}
          <TokenAddress address={Utils.toXdcAddress(this.state.pairAddress)} />
        </div>
      </div>
    );
  }

  render() {
    this.text = GetLanguage(this.context.language);
    return (
      <div className="dashboard">
        <div className="swap">
          <SEO
            keywords={["globiance", "globiancedex", "dex", "pool", "import"]}
            title="Import Pool For Management | GlobianceDEX"
            pathSlug="/import-pool"
            description="Join the GlobianceDEX will help users to Import Pool for management to connect wallet."
          />
          <div className="header">
            <div className="text">
              <div className="text__primary">
                {this.renderLang("import_pool")}
              </div>
              <div className="text__secondary">
                {this.renderLang("import_pool_manage")}
              </div>
            </div>
          </div>
          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.firstCurrency,
                "firstCurrency"
              )}
            </div>
          </div>
          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.secondCurrency,
                "secondCurrency"
              )}
            </div>
          </div>
          {this.renderPairAddress()}
          <div className="submit">{this.state.button}</div>
          <Modal
            className="u-fit-modal custom-modal-1"
            centered={true}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Body>{this.state.modalContent}</Modal.Body>
          </Modal>
        </div>
        <div className='landing-main-2' id="swap-pinkgirl">
          <img src={PinkGirl1} alt="" />
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  theme,
  settings,
  poolList,
}: {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  poolList: PoolList;
}) {
  return { wallet, theme, settings, poolList };
}

export default connect(mapStateToProps, actions)(ImportPool);
