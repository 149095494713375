import React, { ReactElement } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { ForceShowModal, Disconnect } from "xdc-connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as actions from "../../redux/actions/index";
import Types, { Wallet, Theme, Lang } from "../../types";
import { DEX_API, AbbreviateNumber } from "../../helper/constant";
import {
  faChevronDown,
  faChevronUp,
  faEllipsisH,
  faGlobe,
  faMagic,
  faMoon,
  faSignOutAlt,
  faSun,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/dex/dex-logo.png";
import LogoBlack from "../../assets/dex/dex-logo.png";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import DefaultLogo from "../../assets/img/default.png";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import Button from "@restart/ui/esm/Button";
import EN_FLAG from '../../assets/dex/flags/EN.png';
import AR_FLAG from '../../assets/dex/flags/AR.png';
import GE_FLAG from '../../assets/dex/flags/GE.png';
import TH_FLAG from '../../assets/dex/flags/TH.png';
import TR_FLAG from '../../assets/dex/flags/TR.png';
import ES_FLAG from '../../assets/dex/flags/ES.png';
import FR_FLAG from '../../assets/dex/flags/FR.png';
import ID_FLAG from '../../assets/dex/flags/ID.png';
import MY_FLAG from '../../assets/dex/flags/MY.png';
import VN_FLAG from '../../assets/dex/flags/VN.png';
import CN_FLAG from '../../assets/dex/flags/CN.png';
import PT_FLAG from '../../assets/dex/flags/PT.png';
import PH_FLAG from '../../assets/dex/flags/PH.png';
import KR_FLAG from '../../assets/dex/flags/KR.png';
import JP_FLAG from '../../assets/dex/flags/JP.png';
import { BalanceOf } from '../../helper/crypto';
import { FromDecimals, RemoveExpo } from "../../helper/decimals";
import Utils from "xdc3-utils";
import { ChainIdToExplorer, DEFAULT_CHAIN_ID } from "../../helper/constant";
interface PropsInterface extends actions.ActionsInterface {
  tokenList: Types.TokenList;
  wallet: Wallet;
  theme: Theme;
  lang: Lang;
}

interface StateInterface{
  toggle: any,
  tvlLoader: any,
  tvlData: any,
  toggleMobileNav: any,
  bal: any,
  width: any,
  height: any,
}

const IconMap: { [k in Theme]: IconDefinition } = {
  light: faSun,
  dark: faMoon,
  iri: faMagic,
};

const statsAPI = `${DEX_API}/get-stats`;

const _first = DEFAULT_TOKEN_LIST.filter(
  (x) => x.symbol === 'XDC'
);
const logo = _first.length > 0 ? _first[0].logo : DefaultLogo;

class Header extends React.Component<PropsInterface, StateInterface> {
  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsInterface) {
    super(props);
    this.state = {
      toggle: false,
      tvlLoader: true,
      tvlData: 0,
      toggleMobileNav: 0,
      bal: '',
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }



  componentDidMount() {
    this.getStats();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUpdate(newProps: any) {
    if (this.props.wallet !== newProps.wallet) {
      this.getBalance()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getStats = () => {
    return axios
      .post(statsAPI)
      .then((res: any) => {
        const data = res.data.data;
        this.setState({
          tvlLoader: false,
          tvlData: data.totalLiquidity,
        });
      })
      .catch((err) => console.log(err));
  };

  getBalance = () => {
    const getToken = this.props.tokenList.find((token: any) => token.symbol === "XDC");
    if (getToken) {
      BalanceOf(getToken).then((balances) => {
        let balance = "0";
        if (!balances) {
          balance = "0";
          this.setState({ bal: balance })
        } else {
          balance = RemoveExpo(parseFloat(
            FromDecimals(balances[0], getToken.decimals)
          ).toFixed(2));
          console.log(balance)
          this.setState({ bal: balance })
        }
      });
    }
  }

  handleMobNavOutside() {
    this.setState({
      toggleMobileNav: 0,
    });
  }

  toggleTheme(theme: Theme): Theme {
    const themes: Theme[] = ["light", "dark", "iri"];
    return themes[(themes.indexOf(theme) + 1) % themes.length];
  }

  renderThemeButton(): ReactElement {
    const theme = this.props.theme;
    return (
      <div
        className={"theme-button"}
        onClick={() => this.props.ChangeTheme(this.toggleTheme(theme))}
      >
        <FontAwesomeIcon icon={IconMap[theme]} />
      </div>
    );
  }

  switchLang(lang: Lang) {
    this.props.SwitchLanguage(lang)
    this.context.updateLanguage(lang);
  }

  handleToggleMenu = () => {
    this.setState({
      toggle: !this.state.toggle,
    });
  };


  renderLogout() {
    if (!this.props.wallet.connected) return <div className="logout"></div>;
    return (
      <div className="logout" onClick={() => Disconnect()}>
        <FontAwesomeIcon icon={faSignOutAlt} />
      </div>
    );
  }

  handleMobNav = (nav: any) => {
    if (nav === this.state.toggleMobileNav) {
      this.setState({
        toggleMobileNav: 0,
      });
    } else {
      this.setState({
        toggleMobileNav: nav,
      });
    }
  };

  renderHeaderImg = () => {
    const theme = this.props.theme;
    let logoImg = null;
    if (theme === "light" || theme === "dark") {
      logoImg = Logo;
    } else {
      logoImg = LogoBlack;
    }

    return logoImg;
  };

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };

  render() {
    this.text = GetLanguage(this.context.language);
    const walletConnectProps = {
      disabled: false,
      btnName: "CONNECT WALLET",
    };

    if (this.props.wallet.connected) {
      if (this.props.wallet.valid_network === true) {
        walletConnectProps.disabled = true;
        walletConnectProps.btnName = "CONNECTED";
      } else {
        walletConnectProps.disabled = true;
        walletConnectProps.btnName = "INCORRECT NETWORK";
      }
    }

    let btnStatus = "";
    if (walletConnectProps.btnName === "CONNECTED") {
      btnStatus = "btn btn-dex-success";
    } else if (walletConnectProps.btnName === "INCORRECT NETWORK") {
      btnStatus = "btn btn-dex-danger";
    } else {
      btnStatus = "btn btn-dex-gradient-outline";
    }

    return (
      <div
        className="header-main"
        onMouseLeave={() => {
          this.handleMobNavOutside();
        }}
      >
        <header>
          <div className="logo-block">
            <Link to="/">
              <img src={this.renderHeaderImg()} className="logo" alt="" />
            </Link>
            {this.state.tvlLoader ? (
              <div className="tvl-block">
                {this.renderLang("TVL")} <div className="dot"></div>
              </div>
            ) : (
              <div className="tvl-block">
                {this.renderLang("TVL")} {AbbreviateNumber(this.state.tvlData)}
              </div>
            )}
          </div>
          <div
            className={`toggle-navigation ${this.state.toggle ? "active" : ""}`}
            onClick={() => this.handleToggleMenu()}
          ></div>
          <ul
            className={`navigation nav-mob ${this.state.toggle ? "active" : ""
              }`}
          >
            <li>
              <Link
                to="/swap"
                className=""
                onClick={() => this.handleToggleMenu()}
              >
                {" "}
                {this.renderLang("swap")}
              </Link>
            </li>
            <li
              className={`${this.state.toggleMobileNav === 1 ? "active" : ""}`}
              onClick={() => this.handleMobNav(1)}
            >
              <Link to="#"> {this.renderLang("liquidity")} </Link>
              {this.state.toggleMobileNav === 1 ? (
                <FontAwesomeIcon className="nav-icon" icon={faChevronUp} />
              ) : (
                <FontAwesomeIcon className="nav-icon" icon={faChevronDown} />
              )}
              <ul className="list-g">
                <li>
                  <Link
                    to="/add"
                    className=""
                    onClick={() => this.handleToggleMenu()}
                  >
                    {this.renderLang("add")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/remove"
                    className=""
                    onClick={() => this.handleToggleMenu()}
                  >
                    {this.renderLang("remove")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/pool"
                className=""
                onClick={() => this.handleToggleMenu()}
              >
                {this.renderLang("pool")}
              </Link>
            </li>
            <li>
              <Link
                to="/token"
                className=""
                onClick={() => this.handleToggleMenu()}
              >
                {this.renderLang("token_list")}
              </Link>
            </li>
            <li
              className={`${this.state.toggleMobileNav === 2 ? "active" : ""}`}
              onClick={() => this.handleMobNav(2)}
            >
              <Link to="#">
                <FontAwesomeIcon icon={faEllipsisH} size="lg" />
              </Link>
              <ul className="list-g">
                <li>
                  <Link to="/analytics" onClick={() => this.handleToggleMenu()}>
                    {this.renderLang("analytics")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/token-prices"
                    onClick={() => this.handleToggleMenu()}
                  >
                    {this.renderLang("token_prices")}
                  </Link>
                </li>
                <li>
                  <Link to="/faq" onClick={() => this.handleToggleMenu()}>
                    {this.renderLang("faq")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/stake"  onClick={() => this.handleToggleMenu()}>
                  {this.renderLang('stake')}
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className={`${this.state.toggleMobileNav === 3 ? "active" : ""}`} onClick={() => this.handleMobNav(3)}>
              <Link to="#"  >
                <FontAwesomeIcon icon={faGlobe} size="lg" />
              </Link>
              <ul className="list-g lang-switch">
                <li onClick={() => this.switchLang('EN')} className={`${this.props.lang === 'EN' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={EN_FLAG} alt="EN" /> EN
                  </Link>
                </li>
                <li onClick={() => this.switchLang('AR')} className={`${this.props.lang === 'AR' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={AR_FLAG} alt="AR" />
                    AR
                  </Link>
                </li>
                <li onClick={() => this.switchLang('TH')} className={`${this.props.lang === 'TH' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={TH_FLAG} alt="TH" />
                    TH
                  </Link>
                </li>
                <li onClick={() => this.switchLang('TR')} className={`${this.props.lang === 'TR' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={TR_FLAG} alt="TR" />
                    TR
                  </Link>
                </li>
                <li onClick={() => this.switchLang('GE')} className={`${this.props.lang === 'GE' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={GE_FLAG} alt="GE" />
                    GE
                  </Link>
                </li>
                <li onClick={() => this.switchLang('ES')} className={`${this.props.lang === 'ES' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={ES_FLAG} alt="ES" />
                    ES
                  </Link>
                </li>
                <li onClick={() => this.switchLang('FR')} className={`${this.props.lang === 'FR' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={FR_FLAG} alt="FR" />
                    FR
                  </Link>
                </li>
                <li onClick={() => this.switchLang('ID')} className={`${this.props.lang === 'ID' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={ID_FLAG} alt="ID" />
                    ID
                  </Link>
                </li>
                <li onClick={() => this.switchLang('MY')} className={`${this.props.lang === 'MY' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={MY_FLAG} alt="MY" />
                    MY
                  </Link>
                </li>
                <li onClick={() => this.switchLang('VN')} className={`${this.props.lang === 'VN' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={VN_FLAG} alt="VN" />
                    VN
                  </Link>
                </li>
                <li onClick={() => this.switchLang('CN')} className={`${this.props.lang === 'CN' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={CN_FLAG} alt="CN" />
                    CN
                  </Link>
                </li>
                <li onClick={() => this.switchLang('PT')} className={`${this.props.lang === 'PT' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={PT_FLAG} alt="PT" />
                    PT
                  </Link>
                </li>
                <li onClick={() => this.switchLang('PH')} className={`${this.props.lang === 'PH' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={PH_FLAG} alt="PH" />
                    PH
                  </Link>
                </li>
                <li onClick={() => this.switchLang('KR')} className={`${this.props.lang === 'KR' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={KR_FLAG} alt="KR" />
                    KR
                  </Link>
                </li>
                <li onClick={() => this.switchLang('JP')} className={`${this.props.lang === 'JP' ? 'lang-active' : ''}`}>
                  <Link to="#" onClick={() => this.handleToggleMenu()}>
                    <img src={JP_FLAG} alt="JP" />
                    JP
                  </Link>
                </li>
              </ul>
            </li>
            {this.state.width > 991 &&
            this.props.wallet.valid_network && this.props.wallet.connected && (
              <li>
                <div className="wallet-status-container">
                  <div className="balance">
                    <img src={logo} alt="xdc" height="28" />{" "}
                    {this.state.bal}
                  </div>
                  <div className="account">
                    <div className="account-inner">
                      <a
                        href={`${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/address/${Utils.toXdcAddress(this.props.wallet.address)}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          {Utils.toXdcAddress(this.props.wallet.address.toString().slice(0, 5))}
                        </span>
                      </a>

                    </div>
                  </div>
                </div>
              </li>
            )}
            {this.state.width > 991 && (
              <>
              <li>
                <div className="xdc-connect-wrapper">
                  <Button
                    className={btnStatus}
                    onClick={() => ForceShowModal()}
                    disabled={walletConnectProps.disabled}
                  >
                    {walletConnectProps.btnName}
                  </Button>
                  {/* <XdcConnect
                    addToastContainer={true}
                    // toastContainer={<ToastContainer />}
                    enabledProviders={[
                      "keystore",
                      "privatekey",
                      "xinpay",
                      "dcent-inapp",
                    ]}
                    defaultChainId={50}
                    btnClass={btnStatus}
                    rpcProvider={PROVIDERS[DEFAULT_CHAIN_ID]}
                    onAddressChange={(wallet) => {
                      this.props.AccountChanged(wallet.address);
                    }}
                    onDisconnect={() => {
                      this.props.WalletDisconnected();
                    }}
                    onConnect={(wallet) => {
                      this.props.WalletConnected({
                        address: wallet.address,
                        chain_id: wallet.chain_id,
                        loader: wallet.loader,
                        provider: wallet.rpc_provider,
                        valid_network: VALID_CHAIN_ID.includes(
                          wallet.chain_id.toString() as any
                        ),
                      });
                    }}
                    theme={this.props.theme === "dark" ? "dark" : "light"}
                    {...walletConnectProps}
                  /> */}
                </div>
              </li>
              <li>{this.renderThemeButton()}</li>
              <li>{this.renderLogout()}</li>
              </>
            )}
          </ul>
        </header>
        {/* <div className="notifi-header">
          Note:
        </div> */}
      </div>
    );
  }
}

function mapStateToProps({ wallet, theme, lang, tokenList }: { wallet: Wallet; theme: Theme; lang: Lang, tokenList: Types.TokenExtended[]; }) {
  return { wallet, theme, lang, tokenList };
}

export default connect(mapStateToProps, actions)(Header);
