import { Link } from 'react-router-dom';

export default function PageNotFound() {
    return (
        <div className="dashboard">
            <div className="page-error-container">
                <div className="main-title">404</div>
                <div className="main-description">The page you were looking for does not exist </div>
                <Link to="/"><div className="go-back">Go to Home Page</div></Link>
            </div>
        </div>
    )
}
