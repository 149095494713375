import themeReducer from "./themeReducer"
import walletReducer from './walletReducer';
import settingsReducer from "./settingsReducer"
import tokenListReducer from "./tokenListReducer"
import poolListReducer from "./poolListReducer"
import assetPrice from "./priceReducer"
import addressPrice from "./addressPriceReducer"
import langReducer from "./langReducer"
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    wallet: walletReducer,
    theme: themeReducer,
    tokenList: tokenListReducer,
    poolList: poolListReducer,
    settings: settingsReducer,
    assetPrice,
    addressPrice,
    lang: langReducer
});

export default rootReducer;
