import React from 'react';
import { connect } from 'react-redux';
import * as actions from "../../redux/actions/index";
import { Theme } from "../../types";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import axios from 'axios';
import { DEX_API } from "../../helper/constant";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Planet from '../../assets/dex/planet.png';
import SwapToken from '../../assets/dex/swap-hero-img.png';
import LiquidityIcon from '../../assets/dex/counter-box/liquidity.png';
import GBEXIcon from '../../assets/dex/counter-box/gb-icon.png';
import VolumeIcon from '../../assets/dex/counter-box/volume.png';
import TradeIcon from '../../assets/dex/counter-box/trade.png';
import LiquidityIcon1 from '../../assets/dex/dark/liquidity.png';
import GBEXIcon1 from '../../assets/dex/dark/gb-icon.png';
import VolumeIcon1 from '../../assets/dex/dark/volume.png';
import TradeIcon1 from '../../assets/dex/dark/trade.png';
import GBEXLPTokenImg from '../../assets/dex/GBEX-lp-token.png';
import spaceman from '../../assets/dex/dark/space-man.png';
import PinkGirl from "../../assets/dex/iri/landing-main-2.png"
import LPToken from '../../assets/dex/iri/lp-token.png';
import GBWallet from '../../assets/dex/iri/gb-wallet.png';
import Celebration from '../../assets/img/celebration.gif';
import SmailCeli from '../../assets/img/SmailCeli.gif';


import XRC from '../../assets/dex/iri/xrc.png';
import GBEXStats from '../../assets/dex/iri/GBEXStats.png';
import { SEO } from '../common/SEO';


const statsAPI = `${DEX_API}/get-stats`;

interface PropsInterface extends actions.ActionsInterface {
    theme: Theme;
}


interface StateInterface {
    statsData: {
        totalLiquidity:number;
        totalVolume:number;
        tradeCount:number;
        listedTokens:number;

    };
    statsLoader: boolean;
    messageVisible:boolean;
}



class Landing extends React.Component<PropsInterface, StateInterface> {
    static contextType = LanguageContext;
    text: any;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            statsData: {
                totalLiquidity:0,
                totalVolume:0,
                tradeCount:0,
                listedTokens:0,
            },
            statsLoader: true,
            messageVisible: true,

        };
        this.MessageClose = this.MessageClose.bind(this);
    }

    componentDidMount() {
        this.getStats();
    }

    getStats = () => {
        return axios
            .post(statsAPI)
            .then((res: any) => {
                const data = res.data.data;
                this.setState({
                    statsData: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ statsLoader: false });
            })
    }


    renderLandingImg = () => {
        const curTheme = this.props.theme
        let img1 = Planet;
        let img2 = SwapToken;
        let img3 = GBEXLPTokenImg;
        let button1, button2, button3;

        switch (curTheme) {
            case 'light':
                img1 = Planet;
                img2 = SwapToken;
                img3 = GBEXLPTokenImg;
                button1 = 'btn-dex-gradient';
                button2 = 'btn-dex-gradient';
                button3 = 'btn-dex-gradient';
                return { img1, img2, img3, button1, button2, button3 };
            case 'dark':
                img1 = "";
                img2 = spaceman;
                img3 = "";
                button1 = 'btn-dex-dark-solid';
                button2 = 'btn-dex-dark-solid';
                button3 = 'btn-dex-dark-solid';
                return { img1, img2, img3, button1, button2, button3 };
            case 'iri':
                img1 = PinkGirl;
                img2 = XRC;
                img3 = LPToken;
                button1 = ' btn-dex-iri-solid';
                button2 = 'btn-dex-iri-outline';
                button3 = 'btn-dex-iri-solid-white';
                return { img1, img2, img3, button1, button2, button3 };
            default:
                return { img1, img2, img3, button1, button2, button3 };
        }
    }

    renderLang = (textName: string) => {
        const langData = textName;
        const langText = this.text ? this.text[langData] : null
        return langText;
    }



    MessageClose() {
        console.log("thisss",this)
        this.setState({ messageVisible: false })

    }



    render() {

        this.text = GetLanguage(this.context.language);
        const landingImages = this.renderLandingImg();
        const messagevisible = this.state.messageVisible;
        const themeChange = this.props.theme;
        const statsDex = (
            <>
                <hr className="dex-hr" />
                <div className="swap-token-title dex-text-center dex-project-cmp-pd">
                    {/* <h2>We have <span className="text-yellow">successfully</span> completed <span
                        className="text-yellow">1000+</span>
                    <span className="text-light-blue">Projects Yearly</span> and <span
                        className="text-light-blue">Counting</span>
                </h2> */}
                </div>
                <div className="dex-counting-boxes light">
                    <div className="dex-single-box">
                        <LazyLoadImage className="dex-img-counter" src={LiquidityIcon} alt="" />
                        <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> :"$ "+Math.round(this.state.statsData.totalLiquidity).toLocaleString()}</span>
                        <span className="desc-text">{this.renderLang('counting_box_des3')}</span>
                    </div>
                    <div className="dex-single-box">
                        <LazyLoadImage className="dex-img-counter" src={VolumeIcon} alt="" />
                        <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : "$ "+Math.round(this.state.statsData.totalVolume).toLocaleString()}</span>
                        <span className="desc-text">{this.renderLang('counting_box_des2')}</span>
                    </div>
                    <div className="dex-single-box">
                        <LazyLoadImage className="dex-img-counter" src={TradeIcon} alt="" />
                        <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : this.state.statsData.tradeCount}</span>
                        <span className="desc-text">{this.renderLang('counting_box_des1')}</span>
                    </div>
                    <div className="dex-single-box">
                        <LazyLoadImage className="dex-img-counter" src={GBEXIcon} alt="" />
                        <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : this.state.statsData.listedTokens}</span>
                        <span className="desc-text">{this.renderLang('counting_box_des')}</span>
                    </div>
                </div>
            </>
        )

        const statsDex1 = (
            <>
                <div className="dark-dex">
                    <div className="dark-box">
                        <div className="dex-counting-boxes dark">
                            <div className="dex-single-box">
                                <LazyLoadImage className="dex-img-counter" src={LiquidityIcon1} alt="" />
                                <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : "$ "+Math.round(this.state.statsData.totalLiquidity).toLocaleString()}</span>
                                <span className="desc-text">{this.renderLang('counting_box_des3')}</span>
                            </div>
                            <div className="dex-single-box">
                                <LazyLoadImage className="dex-img-counter" src={VolumeIcon1} alt="" />
                                <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : "$ "+Math.round(this.state.statsData.totalVolume).toLocaleString()}</span>
                                <span className="desc-text">{this.renderLang('counting_box_des2')}</span>
                            </div>
                            <div className="dex-single-box">
                                <LazyLoadImage className="dex-img-counter" src={TradeIcon1} alt="" />
                                <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : Math.round(this.state.statsData.tradeCount).toLocaleString()}</span>
                                <span className="desc-text">{this.renderLang('counting_box_des1')}</span>
                            </div>
                            <div className="dex-single-box">
                                <LazyLoadImage className="dex-img-counter" src={GBEXIcon1} alt="" />
                                <span className="counting-text">{this.state.statsLoader ? <div className="loader-dot"></div> : Math.round(this.state.statsData.listedTokens).toLocaleString()}</span>
                                <span className="desc-text">{this.renderLang('counting_box_des')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )


        const XRC20Section = (
            <section className="swap-token">
                <div className="swap-content">
                    <div className="app-image">
                        <LazyLoadImage className="logo" src={landingImages.img2} alt={landingImages.img2} />
                    </div>
                    <div className="swap-wrapper-text">
                        <div className="ring-planet"></div>
                        <div className="swap-token-title">
                            <h2>{this.renderLang('landingXRC20title')}<span
                                className="text-light-blue">{this.renderLang('landingXRC20title1')}</span> <span
                                    className="text-yellow">{this.renderLang('landingXRC20title2')}</span> <span
                                        className="text-light-blue">{this.renderLang('landingXRC20title3')}</span>{this.renderLang('landingXRC20title4')}
                            </h2>
                        </div>
                        <p className="swap-token-p">{this.renderLang('landingXRC20_subtitle')}</p>

                        <Link to="/swap" className={`btn ${landingImages.button1}`}>{this.renderLang('landingStartTrading')}</Link>
                    </div>
                </div>
                {themeChange === 'light' && statsDex}
            </section>
        )





        const IriContent = (
            <>
                <section className="smart-chain">
                    <div className="wallet-img">
                        <LazyLoadImage src={GBWallet} alt="" />
                    </div>
                    <div className="main-text-title">
                        <h2 className="main-title text-iri-dark"><span className="text-iri-light">{this.renderLang('trade_any1')}</span> {this.renderLang('trade_any2')}</h2>
                        <p>{this.renderLang('trade_any3')}</p>
                        <div className="button-group">
                            <Link to="/swap" className={`btn ${landingImages.button1}`}>{this.renderLang('trade')}</Link>
                            <Link target="_blank" to="/faq#liquidity" className={`btn ${landingImages.button2}`}>{this.renderLang('learn_more')}</Link>
                        </div>
                    </div>
                </section>
            </>
        );
        return (
            <div className="landing-wrapper">
                <SEO
                    keywords={["globiance", "globiancedex", "dex", "home"]}
                    title="GlobianceDEX - Swap, Earn and Unlock Crypto"
                    pathSlug=""
                    description="Globiance DEX is the first Decentralized Exchange ( DEX ) built on leading XDC ( XinFin ) blockchain and which has a 2000+ TPS making trading on Globiance DEX lightning fast."
                />
              {messagevisible
              ?  <section className="home-message-box">
                    <div className="homepage-message">
                        <p> <LazyLoadImage className="dex-celebration" src={Celebration} alt="" /> GlobianceDEX Now listed at <a href="https://www.coingecko.com/en/exchanges/globiance" target="_blank">Coingecko</a> and <a href="https://www.geckoterminal.com/xdc/globiance/pools" target="_blank">Gecko terminal</a>
                        <LazyLoadImage className="dex-celebration-two" src={SmailCeli} alt="" /> <span className="MessageClose" onClick={this.MessageClose}>X</span> </p>
                    </div>
                </section>
             : ''
            }
                <section className={`main ${themeChange === 'light' || themeChange === 'dark' ? '' : 'main-top'}`}>

                    <div className="content">
                        <div className={`dex-text ${themeChange === 'dark' ? 'dark' : ''}`}>
                            <h1>{this.renderLang('landing_header_title')}</h1>
                            <h3 className="dex-subtitle">{this.renderLang('landing_header_subtitle')}
                            <span className="text-light-blue">{themeChange === 'dark' ? <strong>{this.renderLang('landing_header_subtitle1')}</strong> : this.renderLang('landing_header_subtitle1')} </span>
                                {this.renderLang('landing_header_subtitle2')}
                                <span className="text-yellow">{themeChange === 'dark' ? <strong>{this.renderLang('landing_header_subtitle3')}</strong> : this.renderLang('landing_header_subtitle3')}</span> </h3>
                        </div>
                        {themeChange === 'iri' || themeChange === 'light' ?
                            (<div className="app-image">
                                <LazyLoadImage className="res-img" src={landingImages.img1} alt="" />
                            </div>)
                            :
                            null
                        }
                    </div>

                    {themeChange === 'dark' && statsDex1}
                    <div className={`list-section ${themeChange === 'light' || themeChange === 'dark' ? '' : 'list-section-top'}`}>
                    <p className={themeChange === 'light' || themeChange === 'dark' ? 'list-section-p-dark' : 'list-section-p'}><span>Listed AT</span></p>
                    <div className="content">
                        <div className="content-list-section">
                            <div>
                            <a href="https://www.coingecko.com/en/exchanges/globiance" target="_blank">
                                <LazyLoadImage className={`list-section-img ${themeChange === 'light' || themeChange === 'dark' ? 'list-section-img-light' : ''}`} src="https://static.coingecko.com/s/coingecko-logo-8903d34ce19ca4be1c81f0db30e924154750d208683fad7ae6f2ce06c76d0a56.png" alt="" />
                            </a>
                            <span>CoinGecko</span>
                            </div>
                            <div>
                            <a href="https://www.geckoterminal.com/xdc/globiance/pools" target="_blank">
                                <LazyLoadImage className={`list-section-img ${themeChange === 'light' || themeChange === 'dark' ? 'list-section-img-dark' : ''}`} src="https://www.geckoterminal.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo_dark.fd00518c.png&w=3840&q=75" alt="" />
                            </a>
                            <span><br/>GeckoTerminal</span>
                            </div>
                            <div>
                            <a href="https://coincodex.com/exchange/globiancedex" target="_blank">
                                <LazyLoadImage className={`list-section-img ${themeChange === 'light' || themeChange === 'dark' ? 'list-section-img-light' : ''}`} src="https://coincodex.com/images/coincodex-dark.svg?v=1" alt="" />
                            </a>
                            <span><br/>CoinCodex</span>
                            </div>
                        </div>
                    </div>
                 </div>
                </section>

                {themeChange === 'iri' ? IriContent : XRC20Section}
                <section className={`gb-bg-${themeChange}`}>
                    <div className="content content-pd">
                        <div className="gb-token-text">
                            <div className="swap-token-title">
                                <h2>{this.renderLang('lp_tokens')}<span
                                    className="text-yellow">{this.renderLang('lp_tokens1')}</span>{this.renderLang('lp_tokens2')}<span
                                        className="text-light-blue">{this.renderLang('lp_tokens3')}</span>{this.renderLang('lp_tokens4')}<span
                                            className="text-light-blue">{this.renderLang('lp_tokens5')}</span>
                                    {this.renderLang('lp_tokens6')} <span
                                        className="text-yellow">{this.renderLang('commingsoon')}</span></h2>
                            </div>
                            <Link to="/learn-more-incentives" className={`btn ${landingImages.button1}`}>{this.renderLang('learn_more')}</Link>
                        </div>
                        {themeChange === 'dark' ?
                            null
                            : (<div className="gb-token-image">
                                <LazyLoadImage src={landingImages.img3} alt="" />
                            </div>)
                        }
                    </div>
                </section>

                {themeChange === 'iri' && XRC20Section}
                {themeChange === 'iri' && (
                    <>
                        <section className="supply-stats">
                            <div className="supply-data">
                                <div className="supply-img">
                                    <LazyLoadImage src={LiquidityIcon1} alt="" />
                                </div>
                                <p className="number">{this.state.statsLoader ? <div className="loader-dot"></div> : "$ "+Math.round(this.state.statsData.totalLiquidity).toLocaleString()}</p>
                                <p className="smaller-text">{this.renderLang('stats_supply_desc1')}</p>
                            </div>
                            <div className="supply-data">
                                <div className="supply-img">
                                    <LazyLoadImage src={VolumeIcon1} alt="" />
                                </div>
                                <p className="number">{this.state.statsLoader ? <div className="loader-dot"></div> : "$ "+Math.round(this.state.statsData.totalVolume).toLocaleString()}</p>
                                <p className="smaller-text">{this.renderLang('stats_supply_desc2')}</p>
                            </div>
                            <div className="supply-data">
                                <div className="supply-img">
                                    <LazyLoadImage src={TradeIcon1} alt="" />
                                </div>
                                <p className="number">{this.state.statsLoader ? <div className="loader-dot"></div> : this.state.statsData.tradeCount}</p>
                                <p className="smaller-text">{this.renderLang('stats_supply_desc3')}</p>
                            </div>
                            <div className="supply-data">
                                <div className="supply-img">
                                    <LazyLoadImage src={GBEXStats} alt="" />
                                </div>
                                <p className="number">{this.state.statsLoader ? <div className="loader-dot"></div> : this.state.statsData.listedTokens}</p>
                                <p className="smaller-text">{this.renderLang('stats_supply_desc4')}</p>
                            </div>
                        </section>
                        <section className="call-to-action">
                            <div className="cta-text">
                                <div className="main-title">{this.renderLang('starts_in')}</div>
                                <p>{this.renderLang('starts_in_des1')}<br />
                                    {this.renderLang('starts_in_des2')}</p>
                                <Link to="/faq" className="btn btn-dex-iri-solid">{this.renderLang('learn_how_to_start')}</Link>
                            </div>

                        </section>
                    </>
                )}
            </div>
        )
    }
}

function mapStateToProps({ theme }: { theme: Theme }) {
    return { theme };
}


export default connect(mapStateToProps, actions)(Landing);