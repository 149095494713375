import React from "react";
import { Switch, Route } from "react-router-dom";

import Swap from "../swap/swap";
import Pool from "../pool/pool";
import Add from "../liquidity/Add";
import Remove from "../liquidity/Remove";
import CreatePair from "../liquidity/CreatePair";
import ImportPool from "../liquidity/ImportPool";
import InfoPool from "../info/pool";
import Landing from "../Landing/Landing";
import Stake from "../Stake/Stake";
import StakeDetails from "../Stake/StakeDetails";
import Analytics from "../Analytics/Analytics";
import TokenPrice from "../TokenPrice/TokenPrice";
import LearnMore from "../Landing/LearnMore";
import FOTT from "../FOTT";
import Faq from "../Faq/Faq";
import ManagePool from "../info/ManagePool";
import TokenInfo from "../TokenInfo/TokenInfo";
import Token from "../Token/Token";
import PageNotFound from "../common/PageNotFound";
// import Maintenance from "../common/Maintenance"
class Dashboard extends React.Component{

  render() {
    return (
      <>
        <div className="inner-wrapper" >
          <Switch>
          <Route path="/" exact={true} component={Landing} />
            <Route path="/swap" exact={true} component={Swap} />
            <Route path="/pool" exact={true} component={Pool} />
            <Route path="/add" exact={true} component={Add} />
            <Route path="/remove" exact={true} component={Remove} />
            <Route path="/create-pair" exact={true} component={CreatePair} />
            <Route path="/import-pool" exact={true} component={ImportPool} />
            <Route path="/info/pool/:pair" exact={true} component={InfoPool} />
            <Route path="/stake" exact={true} component={Stake} />
            <Route path="/stake1" exact={true} component={StakeDetails} />
            <Route path="/analytics" exact={true} component={Analytics} />
            <Route path="/token-prices" exact={true} component={TokenPrice} />
            <Route path="/learn-more-incentives" exact={true} component={LearnMore} />
            <Route path="/fott" exact={true} component={FOTT} />
            <Route path="/faq" exact={true} component={Faq} />
            <Route path="/info/manage/:pair" exact={true} component={ManagePool} />
            <Route path="/info/token/:token" exact={true} component={TokenInfo} />
            <Route path="/token" exact={true} component={Token} />
            {/* <Route path="/maintenance" exact={true} component={Maintenance} /> */}
            <Route path=''  component={PageNotFound} />
          </Switch>
        </div>

      </>
    );
  }
}

export default Dashboard;
