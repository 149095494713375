import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { Theme } from "../../types";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { DEX_API, ChartDateFilter } from "../../helper/constant";
import CustomToolTip from "./CustomToolTip";
import { ChartLoader } from "../common/ChartLoader";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";

interface StateInterface {
  loading: boolean;
  data: any[];
  focusBar: any;
  mouseLeave: any;
  activeDay: string;
}

interface PropsInterface extends actions.ActionsInterface {
  theme: Theme;
}


const volumeAPI = `${DEX_API}/get-total-volume-chart`;

class VolumeChart extends React.Component<PropsInterface, StateInterface> {
  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      focusBar: null,
      mouseLeave: true,
      activeDay: '1W'
    };
  }

  componentDidMount() {
    const DateVal = new Date();
    DateVal.setTime(DateVal.getTime() - 7 * 24 * 60 * 60 * 1000);
    const currentDate = DateVal.toISOString();

    this.getVolumeChart(currentDate);
  }

  getVolumeChart = (fromDate: string) => {
    this.setState({ loading: true });
    return axios
      .post(volumeAPI, {
        from: fromDate,
      })
      .then((res: any) => {
        const data = res.data.data;
        this.setState({
          data: data.reverse(),
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  switchVolumeDay = (days: string) => {
    const newDate = new Date();
    if (days === "1W") {
      newDate.setTime(newDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      const nextDate = newDate.toISOString();
      this.setState({ activeDay: days });
      this.getVolumeChart(nextDate);
    } else if (days === "1M") {
      newDate.setMonth(newDate.getMonth() - 1);
      const nextDate = newDate.toISOString();
      this.setState({ activeDay: days });
      this.getVolumeChart(nextDate);
    } else if (days === "ALL") {
      newDate.setMonth(newDate.getMonth() - 24);
      const nextDate = newDate.toISOString();
      this.setState({ activeDay: days });
      this.getVolumeChart(nextDate);
    }
  };



  render() {
    this.text = GetLanguage(this.context.language);

    let colorFillLight: string, colorFillDark: string, stroke: string;
    if (this.props.theme === "light") {
      colorFillLight = "#0db2d7";
      colorFillDark = "#2b5876";
      stroke = "#0fa9d2";

    }else if(this.props.theme === "dark"){
      colorFillLight = "#C38FFF";
      colorFillDark = "#666666";
      stroke = "#919191";
    } else {
      colorFillLight = "#f85797";
      colorFillDark = "#a4124d";
      stroke = "#a4124d";
    }


    return (
      <div className="chart">
        <h3>{this.text.volume}</h3>
        <div className="chartData">
          <div className="day-filter">
            {ChartDateFilter.map((day, index) => (
              <div
                key={day}
                className={`day ${this.state.activeDay === day ? 'active' : null}`}
                onClick={() => this.switchVolumeDay(day)}
              >
                {day}
              </div>
            ))}
          </div>
          {this.state.loading ?
            (<ChartLoader />)
            :
            (
              <ResponsiveContainer
                width="100%"
                height="100%"
              // margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <BarChart
                  data={this.state.data ? this.state.data : undefined}
                  barGap={0}
                  barCategoryGap={0}
                  maxBarSize={20}
                  margin={{
                    right: 0,
                    bottom: 0,
                    left: 0,
                    top: 0,
                  }}
                  onMouseMove={(state: any) => {
                    if (state.isTooltipActive) {
                      this.setState({ focusBar: state.activeTooltipIndex });
                      this.setState({ mouseLeave: false });
                    } else {
                      this.setState({ focusBar: null });
                      this.setState({ mouseLeave: true });
                    }
                  }}
                >
                  <XAxis
                    dataKey="day"
                    tick={false}
                  // animationEasing={"linear"}
                  />
                  <Tooltip
                    wrapperStyle={{
                      visibility: "visible",
                      fontSize: "22px",
                      fontWeight: 600,
                    }}
                    cursor={false}
                    content={<CustomToolTip chartType="Volume" />}
                    position={{ x: 10, y: -70 }}
                    active={true}
                  />
                  <Bar
                    type="monotone"
                    dataKey="totalVolume"
                    stroke={stroke}
                    fill={colorFillLight}
                    minPointSize={10}
                  >
                    {this.state.data?.map((entry, index) => (
                      <Cell
                        key={index}
                        fill={
                          this.state.focusBar === index || this.state.mouseLeave
                            ? colorFillLight
                            : colorFillDark
                        }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
        </div>
      </div>
    );
  }
}
function mapStateToProps({ theme }: { theme: Theme }) {
  return { theme };
}

export default connect(mapStateToProps, actions)(VolumeChart);
