import React from "react";
import axios from "axios";
import { DEX_API, Delimiter } from "../../helper/constant";
import { ASSET_USD_DECIMALS } from "../../helper/decimals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSearch,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import DefaultLogo from "../../assets/img/default.png";
import Skeleton from "react-loading-skeleton";
import { SEO } from "../common/SEO";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import { Link } from "react-router-dom";
import { CompareAddressValue } from "../../helper/addresses";

// const mapAPI = `${DEX_API}/get-token-map`;
const tokenPriceAPI = `${DEX_API}/get-token-price`;

interface StateInterface {
  tokenPrice: any;
}

class TokenPrice extends React.Component<StateInterface> {
  static contextType = LanguageContext;
  text: any;

  state = {
    mapData: [],
    tokenPrice: [],
    loader: false,
    toggleContent: 0,
    searchData: [],
    page: 0,
    pageLimit: 5,
    searchPhrase: "",
  };

  componentDidMount() {
    this.gettokenPriceData();
  }

  gettokenPriceData = () => {
    this.setState({ loader: true }, () => {
      axios
        .post(tokenPriceAPI)
        .then((res: any) => {
          const data = res.data.data;
          this.setState({
            tokenPrice: data,
          });
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.setState({ loader: false });
        });
    });
  };

  toggleTable(id: number) {
    if (this.state.toggleContent === id) {
      this.setState({
        toggleContent: 0,
      });
    } else {
      this.setState({
        toggleContent: id,
      });
    }
  }

  renderTokenPrice() {
    let data: any = [];
    if (this.state.searchData.length !== 0) {
      data = this.state.searchData;
    } else if (
      this.state.searchPhrase !== "" &&
      this.state.searchData.length === 0
    ) {
      data = null;
    } else {
      if (this.state.page === 0) {
        data = this.state.tokenPrice.slice(0, this.state.pageLimit);
      } else {
        data = this.state.tokenPrice.slice(
          this.state.pageLimit * this.state.page,
          this.state.pageLimit + this.state.pageLimit * this.state.page
        );
      }
    }

    if (data === null) {
      return (
        <div className="token-data">
          <div className="no-data">{this.renderLang("no_token_found")}</div>
        </div>
      );
    }
    const getPrice = data.map((dataArr: any, i: any) => {
      const _first = DEFAULT_TOKEN_LIST.filter((x) =>
        CompareAddressValue(x.address, dataArr.address)
      );
      const logo = _first.length > 0 ? _first[0].logo : DefaultLogo;
      if (dataArr.symbol === "WXDC") {
        dataArr.symbol = "XDC";
      }
      if (!dataArr.isDerived)
        return (
          <div key={`${dataArr.symbol}-${i}`} className="token-data">
            <div className="token-header">
              <div className="">
                <img src={logo} alt={logo} width="30px" /> {dataArr.symbol}
              </div>
              <div>
                $&nbsp;
                {Delimiter(
                  dataArr.price.toFixed(ASSET_USD_DECIMALS[dataArr.symbol] || 6)
                )}
              </div>
              <div>-</div>
              <div>-</div>
            </div>
          </div>
        );

      return (
        <div className="token-data">
          <div className="token-header">
            <div className="">
              <img src={logo} alt={logo} width="30px" /> {dataArr.symbol}
            </div>
            <div>
              $&nbsp;
              {Delimiter(
                dataArr.price.toFixed(ASSET_USD_DECIMALS[dataArr.symbol] || 6)
              )}
            </div>
            <div>{dataArr.pathName.length}</div>
            <div>
              {this.state.toggleContent === i + 1 ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  className="icon"
                  onClick={() => this.toggleTable(i + 1)}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="icon"
                  onClick={() => this.toggleTable(i + 1)}
                />
              )}
            </div>
          </div>
          <div
            className={`token-content ${
              this.state.toggleContent === i + 1 ? "active" : ""
            } `}
          >
            <div className="tx-table__table">
              <table className="table">
                <thead>
                  <tr>
                    <th>{this.renderLang("path_name")}</th>
                    <th>{this.renderLang("price")}</th>
                  </tr>
                </thead>
                <tbody>
                  {dataArr.pathAddress.map((d: any, j: any) => {
                    if (dataArr.pathName[j])
                      return (
                        <tr>
                          <td className="symbol">
                            {dataArr.pathName[j].join("-")}
                          </td>
                          <td>
                            $
                            {Delimiter(
                              dataArr.pathPrice[j].toFixed(
                                ASSET_USD_DECIMALS[dataArr.symbol] || 6
                              )
                            )}
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    });
    return getPrice;
  }

  handleSearch(e: any) {
    const search = e.target.value.toUpperCase();
    const tokenData = this.state.tokenPrice;
    if (search !== "") {
      const data = tokenData.filter((d: any) => {
        return d.symbol.includes(search);
      });
      this.setState({ searchData: data, searchPhrase: search });
    }
    return this.setState({ searchPhrase: search });
  }

  renderPageButton(page: number) {
    let className = "pg-button";
    if (page <= 0) {
      className += " active";
    }

    const activePage = this.state.page + 1;
    if (page === activePage) {
      className += " active";
    }
    return (
      <button
        key={`pg-button-${page} `}
        className={className}
        onClick={() => this.setState({ page: page - 1 })}
      >
        {page}
      </button>
    );
  }

  renderPrevButton() {
    let className = "pg-button";
    if (this.state.page <= 0) {
      className += " disabled";
    }
    return (
      <button
        key={`pg-button-prev`}
        className={className}
        onClick={() => this.setState({ page: this.state.page - 1 })}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  }

  renderNextButton() {
    const pageCount = Math.ceil(
      this.state.tokenPrice.length / this.state.pageLimit
    );

    let className = "pg-button";
    if (this.state.page >= pageCount - 1) {
      className += " disabled";
    }
    return (
      <button
        key={`pg-button-Next`}
        className={className}
        onClick={() => this.setState({ page: this.state.page + 1 })}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  }

  renderPagination() {
    const pageCount = Math.ceil(
      this.state.tokenPrice.length / this.state.pageLimit
    );

    const first = this.renderPrevButton();
    const last = this.renderNextButton();

    if (pageCount <= 1) {
      return this.renderPageButton(1);
    } else if (pageCount < 3) {
      const btns = [];

      for (let i = 0; i < pageCount; i++) {
        btns.push(this.renderPageButton(i + 1));
      }
      return [first, ...btns, last];
    } else {
      const btns = [];
      if (this.state.page === 0) {
        btns.push(this.renderPageButton(1));
        btns.push(this.renderPageButton(2));
        btns.push(this.renderPageButton(3));
      } else if (this.state.page === this.state.tokenPrice.length - 1) {
        btns.push(this.renderPageButton(this.state.tokenPrice.length - 2));
        btns.push(this.renderPageButton(this.state.tokenPrice.length - 1));
        btns.push(this.renderPageButton(this.state.tokenPrice.length));
      } else {
        btns.push(this.renderPageButton(this.state.page));
        if (this.state.page < pageCount)
          btns.push(this.renderPageButton(this.state.page + 1));
        if (this.state.page + 1 < pageCount)
          btns.push(this.renderPageButton(this.state.page + 2));
      }
      return [first, ...btns, last];
    }
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };

  render() {
    this.text = GetLanguage(this.context.language);

    return (
      <div className="tokenPrice-row-wrapper">
        <SEO
          keywords={["globiance", "globiancedex", "dex", "token", "prices"]}
          title="Token Price List | GlobianceDex"
          pathSlug="/token-prices"
          description="Globiance DEX generates the USD prices of assets by deriving its relative prices in different pools in relation to a stablecoin and taking its average as it's price."
        />
        <h3>{this.renderLang("token_price_list")}</h3>
        <br />
        <p>
          {this.renderLang("token_price_desc")} <br />
          <br /> {this.renderLang("token_price_desc1")}{" "}
          {this.renderLang("token_price_desc2")}{" "}
          {this.renderLang("token_price_desc3")} <br /> <br />{" "}
          {this.renderLang("token_price_desc4")} <br />
          <br /> {this.renderLang("token_price_desc5")}{" "}
          <a
            className="link"
            target="_blank"
            rel="noreferrer"
            href={this.renderLang("faq_link")}
          >
            {" "}
            <Link to="/faq">{this.renderLang("faq_link")}</Link>
          </a>
        </p>
        <br />
        <div className="search-token">
          <FontAwesomeIcon icon={faSearch} className="icon" />
          <input
            className="search-field"
            placeholder="Search Symbol"
            onChange={(e) => this.handleSearch(e)}
          />
        </div>
        <div className="titles">
          <div className="name">{this.renderLang("symbol")}</div>
          <div className="name">{this.renderLang("price")}</div>
          <div className="name">{this.renderLang("total_path")}</div>
          <div className="name"></div>
        </div>
        {this.state.loader ? (
          <div>
            <Skeleton count={3} height={80} width={"100%"} />
          </div>
        ) : (
          this.renderTokenPrice()
        )}
        {this.state.searchData.length === 0 &&
        this.state.searchPhrase === "" ? (
          <div className="pagination">{this.renderPagination()}</div>
        ) : null}
      </div>
    );
  }
}
export default TokenPrice;
