import { VALID_CHAIN_TYPE } from "../types";

export const PROJECT_NAME = "Globiance Dex";

export const ChainIdToExplorer = {
  "50": "https://explorer.xinfin.network",
  "51": "https://explorer.apothem.network",
  "551": "https://devnetscan.apothem.network",
};

export const VALID_CHAIN_ID: VALID_CHAIN_TYPE[] = ["50"];

export const DEFAULT_CHAIN_ID = "50";

export const MAX_UINT256 = "1000000000000000000000000000000000000";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const DEX_API = "https://dexapi.globiance.com";

export const DEX_WS = "wss://dexws.globiance.com";

export const LP_DECIMALS = 18;

export const WIDTH_SM = 580;
export const WIDTH_MD = 762;
export const WIDTH_LG = 992;

export const GenerateTxLink = (hash: string): string => {
  return `${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/tx/${hash}`;
};

export const GenerateAccountLink = (account: string): string => {
  return `${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/addr/${account}`;
};

export const FormatTime = (date: Date) => {
  return `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
};

export const FormatTimeToYYYYMMDD = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const PairDecimals = 3;

export const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ChartDateFilter = ["1W", "1M", "ALL"];

export const USDDecimals = 2;

const SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

export const AbbreviateNumber = (number: number) => {
  number = parseFloat(number.toFixed());
  /* tslint:disable:no-bitwise */
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier === 0) return number;
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
};

export const RoundUptoSignificant = (n: string, sig = 3): string => {
  const nSplit = n.split(".");
  const wholeNumberLength = nSplit[0].length;
  if (nSplit.length !== 2) return n;
  let lastIndex = 0;
  for (let i = 0; i < nSplit[1].length; i++) {
    const ch = nSplit[1][i];
    if (ch !== "0") {
      lastIndex = i;
      break;
    }
  }
  return nSplit.join(".").slice(0, wholeNumberLength + 1 + lastIndex + sig);
};

export const Delimiter = (number: any) => {
  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
  // ! LOOKBEHIND not supported in safari
  // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};
