import { provider as Provider } from "xdc3-core";
import * as Types from "./types";
import { Token, PriceItem, SettingsModal, Lang } from "../../types";

export interface WalletConnectedArgs {
  address: string;
  chain_id: number;
  loader: string;
  provider: Provider;
  valid_network: boolean;
}

export interface ActionsInterface {
  WalletConnected: (data: WalletConnectedArgs) => {};
  NetworkChanged: (chain_id: number) => {};
  AccountChanged: (address: string) => {};
  WalletDisconnected: () => {};
  NetworkValid: () => {};
  NetworkInValid: () => {};
  ChangeTheme: (theme: Theme) => {};
  AddCustomToken: (token: Token) => {};
  RemoveCustomToken: (token: Token) => {};
  AddCustomPool: (first: Token, second: Token, address: string) => {};
  RemoveCustomPool: (address: string) => {};
  FetchAssetPrice: (data: PriceItem[]) => {};
  AcknowledgeFee: (symbol: string) => {};
  UpdateSettings: (settings: SettingsModal) => {};
  SwitchLanguage: (lang: Lang) => {};
}

export type Theme = "light" | "dark" | "iri";


export const WalletConnected = ({
  address,
  chain_id,
  loader,
  provider,
  valid_network,
}: WalletConnectedArgs) => {
  return {
    type: Types.WALLET_CONNECTED,
    payload: { address, chain_id, loader, provider, valid_network },
  };
};

export const NetworkChanged = (chain_id: number) => {
  return {
    type: Types.WALLET_CHAIN_CHANGED,
    payload: { chain_id },
  };
};

export const AccountChanged = (address: string) => {
  return {
    type: Types.WALLET_ADDRESS_CHANGED,
    payload: { address },
  };
};

export const WalletDisconnected = () => {
  return {
    type: Types.WALLET_DISCONNECTED,
  };
};

export const NetworkValid = () => {
  return {
    type: Types.NETWORK_VALID,
  };
};

export const NetworkInValid = () => {
  return {
    type: Types.NETWORK_INVALID,
  };
};

export const ChangeTheme = (theme: Theme) => {
  return {
    type: Types.THEME_CHANGED,
    payload: theme,
  };
};

export const AddCustomToken = (token: Token) => {
  return {
    type: Types.CUSTOM_TOKEN_ADDED,
    payload: token,
  };
};

export const RemoveCustomToken = (token: Token) => {
  return {
    type: Types.CUSTOM_TOKEN_REMOVED,
    payload: token,
  };
};

export const AddCustomPool = (first: Token, second: Token, address: string) => {
  return {
    type: Types.CUSTOM_POOL_ADDED,
    payload: { first, second, address },
  };
};

export const RemoveCustomPool = (address: string) => {
  return {
    type: Types.CUSTOM_POOL_REMOVED,
    payload: address,
  };
};

export const FetchAssetPrice = (data: PriceItem[]) => {
  const assetPrice: any = data.reduce<{ [symbol: string]: number }>((acc, cur) => {
    acc[cur.symbol] = cur.price
    return acc;
  }, {});

  const addressPrice: any = data.reduce<{ [adddress: string]: number }>((acc, cur) => {
    acc[cur.address] = cur.price
    return acc;
  }, {});
  assetPrice.XDC = assetPrice.WXDC;
  return {
    type: Types.FETCHED_LATEST_PRICE,
    payload: {assetPrice, addressPrice},
  };
};

export const AcknowledgeFee = (symbol: string) => {
  return {
    type: Types.ACKNOWLEDGE_FEE,
    payload: symbol,
  };
};

export const UpdateSettings = (settings: SettingsModal) => {
  return {
    type: Types.SETTINGS_CHANGED,
    payload: settings,
  };
};

export const SwitchLanguage = (lang: Lang) => {
  return {
    type: Types.LANGUAGE_SWITCH,
    payload: lang,
  };
};
