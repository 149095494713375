import  { unitMap } from "xdc3-utils";
import Big from "bignumber.js"

const _mapUnit: any = unitMap;

const ValueToUnit = Object.keys(_mapUnit).reduce(
  (acc: { [k: string]: string }, cur: string) => {
    acc[_mapUnit[cur].length - 1] = cur as string;
    return acc;
  },
  {}
);

// @ts-ignore
function getUnitName(decimal: number): string {
  return ValueToUnit[`${decimal}`];
}

export const RemoveExpo = (x: string | number): string => {
  const data = String(x).split(/[eE]/);
  if (data.length === 1) return data[0];

  let z = "";
  const sign = x < 0 ? "-" : "";
  const str = data[0].replace(".", "");
  let mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};

export const FromDecimals = (x: number | string, decimal = 18): string => {
  x = RemoveExpo(x)
  // const unitName: any = getUnitName(decimal)
  // return Utils.fromWei(x, unitName)
  return RemoveExpo(new Big(x).div(new Big(10).pow(`${decimal}`)).toString())
}

export const ToDecimals = (x: number | string, decimal = 18): string => {
  if (typeof x === "string") {
    if (x.trim() === "") x = 0
  }
  x = RemoveExpo(x)

  // const unitName: any = getUnitName(decimal)
  // return Utils.toWei(x, unitName)

  return (RemoveExpo(new Big(x).multipliedBy(new Big(10).pow(`${decimal}`)).toString())).split(".")[0]
}

export const ToFixedFloor = (x: number | string, decimals = 3): string => {
  if (typeof x === "string") {
    x = parseFloat(x);
  }
  x = Math.floor(x * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return RemoveExpo(x);
};

export const ToFixedCeil = (x: number | string, decimals = 3): string => {
  if (typeof x === "string") {
    x = parseFloat(x);
  }
  x = Math.ceil(x * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return RemoveExpo(x);
};

export const RemoveDecimal = (x:number|string) :string => {
  x = RemoveExpo(x)
  return x.split(".")[0]
}

export const ROUND_DECIMALS = 12;

export const ROUND_DECIMALS_PAIR: { [key: string]: [number, number] } = {
  "GBEX-XDC": [0, 12],
  "XDC-GBEX": [12, 0],

  "GBEX-USDG": [0, 12],
  "USDG-GBEX": [12, 0],

  "GBEX-WXDC": [0, 12],
  "WXDC-GBEX": [12, 0],

  "GBEX-EURG": [0, 12],
  "EURG-GBEX": [12, 0],

  "GBEX-SRX": [0, 12],
  "SRX-GBEX": [12, 0],

  "SRX-EURG": [4, 3],
  "EURG-SRX": [3, 4],

  "SRX-USDG": [4, 3],
  "USDG-SRX": [3, 4],

  "SRX-XDC": [4, 2],
  "XDC-SRX": [2, 4],

  "SRX-WXDC": [4, 2],
  "WXDC-SRX": [2, 4],

  "PLI-WXDC": [4, 2],
  "WXDC-PLI": [2, 4],
};

export const ROUND_DECIMALS_AMOUNT: { [key: string]: number } = {
  GBEX: 0,
  XDC: 2,
  WXDC: 2,
  SRX: 2,
  EURG: 2,
  USDG: 2,
  PLI: 2,
  WTK:2,
  DC:2,
  BLKZ:2
};

export const ROUND_LP_DECIMALS = 2;

export const USD_DECIMALS = 2;

export const POOL_DECIMALS = 2;

export const ASSET_USD_DECIMALS: { [key: string]: number } = {
  GBEX: 12,
  XDC: 4,
  WXDC: 4,
  SRX: 4,
  EURG: 2,
  USDG: 2,
  PLI: 4,
  WTK:4,
  DC:4,
  BLKZ:4
};
