import React from "react";
import {
  Bar,
  Legend,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell} from "recharts";
import axios from "axios";
import { connect } from "react-redux";
import { DEX_API } from "../../../helper/constant";
import { HLOC } from "../../../types";
import * as actions from "../../../redux/actions/index";
import { Theme } from "../../../types";

import CustomTool from "./CustomTool";
import { ChartLoader } from "../../common/ChartLoader";

interface StateInterface {
  tradeChart: HLOC[] | null;
  payload: { token0: number; token1: number } | null | undefined;
  loading: boolean;
  focusBar: null;
  mouseLeave: boolean;
  windowWidth: any;
}

interface TokenLmt {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

interface PropsInterface extends actions.ActionsInterface {
  pair: string;
  first?: TokenLmt;
  second?: TokenLmt;
  theme: Theme;
}

class TradeVolume extends React.Component<PropsInterface, StateInterface> {
  ONE_DAY: number = 86400 * 1000;
  limit: number = 30;

  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      tradeChart: null,
      payload: null,
      loading: true,
      focusBar: null,
      mouseLeave: true,
      windowWidth: window.innerWidth
    };
  }

  componentDidMount() {
    this.getTradeChart();
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  getTradeChart() {
    const fromTs = Date.now() - this.limit * this.ONE_DAY;
    const from = new Date(fromTs);
    axios
      .post(`${DEX_API}/pair-chart-data`, {
        pair: this.props.pair,
        from,
      })
      .then((resp: any) => {
        if (resp && resp.data && resp.data.data) {
          this.setState({ tradeChart: resp.data.data, loading: false });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleResize = (e:any) => {
    this.setState({ windowWidth: window.innerWidth });
  };



  render() {
    const axisLabel = this.props.first?.symbol;
    let colorFillLight:string,colorFillDark:string ;
    if (this.props.theme === "light") {
      colorFillLight = "#0fa9d2";
      colorFillDark = "#2b5876";

    }else if(this.props.theme === 'dark'){
      colorFillLight = '#C38FFF';
      colorFillDark = '#959595';
    }  else {
      colorFillLight = "#f85797";
      colorFillDark = "#9c3e5c";
    }

    let labels = true, labelWidth = 150;
    if(this.state.windowWidth <= 800){
      labels = false;
      labelWidth = 0;
    }



    return (
      <div className="chart-trade-volume">
        <div className="chart-trade-volume-canvas">
          <div className="chart-liqduidity-legend">
            &nbsp;
          </div>
          {this.state.loading ? (
            <ChartLoader />
          ) : (
            <ResponsiveContainer width="95%" height="95%">
              <BarChart
                barGap={0}
                barCategoryGap={0}
                maxBarSize={10}
                data={this.state.tradeChart ? this.state.tradeChart : undefined}
                margin={{
                  right: 0,
                  bottom: 0,
                  top: 30,
                  left: 0,
                }}
                onMouseMove={(state: any) => {
                  if (state.isTooltipActive) {
                    this.setState({ focusBar: state.activeTooltipIndex });
                    this.setState({ mouseLeave: false });
                  } else {
                    this.setState({ focusBar: null });
                    this.setState({ mouseLeave: true });
                  }
                }}
              >
                <XAxis dataKey="_id" tick={labels} stroke={colorFillLight} />
                <YAxis width={labelWidth}  stroke={colorFillLight} />
                <Tooltip
                  wrapperStyle={{ visibility: "visible" }}
                  content={<CustomTool chartType="tradeVolume" symbol1={axisLabel} />}
                  cursor={false}
                  position={{ x: 10, y: -100 }}
                  viewBox={{ width: 400, height: 400 }}
                  active={true}
                />
                <Legend />
                <Bar dataKey="total0" fill={colorFillLight} name={axisLabel}>
                  {this.state.tradeChart?.map((entry, index) => (
                    <Cell
                      fill={
                        this.state.focusBar === index || this.state.mouseLeave
                          ? colorFillLight
                          : colorFillDark
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ theme }: { theme: Theme }) {
  return { theme };
}

export default connect(mapStateToProps, actions)(TradeVolume);
