import { Language } from '../../helper/languages';


// IMPORT ALL TRANSLATION FILES,
import EN from "./EN.json"
import AR from "./AR.json"
import TH from "./TH.json"
import TR from "./TR.json"
import GE from "./GE.json"
import ES from "./ES.json"
import FR from "./FR.json"
import MY from "./MY.json"
import ID from "./ID.json"
import VN from "./VN.json"
import CN from "./CN.json"
import PT from "./PT.json"
import PH from "./PH.json"
import KR from "./KR.json"
import JP from "./JP.json"


const translations: { [language: string]: object } = {
  "EN": EN,
  "AR": AR,
  "TH": TH,
  "TR": TR,
  "GE": GE,
  "ES": ES,
  "FR": FR,
  "MY": MY,
  "ID": ID,
  "VN": VN,
  "CN": CN,
  "PT": PT,
  "PH": PH,
  "KR": KR,
  "JP": JP,
}

export const GetLanguage = (language: Language) => {
  return translations[language]
}