import {
  faArrowDown,
  faCog,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import Utils from "xdc3-utils";

import TokenList from "../TokenList";
import { Token } from "@globiance/default-token-list";

import {
  FromDecimals,
  RemoveExpo,
  ROUND_LP_DECIMALS,
  ToDecimals,
} from "../../helper/decimals";
import * as actions from "../../redux/actions/index";
import {
  Wallet,
  Theme,
  Settings,
  SettingsModal,
  AssetPrice,
  TokenList as TokenListType,
  AddressPrice,
} from "../../types";
import {
  GeneralContractTxPayable,
  BalanceOf,
  GeneralContractTx,
  Approve,
  GetQuote,
  IsErc20Address,
} from "../../helper/crypto";
import SettingsComp from "../common/settings";
import { RouteComponentProps, Link } from "react-router-dom";
import AcknowledgeFee from "../common/acknowledgeFee";
import DefaultLogo from "../../assets/img/default.png";
import { ROUND_DECIMALS_PAIR } from "../../helper/decimals";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import {
  DEX_API,
  LP_DECIMALS,
  RoundUptoSignificant,
} from "../../helper/constant";
import BlockNumber from "../common/BlockNumber";
import { SEO } from "../common/SEO";
import axios from "axios";
import { ForceShowModal } from "xdc-connect";
import PinkGirl1 from '../../assets/dex/iri/landing-main-2-swap.png';

interface PropsInterface extends actions.ActionsInterface {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  assetPrice: AssetPrice;
  addressPrice: AddressPrice;
  history: RouteComponentProps["history"];
  tokenList: TokenListType;
}

interface StateInterface {
  firstCurrency: Token | null;
  secondCurrency: Token | null;
  showModal: boolean;
  modalContent: ReactElement | null;
  firstCurrencyAmount: string | number;
  secondCurrencyAmount: string | number;
  firstCurrencyBalance: { balance: string | null; allowance: string | null };
  secondCurrencyBalance: { balance: string | null; allowance: string | null };
  valid: boolean;
  error_message: string;
  slippage: number;
  deadline: number;
  button: ReactElement;
  approxLP: number | null;
  exists: boolean;
  rate: string | null;
  reverse: boolean;
  pairNotExist: boolean;
}

type CurrencyType = "firstCurrency" | "secondCurrency";

class AddLiquidity extends React.Component<PropsInterface, StateInterface> {
  i: number = 0;
  getRateDebounce: _.DebouncedFunc<any>;
  polling_interval: any;
  static contextType = LanguageContext;
  text: any;

  constructor(props: any) {
    super(props);

    this.state = {
      firstCurrency: null,
      secondCurrency: null,
      firstCurrencyAmount: 0,
      secondCurrencyAmount: 0,
      approxLP: 0,
      showModal: false,
      modalContent: null,
      slippage: this.props.settings.slippage,
      deadline: this.props.settings.deadline,
      firstCurrencyBalance: { balance: null, allowance: null },
      secondCurrencyBalance: { balance: null, allowance: null },
      valid: false,
      rate: null,
      error_message: "CONNECT WALLET",
      button: <button onClick={() => ForceShowModal()}>CONNECT WALLET</button>,
      exists: false,
      reverse: false,
      pairNotExist: true,
    };

    this.toggle = this.toggle.bind(this);
    this.showTokenList = this.showTokenList.bind(this);
    this.showSettings = this.showSettings.bind(this);
    this.addLiquidity = this.addLiquidity.bind(this);
    this.approve = this.approve.bind(this);
    this.onClickAcknowledgeFee = this.onClickAcknowledgeFee.bind(this);
    this.selectToken = this.selectToken.bind(this);
    this.loadToken = this.loadToken.bind(this);

    this.getRateDebounce = _.debounce(this.getRate, 500);
  }

  componentDidMount() {
    this.loadToken();

    if (this.state.firstCurrency) {
      this.getTokenBalance("firstCurrency", this.state.firstCurrency);
    }
    if (this.state.secondCurrency) {
      this.getTokenBalance("secondCurrency", this.state.secondCurrency);
    }
    this.calculateRate();

    axios
      .post(`${DEX_API}/get-token-price`)
      .then((resp) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          this.props.FetchAssetPrice(data.data);
        }
      })
      .catch((e) => console.error(e));


  }

  componentDidUpdate(prevProps: PropsInterface) {
    if (!_.isEqual(prevProps.wallet, this.props.wallet)) {
      if (this.state.firstCurrency) {
        this.getTokenBalance("firstCurrency", this.state.firstCurrency);
      }
      if (this.state.secondCurrency) {
        this.getTokenBalance("secondCurrency", this.state.secondCurrency);

      }
      this.calculateRate();
    }

  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : "";
    return langText;
  };

  loadToken() {
    const location = this.props.history.location;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const tokenLoadList = [];

    let firstSelected = false;

    if (params.inputCurrency) {
      let isDefault: boolean = false;
      for (const token of this.props.tokenList) {
        if (
          params.inputCurrency.toLowerCase() === token.symbol.toLowerCase() ||
          params.inputCurrency.toLowerCase() === token.address.toLowerCase()
        ) {
          this.selectToken("firstCurrency")(token);
          isDefault = true;
          firstSelected = true;
          break;
        }
      }
      if (isDefault === false && Utils.isAddress(params.inputCurrency)) {
        tokenLoadList.push(params.inputCurrency);
      }
    }
    if (params.outputCurrency) {
      let isDefault: boolean = false;
      for (const token of this.props.tokenList) {
        if (
          params.outputCurrency.toLowerCase() === token.symbol.toLowerCase() ||
          params.outputCurrency.toLowerCase() === token.address.toLowerCase()
        ) {
          this.selectToken("secondCurrency")(token);
          isDefault = true;
          break;
        }
      }
      if (isDefault === false && Utils.isAddress(params.outputCurrency)) {
        tokenLoadList.push(params.outputCurrency);
      }
    }

    // show confirmation modal for new tokens - tokenLoadList

    if (tokenLoadList.length > 0) {
      Promise.all(tokenLoadList.map((address) => IsErc20Address(address))).then(
        (resp) => {
          const relevantToken = resp.filter(({ isErc20 }) => isErc20);
          if (relevantToken.length > 0) {
            const modalContent = (
              <div className={`token-load-confirm`}>
                <div className={`${this.props.theme}`}>
                {/* <div className="note">{this.renderLang("note")}</div> */}
                <div className="load-token">{this.renderLang("loadToken")}</div>
                <div className="list">
                  {relevantToken.map((x, i) => (
                    <li key={`token-list-confirm-${x.name}-${i}`}>
                      <img className="logo" src={DefaultLogo} alt={x.symbol} />
                      <div className="body">
                        <div className="symbol">{x.symbol}</div>
                        <div className="name">{x.name}</div>
                      </div>
                    </li>
                  ))}
                </div>
                <div className="footer">
                  <button
                    onClick={() => {
                      relevantToken.forEach((token) => {
                        if (
                          token.name &&
                          token.symbol &&
                          token.address &&
                          token.decimals
                        ) {
                          const _token = {
                            name: token.name,
                            symbol: token.symbol,
                            address: token.address,
                            decimals: parseInt(token.decimals),
                            logo: DefaultLogo,
                          };

                          if (
                            params.inputCurrency.toLowerCase() ===
                            token.address.toLowerCase()
                          ) {
                            this.selectToken("firstCurrency")(_token);
                            firstSelected = true;
                          } else if (
                            params.outputCurrency.toLowerCase() ===
                            token.address.toLowerCase()
                          ) {
                            this.selectToken("secondCurrency")(_token);
                          }
                          this.props.AddCustomToken(_token);
                        }
                      });
                      this.setState({ showModal: false });
                    }}
                  >
                    {this.renderLang("import")}
                  </button>
                </div>
                </div>
              </div>
            );

            this.setState({ modalContent, showModal: true });
          }
        }
      );
    }

    if (firstSelected === false) {
      this.selectToken("firstCurrency")(
        this.props.tokenList.filter((x) => x.symbol === "XDC")[0]
      );
    }
  }

  selectToken = (type: CurrencyType) => (token: Token, cb?: any) => {
    // console.log(token, type);
    const q: any = {
      showModal: false,
    };
    if (type === "firstCurrency") {
      if (this.state.secondCurrency?.symbol === token.symbol) {
        this.setState({ ...q }, () => {
          this.toggle();
          if (cb) cb();
        });
        return;
      }
    } else {
      if (this.state.firstCurrency?.symbol === token.symbol) {
        this.setState({ ...q }, () => {
          this.toggle();
          if (cb) cb();
        });
        return;
      }
    }

    q[type] = token;
    this.setState({ ...q }, () => {
      this.getRateDebounce.cancel();
      this.calculateRate();
      if (type === "firstCurrency") {
        this.getTokenBalance("firstCurrency", token);
      }
      if (type === "secondCurrency") {
        this.getTokenBalance("secondCurrency", token);
      }

      this.checkValidity();
      if (cb) cb();
      if (
        token.feeOnTransfer &&
        !this.props.settings.acknowledgedFee.includes(token.symbol)
      ) {
        this.renderAcknowledgeFee(token);
      }
    });
  };

  renderAcknowledgeFee(token: Token) {
    this.setState({
      showModal: true,
      modalContent: (
        <AcknowledgeFee
          theme={this.props.theme}
          token={token}
          cb={this.onClickAcknowledgeFee}
        />
      ),
    });
  }

  toggle() {
    const first = this.state.firstCurrency;
    const second = this.state.secondCurrency;
    // const firstAmount = this.state.firstCurrencyAmount;
    // const secondAmount = this.state.secondCurrencyAmount;
    this.setState(
      {
        firstCurrency: second,
        secondCurrency: first,
        // firstCurrencyAmount: secondAmount,
        reverse: !this.state.reverse,
        secondCurrencyAmount: "",
        rate: "",
      },
      () => {
        this.getRateDebounce.cancel();
        this.calculateRate();
        if (second) this.getTokenBalance("firstCurrency", second);
        if (first) this.getTokenBalance("secondCurrency", first);
      }
    );
  }

  showSettings() {
    const settings: ReactElement = (
      <SettingsComp
        deadline={this.props.settings.deadline}
        slippage={this.props.settings.slippage}
        cb={(settings: SettingsModal) => this.updateSettings(settings)}
      />
    );
    this.setState({ showModal: true, modalContent: settings });
  }

  calculateRate() {
    if (this.state.firstCurrency == null || this.state.secondCurrency == null)
      return;

    this.getRateDebounce();
  }

  showTokenList(disabled: string[] = [], cb: (token: Token) => void) {
    const tokenList: ReactElement = (
      <TokenList disabled={disabled} onTokenSelect={cb} />
    );
    this.setState({ showModal: true, modalContent: tokenList });
  }

  updateSettings(settings: SettingsModal) {
    this.props.UpdateSettings(settings);
    this.setState({
      showModal: false,
    });
  }

  onClickAcknowledgeFee(token: Token, dontShowAgain: boolean) {
    if (dontShowAgain) {
      this.props.AcknowledgeFee(token.symbol);
    }
    this.showSettings();
  }

  updateBalance(currency: CurrencyType, token: Token) {
    BalanceOf(token)
      .then((balances) => {
        if (balances) {
          const q: any = {};
          q[`${currency}Balance`] = {
            balance: balances[0],
            allowance: balances[1],
          };

          this.setState({ ...q }, this.checkValidity);
        } else this.checkValidity();
      })
      .catch(console.error);
  }

  getTokenBalance(currency: CurrencyType, token: Token) {
    if (!this.props.wallet.connected) return;
    this.updateBalance(currency, token);
  }

  getpairDecimals(): [number, number] {
    if (!this.state.firstCurrency || !this.state.secondCurrency) return [3, 3];

    return (
      ROUND_DECIMALS_PAIR[
        `${this.state.firstCurrency.symbol}-${this.state.secondCurrency.symbol}`
      ] || [3, 3]
    );
  }

  getRate() {
    const amount = RemoveExpo(this.state.firstCurrencyAmount);

    if (this.state.firstCurrency == null || this.state.secondCurrency == null)
      return;

    const lockedFirstAmount = this.state.firstCurrencyAmount;

    const amountInDecimals = ToDecimals(
      amount,
      this.state.firstCurrency.decimals
    );

    this.getQuote(amountInDecimals)
      .then(([exists, rate, approxMint, totalSupply, reverse]) => {
        if (
          exists &&
          rate !== null &&
          rate !== 0 &&
          this.state.secondCurrency
        ) {
          rate = parseFloat(
            FromDecimals(rate, this.state.secondCurrency.decimals)
          );
          const nonClippedRate = RemoveExpo(rate as number);

          let secondaryAmount = parseFloat(nonClippedRate).toFixed(
            this.getpairDecimals()[1]
          );

          if (parseFloat(secondaryAmount) === 0)
            secondaryAmount = RoundUptoSignificant(nonClippedRate, 4);

          const _rate = RemoveExpo(
            (
              parseFloat(lockedFirstAmount + "") / parseFloat(secondaryAmount)
            ).toFixed(this.getpairDecimals()[0])
          );

          approxMint = parseFloat(
            FromDecimals(approxMint as number, LP_DECIMALS)
          );

          this.setState(
            {
              secondCurrencyAmount: secondaryAmount,
              approxLP: approxMint,
              exists,
              rate: _rate,
              reverse,
            },
            () => {
              this.checkValidity();
              setTimeout(() => this.getRateDebounce(), 5000);
            }
          );
        } else {
          this.setState(
            {
              secondCurrencyAmount: 0,
              approxLP: 0,
              exists,
            },
            () => {
              this.checkValidity();
            }
          );
        }
      })
      .catch(console.error);
  }

  renderTokenSelect(selected: Token | null, type: CurrencyType): ReactElement {
    const cb = (token: Token) => {
      const q: any = {
        showModal: false,
      };

      if (type === "firstCurrency") {
        if (this.state.secondCurrency?.symbol === token.symbol) {
          this.setState({ ...q }, () => this.toggle());
          return;
        }
      } else {
        if (this.state.firstCurrency?.symbol === token.symbol) {
          this.setState({ ...q }, () => this.toggle());
          return;
        }
      }

      q[type] = token;
      this.setState({ ...q }, () => {
        this.getRateDebounce.cancel();
        this.calculateRate();
        this.getTokenBalance(type, token);
      });
    };

    if (!selected)
      return (
        <div
          className="select-token"
          onClick={() => this.showTokenList(undefined, cb)}
        >
          <div className="token-name">
            Select Token <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      );

    return (
      <div
        className="select-token"
        onClick={() => this.showTokenList([selected.symbol], cb)}
      >
        <div className="token-badge">
          <div className="token-name">
            <img src={selected.logo} alt={selected.symbol} />
            {selected.symbol} <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      </div>
    );
  }

  async getQuote(
    amount: string
  ): Promise<[boolean, number | null, number | null, number | null, boolean]> {
    if (!this.state.firstCurrency || !this.state.secondCurrency)
      return [false, 0, 0, 0, false];
    const [exists, quote, approxMin, totalSupply, reverse] = await GetQuote(
      amount,
      this.state.firstCurrency?.address,
      this.state.secondCurrency?.address
    );

    if (quote === null) return [false, 0, 0, 0, false];
    return [exists, quote, approxMin, totalSupply, reverse];
  }

  updateSwapAmount(type: CurrencyType, amount: string) {
    amount = amount.replace(/^0+/, "");
    amount = amount.replace(/^\./, "0.");

    if (_.isEmpty(amount)) amount = "0";

    if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]*)$/g.test(amount) === false) {
      return;
    }
    const q: any = {};
    q[`${type}Amount`] = amount;
    this.setState({ ...q }, () => {
      this.calculateRate();
    });
  }

  checkValidity() {
    if (!this.state.firstCurrency || !this.state.secondCurrency)
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("select_pair")}
          </button>
        ),
      });

    if (this.state.exists === false)
      return this.setState({
        button: (
          <>
            <button className={"disabled"}>
              {this.renderLang("pair_not_exists")}
            </button>
            {this.state.pairNotExist === false && (
            <div className="create-pair-link">
              <Link
                to={`/create-pair?outputCurrency=${this.state.secondCurrency?.symbol}&inputCurrency=${this.state.firstCurrency?.symbol}`}
              >
                Create this Pair{" "}
              </Link>
            </div>
            )}
          </>
        ),
        pairNotExist:false
      });

    if (this.props.wallet.connected === false)
      return this.setState({
        button: (
          <button onClick={() => ForceShowModal()}>
            {this.renderLang("landing_header_connect_button")}
          </button>
        ),
      });

    if (
      !this.state.firstCurrencyAmount ||
      parseFloat(this.state.firstCurrencyAmount as string) === 0 ||
      !this.state.secondCurrencyAmount ||
      parseFloat(this.state.secondCurrencyAmount as string) === 0
    ) {
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("enter_valid_amt")}
          </button>
        ),
      });
    }

    if (
      this.state.firstCurrencyBalance.balance &&
      parseFloat(this.state.firstCurrencyBalance.balance) <
        parseFloat(
          ToDecimals(
            this.state.firstCurrencyAmount as string,
            this.state.firstCurrency.decimals
          )
        )
    )
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("insufficient_bal")}
          </button>
        ),
      });

    if (
      this.state.secondCurrencyBalance.balance &&
      parseFloat(this.state.secondCurrencyBalance.balance) <
        parseFloat(
          ToDecimals(
            this.state.secondCurrencyAmount as string,
            this.state.secondCurrency.decimals
          )
        )
    )
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("insufficient_bal")}
          </button>
        ),
      });

    if (
      this.state.firstCurrency.symbol !== "XDC" &&
      parseFloat(
        ToDecimals(
          parseFloat(this.state.firstCurrencyAmount + ""),
          this.state.firstCurrency.decimals
        )
      ) > parseFloat(this.state.firstCurrencyBalance.allowance + "")
    ) {
      return this.setState({
        button: (
          <button onClick={() => this.approve("firstCurrency")} className={""}>
            {this.renderLang("approve")} {this.state.firstCurrency.symbol}
          </button>
        ),
      });
    }

    if (
      this.state.secondCurrency.symbol !== "XDC" &&
      parseFloat(
        ToDecimals(
          parseFloat(this.state.secondCurrencyAmount + ""),
          this.state.secondCurrency.decimals
        )
      ) > parseFloat(this.state.secondCurrencyBalance.allowance + "")
    ) {
      return this.setState({
        button: (
          <button onClick={() => this.approve("secondCurrency")} className={""}>
            {this.renderLang("approve")} {this.state.secondCurrency.symbol}
          </button>
        ),
      });
    }

    this.setState({
      button: (
        <button onClick={this.addLiquidity} className={""}>
          {this.renderLang("add")}
        </button>
      ),
    });
  }

  approve(type: CurrencyType) {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.props.wallet.address
    )
      return;

    if (type === "firstCurrency")
      Approve(this.state.firstCurrency.address)
        .then(() => this.updateBalance(type, this.state.firstCurrency as Token))
        .catch(console.error);
    else if (type === "secondCurrency")
      Approve(this.state.secondCurrency.address)
        .then(() =>
          this.updateBalance(type, this.state.secondCurrency as Token)
        )
        .catch(console.error);
  }

  renderAssetPrice(type: CurrencyType) {
    const currency = this.state[type];
    const amount = this.state[`${type}Amount`] as string;
    if (!currency || !amount) return;

    const frmtAddress = (Utils.fromXdcAddress(currency.address) as string).toLowerCase()

    if (!this.props.addressPrice || !this.props.addressPrice[frmtAddress])
      return;

    const usdTotal = (
      parseFloat(amount) * this.props.addressPrice[frmtAddress]
    ).toFixed(2);
    return (
      <>
        &nbsp;&#8776;&nbsp;{usdTotal}&nbsp;{this.renderLang("usd")}
      </>
    );
  }

  addLiquidity() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.props.wallet.address
    )
      return;

    const deadline =
      (Date.now() / 1000).toFixed(0) + this.props.settings.deadline;

    if (
      this.state.firstCurrency.symbol === "XDC" ||
      this.state.secondCurrency.symbol === "XDC"
    ) {
      // method = addLiquidityETH; payable
      const method = "addLiquidityETH";
      let ethAmount, tokenAmount, minToken, minEth, address;

      if (this.state.firstCurrency.symbol === "XDC") {
        ethAmount = ToDecimals(this.state.firstCurrencyAmount);

        tokenAmount = ToDecimals(
          this.state.secondCurrencyAmount,
          this.state.secondCurrency.decimals
        );

        minEth = ToDecimals(
          (parseFloat(this.state.firstCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100
        );

        minToken = ToDecimals(
          (parseFloat(this.state.secondCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100,
          this.state.secondCurrency.decimals
        );

        address = this.state.secondCurrency.address;
      } else {
        ethAmount = ToDecimals(this.state.secondCurrencyAmount);
        tokenAmount = ToDecimals(
          this.state.firstCurrencyAmount,
          this.state.firstCurrency.decimals
        );

        minEth = ToDecimals(
          (parseFloat(this.state.secondCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100
        );

        minToken = ToDecimals(
          (parseFloat(this.state.firstCurrencyAmount as string) *
            (100 - this.props.settings.slippage)) /
            100,
          this.state.firstCurrency.decimals
        );

        address = this.state.firstCurrency.address;
      }
      GeneralContractTxPayable("router", method, ethAmount, [
        address,
        tokenAmount,
        minToken,
        minEth,
        this.props.wallet.address,
        deadline,
      ])
        .then(() => {
          if (this.state.firstCurrency)
            this.getTokenBalance("firstCurrency", this.state.firstCurrency);
          if (this.state.secondCurrency)
            this.getTokenBalance("secondCurrency", this.state.secondCurrency);
        })
        .catch(console.log);
    } else {
      // method = addLiquidity
      const method = "addLiquidity";
      const deadline =
        parseFloat((Date.now() / 1000).toFixed(0)) +
        this.props.settings.deadline;
      const amountA = RemoveExpo(
        parseFloat(
          ToDecimals(
            parseFloat(this.state.firstCurrencyAmount as string),
            this.state.firstCurrency.decimals
          )
        ).toFixed(0)
      );
      const amountB = RemoveExpo(
        parseFloat(
          ToDecimals(
            parseFloat(this.state.secondCurrencyAmount as string),
            this.state.secondCurrency.decimals
          )
        ).toFixed(0)
      );
      const minA = ToDecimals(
        (parseFloat(this.state.firstCurrencyAmount as string) *
          (100 - this.props.settings.slippage)) /
          100,
        this.state.firstCurrency.decimals
      );
      const minB = ToDecimals(
        (parseFloat(this.state.secondCurrencyAmount as string) *
          (100 - this.props.settings.slippage)) /
          100,
        this.state.secondCurrency.decimals
      );

      GeneralContractTx("router", method, [
        this.state.firstCurrency.address,
        this.state.secondCurrency.address,
        amountA,
        amountB,
        minA,
        minB,
        this.props.wallet.address,
        deadline,
      ])
        .then(() => {
          if (this.state.firstCurrency)
            this.getTokenBalance("firstCurrency", this.state.firstCurrency);
          if (this.state.secondCurrency)
            this.getTokenBalance("secondCurrency", this.state.secondCurrency);
        })
        .catch(console.log);
    }
  }

  renderInverseRate() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.state.firstCurrencyAmount ||
      !this.state.secondCurrencyAmount
    )
      return "";

    let approxLP = "";

    if (this.state.approxLP) {
      approxLP = RemoveExpo(this.state.approxLP.toFixed(ROUND_LP_DECIMALS));
      if (parseFloat(approxLP) === 0) {
        approxLP = RoundUptoSignificant(RemoveExpo(this.state.approxLP));
      }
    }

    return (
      <>
        <div className="rate">
          1 {this.state.secondCurrency.symbol}&nbsp;&#8776;&nbsp;
          {this.state.rate} {this.state.firstCurrency.symbol}
        </div>
        <div className="rate">
          {this.renderLang("share")}&nbsp;&#8776;&nbsp;
          {approxLP}{" "}
          {this.state.reverse ? (
            <>
              {this.state.secondCurrency.symbol}-
              {this.state.firstCurrency.symbol}
            </>
          ) : (
            <>
              {this.state.firstCurrency.symbol}-
              {this.state.secondCurrency.symbol}
            </>
          )}{" "}
          {this.renderLang("lp_token")}
        </div>
      </>
    );
  }

  render() {
    this.text = GetLanguage(this.context.language);
    return (
      <div className="dashboard">
        <div className="swap">
          <SEO
            keywords={["globiance", "globiancedex", "dex", "add", "liquidity"]}
            title="Add Liquidity To A Pool | GlobianceDEX"
            pathSlug="/add"
            description="The GlobianceDEX will allow you to add liquidity to a pool and the community can create new pools, add liquidity, and remove liquidity at any time."
          />
          <div className="header">
            <div className="text">
              <div className="text__primary">
                {this.renderLang("add_liquidity")}
              </div>
              <div className="text__secondary">
                {this.renderLang("add_liquidity_pool")}
              </div>
            </div>

            <div className="settings">
              <FontAwesomeIcon
                onClick={() => this.showSettings()}
                icon={faCog}
              />
            </div>
          </div>
          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.firstCurrency,
                "firstCurrency"
              )}
            </div>
            <div className="token-amount">
              <div className="amount">
                <input
                  className="form-control"
                  onChange={(e) => {
                    this.updateSwapAmount("firstCurrency", e.target.value);
                  }}
                  value={this.state.firstCurrencyAmount}
                />
              </div>
              <div className="sub-text">
                {this.renderAssetPrice("firstCurrency")}
              </div>
            </div>
          </div>
          <div className="toggle-container">
            <div className="toggle">
              <FontAwesomeIcon
                onClick={this.toggle}
                size="lg"
                icon={faRetweet}
              />
            </div>
          </div>
          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.secondCurrency,
                "secondCurrency"
              )}
            </div>
            <div className="token-amount">
              <div className="amount">
                <input
                  className="form-control"
                  onChange={(e) => {
                    this.updateSwapAmount("secondCurrency", e.target.value);
                  }}
                  disabled={true}
                  value={this.state.secondCurrencyAmount}
                />
              </div>
              <div className="sub-text">
                {this.renderAssetPrice("secondCurrency")}
              </div>
            </div>
          </div>
          <div className="inverse-rate">{this.renderInverseRate()}</div>
          <div className="submit">{this.state.button}</div>
          <Modal
            className="u-fit-modal custom-modal-1"
            centered={true}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Body>{this.state.modalContent}</Modal.Body>
          </Modal>
          <BlockNumber />
        </div>
        <div className='landing-main-2' id="swap-pinkgirl">
          <img src={PinkGirl1} alt="" />
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  theme,
  settings,
  assetPrice,
  addressPrice,
  tokenList,
}: {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  assetPrice: AssetPrice;
  addressPrice:AddressPrice
  tokenList: TokenListType;
}) {
  return { wallet, theme, settings, assetPrice, tokenList, addressPrice };
}

export default connect(mapStateToProps, actions)(AddLiquidity);
