import {
  faArrowDown,
  faRetweet,
  faCog,
  faCodeBranch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Utils from "xdc3-utils";
import _ from "lodash";
import axios from "axios";
import TokenList from "../TokenList";
import { Token } from "@globiance/default-token-list";

import {
  FromDecimals,
  RemoveExpo,
  ToDecimals,
  ROUND_DECIMALS_PAIR,
  ROUND_DECIMALS_AMOUNT,
  ToFixedFloor,
} from "../../helper/decimals";
import * as actions from "../../redux/actions/index";
import {
  Wallet,
  Theme,
  Settings,
  SettingsModal,
  TokenList as TokenListType,
  AssetPrice,
  AddressPrice,
} from "../../types";
import {
  GeneralContractTxPayable,
  BalanceOf,
  GetAmountsOut,
  GeneralContractTx,
  Approve,
  RectifyParams,
  GetPairReserves,
} from "../../helper/crypto";
import SettingsComp from "../common/settings";
import AcknowledgeFee from "../common/acknowledgeFee";

import { RouteComponentProps, Link } from "react-router-dom";
import { IsErc20Address, GetAmountsIn } from "../../helper/crypto";
import DefaultLogo from "../../assets/img/default.png";
import { DEX_API, RoundUptoSignificant } from "../../helper/constant";
import { TokenMap } from "../../types";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import BlockNumber from "../common/BlockNumber";
import { SEO } from "../common/SEO";
import { ForceShowModal } from "xdc-connect";
import PinkGirl1 from '../../assets/dex/iri/landing-main-2-swap.png';

interface PropsInterface extends actions.ActionsInterface {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  history: RouteComponentProps["history"];
  tokenList: TokenListType;
  assetPrice: AssetPrice;
  addressPrice: AddressPrice;
}

interface StateInterface {
  firstCurrency: Token | null;
  secondCurrency: Token | null;
  showModal: boolean;
  modalContent: ReactElement | null;
  firstCurrencyAmount: string;
  secondCurrencyAmount: string;
  balance: string | null;
  rateLoading: boolean;
  rate: string | null;
  allowance: string | null;
  valid: boolean;
  error_message: string;
  button: ReactElement;
  lastUpdated: CurrencyType;
  paths: Token[][] | [];
  selectedPath: number | null;
  routerModal: boolean;
  pairNotExist: boolean;
}

type CurrencyType = "firstCurrency" | "secondCurrency";
class Swap extends React.Component<PropsInterface, StateInterface> {
  i: number = 0;
  getRateDebounce: _.DebouncedFunc<any>;
  polling_interval: any;

  static contextType = LanguageContext;
  text: any;

  constructor(props: any) {
    super(props);

    this.state = {
      firstCurrency: null,
      secondCurrency: null,
      firstCurrencyAmount: "",
      secondCurrencyAmount: "",
      showModal: false,
      modalContent: null,
      balance: null,
      rate: null,
      rateLoading: false,
      allowance: null,
      valid: false,
      error_message: "CONNECT WALLET",
      button: <button onClick={() => ForceShowModal()}>CONNECT WALLET</button>,
      lastUpdated: "firstCurrency",
      paths: [],
      selectedPath: null,
      routerModal: false,
      pairNotExist: false,
    };

    this.toggle = this.toggle.bind(this);
    this.showTokenList = this.showTokenList.bind(this);
    this.showSettings = this.showSettings.bind(this);
    this.swap = this.swap.bind(this);
    this.approve = this.approve.bind(this);
    this.selectToken = this.selectToken.bind(this);
    this.loadToken = this.loadToken.bind(this);
    this.onClickAcknowledgeFee = this.onClickAcknowledgeFee.bind(this);

    this.getRateDebounce = _.debounce(this.getRate, 500);
  }

  componentDidMount() {
    this.checkValidity();
    this.loadToken();

    axios
      .post(`${DEX_API}/get-token-price`)
      .then((resp) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          this.props.FetchAssetPrice(data.data);
        }
      })
      .catch((e) => console.error(e));
  }

  loadToken() {
    const location = this.props.history.location;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const tokenLoadList = [];

    let firstSelected = false;

    if (params.inputCurrency) {
      let isDefault: boolean = false;
      for (const token of this.props.tokenList) {
        if (
          params.inputCurrency.toLowerCase() === token.symbol.toLowerCase() ||
          params.inputCurrency.toLowerCase() === token.address.toLowerCase()
        ) {
          this.selectToken("firstCurrency")(token);
          isDefault = true;
          firstSelected = true;
          break;
        }
      }
      if (isDefault === false && Utils.isAddress(params.inputCurrency)) {
        tokenLoadList.push(params.inputCurrency);
      }
    }
    if (params.outputCurrency) {
      let isDefault: boolean = false;
      for (const token of this.props.tokenList) {
        if (
          params.outputCurrency.toLowerCase() === token.symbol.toLowerCase() ||
          params.outputCurrency.toLowerCase() === token.address.toLowerCase()
        ) {
          this.selectToken("secondCurrency")(token);
          isDefault = true;
          break;
        }
      }
      if (isDefault === false && Utils.isAddress(params.outputCurrency)) {
        tokenLoadList.push(params.outputCurrency);
      }
    }

    // show confirmation modal for new tokens - tokenLoadList

    if (tokenLoadList.length > 0) {
      Promise.all(tokenLoadList.map((address) => IsErc20Address(address))).then(
        (resp) => {
          const relevantToken = resp.filter(({ isErc20 }) => isErc20);
          if (relevantToken.length > 0) {
            const modalContent = (
              <div className="token-load-confirm">
              <div className={`${this.props.theme}`}>
                {/* <div className="note">{this.renderLang("note")}</div> */}
                <div className="load-token">{this.renderLang("loadToken")}</div>
                <div className="list">
                  {relevantToken.map((x, i) => (
                    <li key={`token-list-confirm-${x.name}-${i}`}>
                      <img className="logo" src={DefaultLogo} alt={x.symbol} />
                      <div className="body">
                        <div className="symbol">{x.symbol}</div>
                        <div className="name">{x.name}</div>
                      </div>
                    </li>
                  ))}
                </div>
                <div className="footer">
                  <button
                    onClick={() => {
                      relevantToken.forEach((token) => {
                        if (
                          token.name &&
                          token.symbol &&
                          token.address &&
                          token.decimals
                        ) {
                          const _token = {
                            name: token.name,
                            symbol: token.symbol,
                            address: token.address,
                            decimals: parseInt(token.decimals),
                            logo: DefaultLogo,
                          };

                          if (
                            params.inputCurrency.toLowerCase() ===
                            token.address.toLowerCase()
                          ) {
                            this.selectToken("firstCurrency")(_token);
                            firstSelected = true;
                          } else if (
                            params.outputCurrency.toLowerCase() ===
                            token.address.toLowerCase()
                          ) {
                            this.selectToken("secondCurrency")(_token);
                          }
                          this.props.AddCustomToken(_token);
                        }
                      });
                      this.setState({ showModal: false });
                    }}
                  >
                    {this.renderLang("import")}
                  </button>
                </div>
                </div>
              </div>
            );

            this.setState({ modalContent, showModal: true });
          }
        }
      );
    }

    if (firstSelected === false) {
      this.selectToken("firstCurrency")(
        this.props.tokenList.filter((x) => x.symbol === "XDC")[0]
      );
    }
  }

  componentDidUpdate(prevProps: PropsInterface) {
    if (!_.isEqual(prevProps.wallet, this.props.wallet)) {
      if (this.state.firstCurrency) {
        this.getTokenBalance(this.state.firstCurrency);
      } else {
        this.checkValidity();
      }

      if (this.state.firstCurrency && this.state.secondCurrency) {
        this.calculateRate();
      }
    }
  }

  toggle() {
    const first = this.state.firstCurrency;
    const second = this.state.secondCurrency;
    const firstAmount = this.state.firstCurrencyAmount;
    const secondAmount = this.state.secondCurrencyAmount;

    if (this.state.lastUpdated === "secondCurrency") {
      this.setState(
        {
          firstCurrency: second,
          secondCurrency: first,
          selectedPath: null,
          firstCurrencyAmount: secondAmount,
          lastUpdated: "firstCurrency",
          // secondCurrencyAmount: firstAmount,
        },
        () => {
          this.getRateDebounce.cancel();
          this.calculateRate();
          if (second) this.getTokenBalance(second);
        }
      );
    } else {
      this.setState(
        {
          firstCurrency: second,
          secondCurrency: first,
          selectedPath: null,
          secondCurrencyAmount: firstAmount,
          lastUpdated: "secondCurrency",
          // secondCurrencyAmount: firstAmount,
        },
        () => {
          this.getRateDebounce.cancel();
          this.calculateRate();
          if (second) this.getTokenBalance(second);
        }
      );
    }
  }

  calculateRate() {
    const firstAmount = RemoveExpo(this.state.firstCurrencyAmount);
    const secondAmount = RemoveExpo(this.state.secondCurrencyAmount);

    if (this.state.firstCurrency == null || this.state.secondCurrency == null)
      return;

    const isConversion = this.isXdcConversion(
      this.state.firstCurrency,
      this.state.secondCurrency
    );

    if (isConversion.convert) {
      if (this.state.lastUpdated === "firstCurrency") {
        this.setState(
          {
            secondCurrencyAmount: firstAmount,
            rate: null,
          },
          this.checkValidity
        );
      } else {
        this.setState(
          {
            firstCurrencyAmount: secondAmount,
            rate: null,
          },
          this.checkValidity
        );
      }

      return;
    }

    this.getRateDebounce();
  }

  showTokenList(disabled: string[] = [], cb: (token: Token) => void) {
    const tokenList: ReactElement = (
      <TokenList disabled={disabled} onTokenSelect={cb} />
    );
    this.setState({ showModal: true, modalContent: tokenList });
  }

  updateSettings(settings: SettingsModal) {
    this.props.UpdateSettings(settings);
    this.setState({
      showModal: false,
    });
  }

  showSettings() {
    const settings: ReactElement = (
      <SettingsComp
        deadline={this.props.settings.deadline}
        slippage={this.props.settings.slippage}
        cb={(settings: SettingsModal) => this.updateSettings(settings)}
      />
    );
    this.setState({ showModal: true, modalContent: settings });
  }

  updateBalance(token: Token) {
    BalanceOf(token)
      .then((balances) => {
        if (balances)
          this.setState(
            { balance: balances[0], allowance: balances[1] },
            this.checkValidity
          );
      })
      .catch(console.error);
  }

  getTokenBalance(token: Token) {
    if (!this.props.wallet.connected) return;
    this.updateBalance(token);
  }

  getpairDecimals(): [number, number] {
    if (!this.state.firstCurrency || !this.state.secondCurrency) return [3, 3];

    return (
      ROUND_DECIMALS_PAIR[
        `${this.state.firstCurrency.symbol}-${this.state.secondCurrency.symbol}`
      ] || [3, 3]
    );
  }

  pathIncludesTaxToken(path: Token[]): boolean {
    return path.some(
      (x) =>
        this.props.tokenList.filter((token) => token.symbol === x.symbol)[0]
          ?.feeOnTransfer
    );
  }

  async getDerivedPath(
    tokenIn: Token,
    tokenOut: Token
  ): Promise<{ validPaths: string[][]; map: TokenMap }> {
    const resp: any = await axios.post(`${DEX_API}/get-derived-path-address`, {
      tokenIn: tokenIn.address,
      tokenOut: tokenOut.address,
    });
    return resp.data.data;
  }

  getBestRateIndex(paths: Token[][]): number {
    let min = Number.MAX_VALUE,
      index = 0;

    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];

      // direct path is the best path
      if (path.length === 2) return i;

      if (path.length < min) {
        min = path.length;
        index = i;
      }
    }

    return index;
  }

  getRate() {
    const firstAmount = RemoveExpo(this.state.firstCurrencyAmount);
    const secondAmount = RemoveExpo(this.state.secondCurrencyAmount);

    if (this.state.firstCurrency == null || this.state.secondCurrency == null)
      return;

    const lockedFirstAmount = this.state.firstCurrencyAmount;
    const lockedSecondAmount = this.state.secondCurrencyAmount;

    const lockedFirst = { ...this.state.firstCurrency };
    const lockedSecond = { ...this.state.secondCurrency };

    // const directPath = [this.state.firstCurrency, this.state.secondCurrency];

    // this.setState({ paths: [directPath], selectedPath: 0 }, () => {
    //   if (this.state.firstCurrency == null || this.state.secondCurrency == null)
    //     return;

    //   if (this.state.selectedPath === null || this.state.paths.length === 0) {
    //     return;
    //   }

    //   const addressPath = this.state.paths[this.state.selectedPath].map(
    //     (x) => x.address
    //   );

    //   if (this.state.lastUpdated === "firstCurrency") {
    //     if (!firstAmount || firstAmount === "0") {
    //       this.setState({ secondCurrencyAmount: "", rateLoading: false });
    //       this.checkValidity();
    //       return;
    //     }

    //     Promise.all<any>([
    //       this.getFinalAmountOut(
    //         ToDecimals(firstAmount, this.state.firstCurrency.decimals),
    //         addressPath
    //       ),
    //     ]).then(([rate]) => {
    //       if (rate == null) {
    //         this.setState({
    //           secondCurrencyAmount: "",
    //           button: <button className={"disabled"}>Invalid Pair</button>,
    //           rateLoading: false,
    //         });
    //         return;
    //       }

    //       if (!this.state.secondCurrency || !this.state.firstCurrency) {
    //         this.setState({
    //           secondCurrencyAmount: "",
    //           rateLoading: false,
    //         });
    //         return;
    //       }

    //       const nonClippedAmount = FromDecimals(
    //         rate,
    //         this.state.secondCurrency.decimals
    //       );
    //       let secondaryAmount = RemoveExpo(
    //         parseFloat(nonClippedAmount).toFixed(this.getpairDecimals()[1])
    //       );

    //       if (parseFloat(nonClippedAmount) === 0) {
    //         this.setState({
    //           secondCurrencyAmount: "",
    //           button: (
    //             <button className={"disabled"}>
    //               Insufficient Reserve Funds
    //             </button>
    //           ),
    //           rateLoading: false,
    //         });
    //         return;
    //       }

    //       if (parseFloat(secondaryAmount) === 0)
    //         secondaryAmount = RoundUptoSignificant(nonClippedAmount, 4);

    //       // if (parseFloat(secondaryAmount) > reserves[1]) {
    //       //   this.setState({
    //       //     valid: false,
    //       //     error_message: "insufficient pool reserve",
    //       //     button: (
    //       //       <button className={"disabled"}>
    //       //         insufficient pool reserve
    //       //       </button>
    //       //     ),
    //       //     rateLoading: false,
    //       //   });
    //       //   return;
    //       // }

    //       const _rate = RemoveExpo(
    //         (
    //           parseFloat(lockedFirstAmount + "") /
    //           parseFloat(secondaryAmount + "")
    //         ).toFixed(this.getpairDecimals()[0])
    //       );
    //       this.setState(
    //         {
    //           secondCurrencyAmount: secondaryAmount,
    //           rate: _rate,
    //           rateLoading: false,
    //         },
    //         () => {
    //           this.checkValidity();
    //           setTimeout(() => {
    //             this.calculateRate();
    //           }, 5000);
    //         }
    //       );
    //     });
    //   } else {
    //     if (!secondAmount || secondAmount === "0") {
    //       this.setState({ firstCurrencyAmount: "", rateLoading: false });
    //       this.checkValidity();
    //       return;
    //     }

    //     Promise.all([
    //       GetPairReserves([
    //         this.state.firstCurrency.address,
    //         this.state.secondCurrency.address,
    //       ]),
    //       this.getFinalAmountIn(
    //         ToDecimals(secondAmount, this.state.secondCurrency.decimals),
    //         addressPath
    //       ),
    //     ])
    //       .then(([reserves, rate]) => {
    //         if (!this.state.firstCurrency || !this.state.secondCurrency) {
    //           this.setState({
    //             firstCurrencyAmount: "",
    //             rateLoading: false,
    //           });
    //           return;
    //         }

    //         if (reserves == null || rate == null) {
    //           if (reserves != null) {
    //             reserves = [
    //               parseFloat(
    //                 FromDecimals(reserves[0], this.state.firstCurrency.decimals)
    //               ),
    //               parseFloat(
    //                 FromDecimals(
    //                   reserves[1],
    //                   this.state.secondCurrency.decimals
    //                 )
    //               ),
    //             ];

    //             if (
    //               parseFloat(this.state.secondCurrencyAmount) >= reserves[1]
    //             ) {
    //               this.setState({
    //                 firstCurrencyAmount: "",
    //                 rateLoading: false,
    //                 valid: false,
    //                 error_message: "insufficient pool reserve",
    //                 button: (
    //                   <button className={"disabled"}>
    //                     {this.renderLang("insufficient_pair_reserve")}
    //                   </button>
    //                 ),
    //               });
    //               return;
    //             }
    //           }

    //           this.setState({
    //             firstCurrencyAmount: "",
    //             rateLoading: false,
    //           });
    //           return;
    //         }

    //         const nonClippedAmount = FromDecimals(
    //           rate,
    //           this.state.firstCurrency.decimals
    //         );

    //         let primaryAmount = RemoveExpo(
    //           parseFloat(nonClippedAmount).toFixed(this.getpairDecimals()[0])
    //         );

    //         if (parseFloat(primaryAmount) === 0)
    //           primaryAmount = RoundUptoSignificant(nonClippedAmount, 4);

    //         const _rate = RemoveExpo(
    //           (
    //             parseFloat(primaryAmount + "") /
    //             parseFloat(lockedSecondAmount + "")
    //           ).toFixed(this.getpairDecimals()[1])
    //         );

    //         this.setState(
    //           {
    //             firstCurrencyAmount: primaryAmount,
    //             rate: _rate,
    //             rateLoading: false,
    //           },
    //           () => {
    //             this.checkValidity();
    //             setTimeout(() => {
    //               this.calculateRate();
    //             }, 5000);
    //           }
    //         );
    //       })
    //       .catch(console.error);
    //   }
    // });

    this.getDerivedPath(this.state.firstCurrency, this.state.secondCurrency)
      .then(({ validPaths, map }) => {
        if (validPaths.length === 0) {
          this.setState({
            secondCurrencyAmount: "",
            button: (
              <>
                <button className={"disabled"}>
                  {this.renderLang("pair_not_exists")}
                </button>
                <div className="create-pair-link">
                  <Link
                    to={`/create-pair?outputCurrency=${this.state.secondCurrency?.symbol}&inputCurrency=${this.state.firstCurrency?.symbol}`}
                  >
                    Create this Pair{" "}
                  </Link>
                </div>
              </>
            ),
            pairNotExist: true,
          });
          return;
        }

        if (!this.state.secondCurrency || !this.state.firstCurrency) return;
        if (this.state.secondCurrency.symbol !== lockedSecond.symbol) return;
        if (this.state.firstCurrency.symbol !== lockedFirst.symbol) return;

        const pathAddresses = validPaths.map((path) =>
          path.map((x) => map[x].token)
        );

        if (
          this.state.firstCurrency == null ||
          this.state.secondCurrency == null
        )
          return;

        const bestRateIndex = this.getBestRateIndex(pathAddresses);

        const q = {
          paths: pathAddresses,
          selectedPath: this.state.selectedPath,
          rateLoading: true,
        };

        if (q.selectedPath === null) {
          q.selectedPath = bestRateIndex;
        }

        this.setState({ ...q }, () => {
          if (
            this.state.firstCurrency == null ||
            this.state.secondCurrency == null
          )
            return;

          if (
            this.state.selectedPath === null ||
            this.state.paths.length === 0
          ) {
            return;
          }

          const addressPath = this.state.paths[this.state.selectedPath].map(
            (x) => x.address
          );

          if (this.state.lastUpdated === "firstCurrency") {
            if (!firstAmount || firstAmount === "0") {
              this.setState({ secondCurrencyAmount: "", rateLoading: false });
              this.checkValidity();
              return;
            }

            Promise.all<any>([
              this.getFinalAmountOut(
                ToDecimals(firstAmount, this.state.firstCurrency.decimals),
                addressPath
              ),
            ]).then(([rate]) => {
              if (rate == null) {
                this.setState({
                  secondCurrencyAmount: "",
                  button: <button className={"disabled"}>Invalid Pair</button>,
                  rateLoading: false,
                });
                return;
              }

              if (!this.state.secondCurrency || !this.state.firstCurrency) {
                this.setState({
                  secondCurrencyAmount: "",
                  rateLoading: false,
                });
                return;
              }

              const nonClippedAmount = FromDecimals(
                rate,
                this.state.secondCurrency.decimals
              );
              let secondaryAmount = RemoveExpo(
                parseFloat(nonClippedAmount).toFixed(this.getpairDecimals()[1])
              );

              if (parseFloat(nonClippedAmount) === 0) {
                this.setState({
                  secondCurrencyAmount: "",
                  button: (
                    <button className={"disabled"}>
                      Insufficient Reserve Funds
                    </button>
                  ),
                  rateLoading: false,
                });
                return;
              }

              if (parseFloat(secondaryAmount) === 0)
                secondaryAmount = RoundUptoSignificant(nonClippedAmount, 4);

              // if (parseFloat(secondaryAmount) > reserves[1]) {
              //   this.setState({
              //     valid: false,
              //     error_message: "insufficient pool reserve",
              //     button: (
              //       <button className={"disabled"}>
              //         insufficient pool reserve
              //       </button>
              //     ),
              //     rateLoading: false,
              //   });
              //   return;
              // }

              const _rate = RemoveExpo(
                (
                  parseFloat(lockedFirstAmount + "") /
                  parseFloat(secondaryAmount + "")
                ).toFixed(this.getpairDecimals()[0])
              );
              this.setState(
                {
                  secondCurrencyAmount: secondaryAmount,
                  rate: _rate,
                  rateLoading: false,
                },
                () => {
                  this.checkValidity();
                  setTimeout(() => {
                    this.calculateRate();
                  }, 5000);
                }
              );
            });
          } else {
            if (!secondAmount || secondAmount === "0") {
              this.setState({ firstCurrencyAmount: "", rateLoading: false });
              this.checkValidity();
              return;
            }

            Promise.all([
              GetPairReserves([
                this.state.firstCurrency.address,
                this.state.secondCurrency.address,
              ]),
              this.getFinalAmountIn(
                ToDecimals(secondAmount, this.state.secondCurrency.decimals),
                addressPath
              ),
            ])
              .then(([reserves, rate]) => {
                if (!this.state.firstCurrency || !this.state.secondCurrency) {
                  this.setState({
                    firstCurrencyAmount: "",
                    rateLoading: false,
                  });
                  return;
                }

                if (reserves == null || rate == null) {
                  if (reserves != null) {
                    reserves = [
                      parseFloat(
                        FromDecimals(
                          reserves[0],
                          this.state.firstCurrency.decimals
                        )
                      ),
                      parseFloat(
                        FromDecimals(
                          reserves[1],
                          this.state.secondCurrency.decimals
                        )
                      ),
                    ];

                    if (
                      parseFloat(this.state.secondCurrencyAmount) >= reserves[1]
                    ) {
                      this.setState({
                        firstCurrencyAmount: "",
                        rateLoading: false,
                        valid: false,
                        error_message: "insufficient pool reserve",
                        button: (
                          <button className={"disabled"}>
                            {this.renderLang("insufficient_pair_reserve")}
                          </button>
                        ),
                      });
                      return;
                    }
                  }

                  this.setState({
                    firstCurrencyAmount: "",
                    rateLoading: false,
                  });
                  return;
                }

                const nonClippedAmount = FromDecimals(
                  rate,
                  this.state.firstCurrency.decimals
                );

                let primaryAmount = RemoveExpo(
                  parseFloat(nonClippedAmount).toFixed(
                    this.getpairDecimals()[0]
                  )
                );

                if (parseFloat(primaryAmount) === 0)
                  primaryAmount = RoundUptoSignificant(nonClippedAmount, 4);

                const _rate = RemoveExpo(
                  (
                    parseFloat(primaryAmount + "") /
                    parseFloat(lockedSecondAmount + "")
                  ).toFixed(this.getpairDecimals()[1])
                );

                this.setState(
                  {
                    firstCurrencyAmount: primaryAmount,
                    rate: _rate,
                    rateLoading: false,
                  },
                  () => {
                    this.checkValidity();
                    setTimeout(() => {
                      this.calculateRate();
                    }, 5000);
                  }
                );
              })
              .catch(console.error);
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  selectToken = (type: CurrencyType) => (token: Token, cb?: any) => {
    const q: any = {
      showModal: false,
      selectedPath: null,
      paths: [],
    };

    if (type === "firstCurrency") {
      if (this.state.secondCurrency?.symbol === token.symbol) {
        this.setState({ ...q }, () => {
          this.toggle();
          if (cb) cb();
        });
        return;
      }
    } else {
      if (this.state.firstCurrency?.symbol === token.symbol) {
        this.setState({ ...q }, () => {
          this.toggle();
          if (cb) cb();
        });
        return;
      }
    }

    q[type] = token;
    this.setState({ ...q }, () => {
      this.getRateDebounce.cancel();
      this.calculateRate();
      if (this.state.firstCurrency) {
        this.getTokenBalance(this.state.firstCurrency);
      }
      this.checkValidity();
      if (cb) cb();
      if (
        token.feeOnTransfer &&
        !this.props.settings.acknowledgedFee.includes(token.symbol)
      ) {
        this.renderAcknowledgeFee(token);
      }
    });
  };

  onClickAcknowledgeFee(token: Token, dontShowAgain: boolean) {
    if (dontShowAgain) {
      this.props.AcknowledgeFee(token.symbol);
    }
    this.showSettings();
  }

  renderAcknowledgeFee(token: Token) {
    this.setState({
      showModal: true,
      modalContent: (
        <AcknowledgeFee
          theme={this.props.theme}
          token={token}
          cb={this.onClickAcknowledgeFee}
        />
      ),
    });
  }

  renderTokenSelect(selected: Token | null, type: CurrencyType): ReactElement {
    if (!selected)
      return (
        <div
          className="select-token"
          onClick={() => this.showTokenList(undefined, this.selectToken(type))}
        >
          <div className="token-name">
            Select Token <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      );

    return (
      <div
        className="select-token"
        onClick={() =>
          this.showTokenList([selected.symbol], this.selectToken(type))
        }
      >
        <div className="token-badge">
          <div className="token-name">
            <img src={selected.logo} alt={selected.symbol} />
            {selected.symbol}
            <FontAwesomeIcon icon={faArrowDown} />
          </div>
        </div>
      </div>
    );
  }

  async getFinalAmountOut(
    amount: string,
    path: string[]
  ): Promise<number | null> {
    try {
      const amounts = await GetAmountsOut(amount, path);
      if (!amounts) {
        return null;
      }
      const outPrice = amounts[amounts.length - 1];
      return outPrice;
    } catch (e) {
      return null;
    }
  }

  async getFinalAmountIn(
    amount: string,
    path: string[]
  ): Promise<number | null> {
    try {
      const amounts = await GetAmountsIn(amount, path);
      if (!amounts) {
        return null;
      }
      const outPrice = amounts[0];
      return outPrice;
    } catch (e) {
      return null;
    }
  }

  updateSwapAmount(type: CurrencyType, amount: string) {
    amount = amount.replace(/^0+/, "");
    amount = amount.replace(/^\./, "0.");

    if (_.isEmpty(amount)) amount = "0";

    if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]*)$/g.test(amount) === false) {
      return;
    }
    const q: any = {};
    q[`${type}Amount`] = amount;
    this.setState({ ...q, lastUpdated: type }, () => {
      this.calculateRate();
      this.checkValidity();
    });
  }

  checkValidity() {
    if (!this.state.firstCurrency || !this.state.secondCurrency)
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("select_pair")}
          </button>
        ),
      });

    const isConvert = this.isXdcConversion(
      this.state.firstCurrency,
      this.state.secondCurrency
    );
    if (isConvert.convert === false)
      if (this.state.selectedPath === null || this.state.paths.length === 0) {
        this.setState({
          secondCurrencyAmount: "",
          button: (
            <button className={"disabled"}>
              {this.renderLang("pair_not_exists")}
            </button>
          ),
        });
        return;
      }

    if (this.props.wallet.connected === false)
      return this.setState({
        button: (
          <button onClick={() => ForceShowModal()}>
            {this.renderLang("landing_header_connect_button")}
          </button>
        ),
      });

    if (
      !this.state.firstCurrencyAmount ||
      parseFloat(this.state.firstCurrencyAmount) === 0 ||
      !this.state.secondCurrencyAmount ||
      parseFloat(this.state.secondCurrencyAmount) === 0
    ) {
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("enter_valid_amt")}
          </button>
        ),
      });
    }

    if (
      this.state.balance &&
      parseFloat(this.state.balance) <
        parseFloat(
          ToDecimals(
            this.state.firstCurrencyAmount as string,
            this.state.firstCurrency.decimals
          )
        )
    )
      return this.setState({
        button: (
          <button className={"disabled"}>
            {this.renderLang("insufficient_bal")}
          </button>
        ),
      });

    if (
      this.state.firstCurrency.symbol !== "XDC" &&
      parseFloat(this.state.firstCurrencyAmount + "") >
        parseFloat(this.state.allowance + "")
    ) {
      return this.setState({
        button: (
          <button onClick={this.approve} className={""}>
            {this.renderLang("approve")}
          </button>
        ),
      });
    }

    if (this.state.firstCurrencyAmount === "0") {
      return this.setState({
        button: (
          <button className={"disabled"}> {this.renderLang("enterAmt")}</button>
        ),
      });
    }

    this.setState({
      button: (
        <button onClick={this.swap} className={""}>
          {this.renderLang("swap")}
        </button>
      ),
    });
  }

  isXdcConversion(
    first: Token,
    second: Token
  ): { convert: boolean; method: string } {
    if (first.symbol === "XDC" && second.symbol === "WXDC")
      return { convert: true, method: "deposit" };

    if (first.symbol === "WXDC" && second.symbol === "XDC")
      return { convert: true, method: "withdraw" };

    return { convert: false, method: "" };
  }

  approve() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.props.wallet.address
    )
      return;

    Approve(this.state.firstCurrency.address)
      .then(() => this.updateBalance(this.state.firstCurrency as Token))
      .catch(console.error);
  }

  swap() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.props.wallet.address
    )
      return;

    const isConvert = this.isXdcConversion(
      this.state.firstCurrency,
      this.state.secondCurrency
    );

    if (isConvert.convert) {
      if (isConvert.method === "deposit") {
        GeneralContractTxPayable(
          "wxdc",
          "deposit",
          ToDecimals(
            this.state.firstCurrencyAmount,
            this.state.firstCurrency.decimals
          )
        )
          .then(
            () =>
              this.state.firstCurrency &&
              this.getTokenBalance(this.state.firstCurrency)
          )
          .catch(console.log);
      } else {
        GeneralContractTx("wxdc", "withdraw", [
          ToDecimals(
            this.state.firstCurrencyAmount,
            this.state.firstCurrency.decimals
          ),
        ])
          .then(
            () =>
              this.state.firstCurrency &&
              this.getTokenBalance(this.state.firstCurrency)
          )
          .catch(console.log);
      }
      return;
    }

    if (this.state.selectedPath === null || this.state.paths.length === 0)
      return;

    const pathToken = this.state.paths[this.state.selectedPath];
    const path = pathToken.map((x) => x.address);

    let method = "swapExactTokensForTokens";
    const deadline =
      parseFloat((Date.now() / 1000).toFixed(0)) + this.props.settings.deadline;

    const minOut = ToDecimals(
      (parseFloat(this.state.secondCurrencyAmount as string) *
        (100 - this.props.settings.slippage)) /
        100,
      this.state.secondCurrency.decimals
    );

    if (this.state.firstCurrency.symbol === "XDC") {
      method = "swapExactETHForTokens";

      if (this.pathIncludesTaxToken(pathToken))
        method = "swapExactETHForTokensSupportingFeeOnTransferTokens";

      GeneralContractTxPayable(
        "router",
        method,
        ToDecimals(
          this.state.firstCurrencyAmount,
          this.state.firstCurrency.decimals
        ),
        [minOut, RectifyParams([...path]), this.props.wallet.address, deadline]
      )
        .then(
          () =>
            this.state.firstCurrency &&
            this.getTokenBalance(this.state.firstCurrency)
        )
        .catch(console.log);
      return;
    }

    if (this.state.secondCurrency.symbol === "XDC") {
      method = "swapExactTokensForETH";
    }

    if (this.pathIncludesTaxToken(pathToken)) {
      if (this.state.secondCurrency.symbol === "XDC") {
        method = "swapExactTokensForETHSupportingFeeOnTransferTokens";
      } else {
        method = "swapExactTokensForTokensSupportingFeeOnTransferTokens";
      }
    }

    GeneralContractTx("router", method, [
      ToDecimals(
        this.state.firstCurrencyAmount,
        this.state.firstCurrency.decimals
      ),
      minOut,
      RectifyParams([...path]),
      this.props.wallet.address,
      deadline,
    ])
      .then(
        () =>
          this.state.firstCurrency &&
          this.getTokenBalance(this.state.firstCurrency)
      )
      .catch(console.log);
  }
  renderInverseRate() {
    if (
      !this.state.firstCurrency ||
      !this.state.secondCurrency ||
      !this.state.firstCurrencyAmount ||
      !this.state.secondCurrencyAmount ||
      !this.state.rate
    )
      return "";

    return (
      <div className="rate">
        1 {this.state.secondCurrency.symbol}&nbsp;&#8776;&nbsp;
        {this.state.rate} {this.state.firstCurrency.symbol}
      </div>
    );
  }

  renderAssetPrice(type: CurrencyType) {
    const currency = this.state[type];
    const amount = this.state[`${type}Amount`];
    if (!currency || !amount) return;

    const frmtAddr = (
      Utils.fromXdcAddress(currency.address) as string
    ).toLowerCase();

    if (!this.props.addressPrice || !this.props.addressPrice[frmtAddr]) return;

    const usdTotal = (
      parseFloat(amount) * this.props.addressPrice[frmtAddr]
    ).toFixed(2);
    return (
      <>
        &nbsp;&#8776;&nbsp;{usdTotal}&nbsp;{this.renderLang("usd")}
      </>
    );
  }

  renderMaxButton() {
    if (!this.state.firstCurrency || !this.state.balance)
      return <div className="max"></div>;

    const roundOff =
      ROUND_DECIMALS_AMOUNT[this.state.firstCurrency.symbol] || 3;
    const balance = ToFixedFloor(
      FromDecimals(this.state.balance, this.state.firstCurrency.decimals),
      roundOff
    );
    return (
      <div className="max">
        <span onClick={() => this.updateSwapAmount("firstCurrency", balance)}>
          {this.renderLang("max")}
        </span>
      </div>
    );
  }

  renderImg = () => {
    const allPaths = this.state.paths;
    const paths: any = [];

    for (const curPath of allPaths) {
      const path = curPath.map((token) => {
        const logo =
          this.props.tokenList.filter(
            (x) =>
              Utils.fromXdcAddress(x.address).toLowerCase() ===
              Utils.fromXdcAddress(token.address).toLowerCase()
          )[0]?.logo || DefaultLogo;
        return logo;
      });
      paths.push(path);
    }
    return paths;
  };

  renderMinimumOut() {
    if (!this.state.firstCurrency || !this.state.secondCurrency) return;

    if (!this.state.secondCurrencyAmount) return;

    const isConvert = this.isXdcConversion(
      this.state.firstCurrency,
      this.state.secondCurrency
    );

    if (isConvert.convert) return;

    const minOutAmount = (
      parseFloat(this.state.secondCurrencyAmount) *
      (1 - this.props.settings.slippage / 100)
    ).toFixed(this.getpairDecimals()[1]);

    return (
      <div className="minimum-token-out">
        Min. {this.state.secondCurrency.symbol} Received:{" "}
        {parseFloat(minOutAmount).toLocaleString()}
      </div>
    );
  }

  renderTaxNote() {
    if (!this.state.firstCurrency || !this.state.secondCurrency) return;

    if (this.state.selectedPath === null || this.state.paths.length === 0)
      return;

    if (this.pathIncludesTaxToken(this.state.paths[this.state.selectedPath])) {
      return (
        <div className="fott">
          Path includes a{" "}
          <Link target="_blank" to="/fott">
            FOTT
          </Link>
        </div>
      );
    }

    return;
  }

  renderRouter = () => {
    const allPaths = this.state.paths;
    if (allPaths.length === 0) {
      return <div className="auto-router"></div>;
    } else {
      // const paths: any = [];

      let classNameRouteB = "route-b";

      if (this.state.routerModal) {
        classNameRouteB += " active";
      } else {
        classNameRouteB = "route-b";
      }

      return (
        <div className="auto-router">
          <div className="path-box">
            <span
              className={classNameRouteB}
              onClick={() =>
                this.setState({
                  routerModal: !this.state.routerModal,
                })
              }
            >
              {" "}
              <FontAwesomeIcon icon={faCodeBranch} />
              {this.state.routerModal ? (
                <div className="path above">
                  <div className="c-header">
                    <div className="icon-route">
                      <FontAwesomeIcon icon={faCodeBranch} />
                      <span> {this.renderLang("select_path")}</span>
                    </div>
                    <span className="vtext">V1</span>
                  </div>
                  <div className="c-body">
                    {this.renderImg().map((data: any, index: number) => {
                      return (
                        <div
                          className={`icon-set ${
                            this.state.selectedPath === index ? "active" : ""
                          }`}
                          onClick={() =>
                            this.setState(
                              {
                                selectedPath: index,
                                routerModal: !this.state.routerModal,
                              },
                              () => {
                                this.getRateDebounce.cancel();
                                this.calculateRate();
                              }
                            )
                          }
                        >
                          {data.map((imgData: any) => {
                            return (
                              <>
                                <img src={imgData} alt="" />
                                <div className="dot-line"></div>
                              </>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </span>
          </div>
        </div>
      );
    }
  };

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };

  render() {
    this.text = GetLanguage(this.context.language);

    return (
      <div className="dashboard">
        <div className="swap">
          <SEO
            keywords={["globiance", "globiancedex", "dex", "pool", "swap"]}
            title="Swap | Trade Any Token | GlobianceDEX"
            pathSlug="/swap"
            description="GlobianceDEX can help traders to trade instantly against liquidity pools instead of order books and can also customize settings like Slippage, Deadline, Swap Path to their requirements."
          />
          <div className="header">
            <div className="text">
              <div className="text__primary">
                {this.renderLang("trade_token")}
              </div>
              <div className="text__secondary">
                {this.renderLang("trade_desc")}
              </div>
            </div>

            <div className="settings">
              <FontAwesomeIcon
                onClick={() => this.showSettings()}
                icon={faCog}
              />
            </div>
          </div>

          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.firstCurrency,
                "firstCurrency"
              )}
            </div>
            <div className="token-amount">
              <div className="amount">
                <input
                  className="form-control"
                  onChange={(e) => {
                    this.updateSwapAmount("firstCurrency", e.target.value);
                  }}
                  placeholder="Enter Amount"
                  value={this.state.firstCurrencyAmount}
                />
              </div>
              <div className="sub-text">
                {this.renderAssetPrice("firstCurrency")}
              </div>
              {this.renderMaxButton()}
            </div>
          </div>
          <div className="toggle-container">
            <div className="toggle">
              <FontAwesomeIcon
                onClick={this.toggle}
                size="lg"
                icon={faRetweet}
              />
            </div>
          </div>
          <div className="token-input">
            <div className="token-select">
              {this.renderTokenSelect(
                this.state.secondCurrency,
                "secondCurrency"
              )}
            </div>
            <div className="token-amount">
              <div className="amount">
                <input
                  className="form-control"
                  placeholder="Enter Amount"
                  onChange={(e) => {
                    this.updateSwapAmount("secondCurrency", e.target.value);
                  }}
                  value={this.state.secondCurrencyAmount}
                />
              </div>
              <div className="sub-text">
                {this.renderAssetPrice("secondCurrency")}
              </div>
            </div>
          </div>
          <div className="inverse-rate">
            <div className="router">
              {this.renderRouter()}
              {this.renderInverseRate()}
            </div>
            {this.renderMinimumOut()}
            {this.renderTaxNote()}
          </div>
          <div className="submit">{this.state.button}</div>
          <Modal
            className="u-fit-modal custom-modal-1"
            centered={true}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          >
            <Modal.Body>{this.state.modalContent}</Modal.Body>
          </Modal>
          <BlockNumber />
        </div>
        <div className='landing-main-2' id="swap-pinkgirl">
          <img src={PinkGirl1} alt="" />
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  theme,
  settings,
  tokenList,
  assetPrice,
  addressPrice,
}: {
  wallet: Wallet;
  theme: Theme;
  settings: Settings;
  tokenList: TokenListType;
  assetPrice: AssetPrice;
  addressPrice: AddressPrice;
}) {
  return { wallet, theme, settings, tokenList, assetPrice, addressPrice };
}

export default connect(mapStateToProps, actions)(Swap);
