import * as types from "../actions/types";

interface AddressPrice {
  [address: string]: number;
}

const initialPrice: AddressPrice = {};

interface Payload {
  payload: any;
  type: string;
}

const PriceReducer = (state = initialPrice, payload: Payload): AddressPrice => {
  switch (payload.type) {
    case types.FETCHED_LATEST_PRICE: {
      return payload.payload.addressPrice;
    }
    default:
      return state;
  }
};

export default PriceReducer;
