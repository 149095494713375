import Xdc3 from "xdc3"
import { TransactionReceipt } from "xdc3-core"
import { ContractMetadata } from "./addresses"
import store from "../redux/store"
import { DEFAULT_CHAIN_ID, MAX_UINT256, ZERO_ADDRESS } from './constant';
import PROVIDERS from "./providers"
import { Pool, VALID_CONTRACT } from '../types';
import { Token } from '@globiance/default-token-list';
import ERC20_ABI from "../ABI/erc20.json"
import PAIR_ABI from "../ABI/pair.json"
import FACTORY_ABI from "../ABI/factory.json"


import { AbiItem, isAddress } from 'xdc3-utils';
import { SendTransaction } from "xdc-connect";
import Utils from 'xdc3-utils';
import { RemoveDecimal } from "./decimals";

export const GeneralContractView = async (type: VALID_CONTRACT, method: string, params: any[] = [], address?: string): Promise<string | null> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }
    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = address ? address : ContractMetadata[chainId]?.[type]?.address
    const xdc3 = new Xdc3(provider);
    const contract = new xdc3.eth.Contract(ContractMetadata[chainId]?.[type]?.abi, to);
    params = RectifyParams(params)
    const data = contract.methods[method](...params).encodeABI();
    const tx = {
      to,
      data,
    }
    const resp = await xdc3.eth.call(tx)
    return resp
  }
  catch (e) {
    // console.log(e);
    return null
  }
}

export const GeneralContractTx = async (type: VALID_CONTRACT, method: string, params: any[] = [], address?: string): Promise<TransactionReceipt | null> => {
  try {
    const state = store.getState()
    if (!state.wallet.connected) return null

    const chainId = state.wallet.chain_id as string
    const from = state.wallet.address
    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = address ? address : ContractMetadata[chainId]?.[type]?.address
    const xdc3 = new Xdc3(provider);
    const contract = new xdc3.eth.Contract(ContractMetadata[chainId]?.[type]?.abi, to);
    params = RectifyParams(params)
    const data = contract.methods[method](...params).encodeABI();
    const tx = {
      from,
      to,
      data,
    }
    const resp = await SendTransaction(tx)
    return resp
  }
  catch (e) {
    // console.log(e);
    return null
  }
}

export const GeneralContractTxPayable = async (type: VALID_CONTRACT, method: string, value: string, params: any[] = []): Promise<TransactionReceipt | null> => {
  try {
    const state = store.getState()
    if (!state.wallet.connected) return null

    value = RemoveDecimal(value)

    const chainId = state.wallet.chain_id as string
    const from = state.wallet.address
    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = ContractMetadata[chainId]?.[type]?.address
    const xdc3 = new Xdc3(provider);
    const contract = new xdc3.eth.Contract(ContractMetadata[chainId]?.[type]?.abi, to);
    params = RectifyParams(params)

    const data = contract.methods[method](...params).encodeABI();
    const tx = {
      from,
      to,
      data,
      value
    }
    const resp = await SendTransaction(tx)
    return resp
  }
  catch (e) {
    console.log(e);
    return null
  }
}

export const BalanceOf = async (token: Token, _from?: string): Promise<string[] | null> => {
  try {
    const state = store.getState()
    if (!state.wallet.connected) return null
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }


    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = token.address
    const from = _from || state.wallet.address
    const xdc3 = new Xdc3(provider);
    if (token.symbol === "XDC") return [await xdc3.eth.getBalance(from), "0"]

    const contract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], to);
    const resp = await Promise.all([contract.methods.balanceOf(Utils.fromXdcAddress(from)).call(), contract.methods.allowance(Utils.fromXdcAddress(from), Utils.fromXdcAddress(ContractMetadata[chainId].router.address)).call()]);


    return resp
  }
  catch (e) {
    console.log(e);
    return null
  }
}

export const BalanceOfGeneral = async (token: string, address: string): Promise<string | null> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }


    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = token
    const from = address
    const xdc3 = new Xdc3(provider);

    const contract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], to);
    const resp = await contract.methods.balanceOf(Utils.fromXdcAddress(from)).call();

    return resp
  }
  catch (e) {
    console.log(e);
    return null
  }
}


export const Approve = async (token: string, amount = MAX_UINT256): Promise<TransactionReceipt | null> => {
  try {
    const state = store.getState()
    if (!state.wallet.connected) return null
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = token
    const from = state.wallet.address
    const xdc3 = new Xdc3(provider);
    const contract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], to);
    const data = contract.methods.approve(ContractMetadata[chainId].router.address, amount).encodeABI();

    const tx = {
      from,
      to,
      data,
    }

    const resp = await SendTransaction(tx)
    return resp
  }
  catch (e) {
    // console.log(e);
    return null
  }
}

export const GetAmountsOut = async (amountIn: string, path: string[]): Promise<any> => {
  path = RectifyParams(path);
  return (new Xdc3()).eth.abi.decodeParameter("uint256[]", (await GeneralContractView("router", "getAmountsOut", [amountIn, path])) as string)
}

export const GetAmountsIn = async (amountOut: string, path: string[]): Promise<any> => {
  path = RectifyParams(path);
  return (new Xdc3()).eth.abi.decodeParameter("uint256[]", (await GeneralContractView("router", "getAmountsIn", [amountOut, path])) as string)
}

export const IsErc20Address = async (address: string): Promise<{ isErc20: boolean, name?: string, symbol?: string, decimals?: string, address?: string }> => {
  if (!isAddress(address)) return { isErc20: false }
  const state = store.getState()
  let chainId = DEFAULT_CHAIN_ID
  if (state.wallet.connected) {
    chainId = state.wallet.chain_id as string
  }

  const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
  const xdc3 = new Xdc3(provider);

  const code = await xdc3.eth.getCode(address)
  if (code === '0x') return { isErc20: false }

  const contract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], address);



  try {
    const [name, symbol, decimals, totalSupply] = await Promise.all([(contract.methods.name().call()),
    (contract.methods.symbol().call()),
    (contract.methods.decimals().call()),
    (contract.methods.totalSupply().call()),])
    return { isErc20: typeof name === "string" && typeof symbol === "string" && typeof decimals === "string" && typeof totalSupply === "string", name, symbol, decimals, address }
  }
  catch (e) {
    // console.log(e);
    return { isErc20: false }
  }
}

export const PoolBalance = async (pool: Pool): Promise<string | null> => {
  try {
    const state = store.getState()
    if (!state.wallet.connected) return null
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }


    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const to = pool.address
    const from = state.wallet.address
    const xdc3 = new Xdc3(provider);
    const contract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], to);
    const resp = await contract.methods.balanceOf(from).call();

    return resp
  }
  catch (e) {
    console.error(e);
    return null;
  }
}

export const IsPoolPair = async (address: string): Promise<{ isPool: boolean, name?: string, symbol?: string, decimals?: string }> => {
  try {
    if (!isAddress(address)) return { isPool: false }
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    [address] = RectifyParams([address])

    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    const code = await xdc3.eth.getCode(address)
    if (code === '0x') return { isPool: false }

    const contract = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], address);

    const [name, symbol, decimals, totalSupply] = await Promise.all([(contract.methods.name().call()),
    (contract.methods.symbol().call()),
    (contract.methods.decimals().call()),
    (contract.methods.totalSupply().call()),])
    return { isPool: typeof name === "string" && typeof symbol === "string" && typeof decimals === "string" && typeof totalSupply === "string", name, symbol, decimals }

  }
  catch (e) {
    // console.log(e);
    return { isPool: false }
  }
}

export const GetPairReserves = async ([tokenA, tokenB]: string[]): Promise<[number, number] | null> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])


    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);


    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()

    if (pair_address === ZERO_ADDRESS) return null

    const pair = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], pair_address);

    const reserves = await pair.methods.getReserves().call()
    const token0 = await pair.methods.token0().call()

    if (token0.toLowerCase() === tokenA.toLowerCase()) {
      return [parseFloat(reserves._reserve0), parseFloat(reserves._reserve1)]

    } else {
      return [parseFloat(reserves._reserve1), parseFloat(reserves._reserve0)]
    }


  }
  catch (e) {
    return null
  }
}

export const GetQuote = async (amount: string, tokenA: string, tokenB: string): Promise<[boolean, number | null, number | null, number | null, boolean]> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])


    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);


    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()

    if (pair_address === ZERO_ADDRESS) return [false, null, null, null, false]


    const pair = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], pair_address);

    const reserves = await pair.methods.getReserves().call()
    const token0 = await pair.methods.token0().call()
    const totalSupply = await pair.methods.totalSupply().call()

    if (totalSupply === 0 || totalSupply === "0") {
      return [false, null, null, null, false];
    }

    if (token0.toLowerCase() === tokenA.toLowerCase()) {
      // token0 = amount0
      const quote = parseFloat(amount) * parseFloat(reserves._reserve1) / parseFloat(reserves._reserve0)
      const approxMint = Math.min(parseFloat(amount) * parseFloat(totalSupply) / parseFloat(reserves._reserve0), quote * parseFloat(totalSupply) / parseFloat(reserves._reserve1))
      return [true, quote, approxMint, totalSupply, false]
    } else {
      // token1 = amount0
      const quote = parseFloat(amount) * parseFloat(reserves._reserve0) / parseFloat(reserves._reserve1)
      const approxMint = Math.min(quote * parseFloat(totalSupply) / parseFloat(reserves._reserve0), parseFloat(amount) * parseFloat(totalSupply) / parseFloat(reserves._reserve1))

      return [true, quote, approxMint, totalSupply, true]
    }

  }
  catch (e) {
    // console.log(e);
    return [false, null, null, null, false];
  }
}

// function _calculateApproxMint(amount0: number, amount1: number, reserve0: number, reserve1: number, totalSupply: number): number {

// }

export const GetPoolPair = async (tokenA: string, tokenB: string): Promise<string> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])


    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);
    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()
    return pair_address
  }
  catch (e) {
    console.error(e);
    return ZERO_ADDRESS
  }
}

export const GetLPFromTokens = async (tokenA: string, tokenB: string): Promise<[number | null, number | null]> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);
    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()
    const pair = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], pair_address);
    const [balance, approved] = await Promise.all([pair.methods.balanceOf(state.wallet.address).call(), pair.methods.allowance(state.wallet.address, ContractMetadata[chainId].router.address).call()])

    return [balance, approved]
  }
  catch (e) {
    console.log(e);
    return [null, null]
  }
}

export const GetTknFromLiquidity = async (amount: string, tokenA: string, tokenB: string): Promise<[boolean, number | null, number | null]> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);
    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()

    if (pair_address === ZERO_ADDRESS) return [false, null, null]

    const pair = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], pair_address);

    const tokenAContract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], tokenA);
    const tokenBContract = new xdc3.eth.Contract(ERC20_ABI as AbiItem[], tokenB);

    const [balance0, balance1] = await Promise.all([tokenAContract.methods.balanceOf(pair_address).call(), tokenBContract.methods.balanceOf(pair_address).call()])

    const totalSupply = await pair.methods.totalSupply().call();
    const tokenAmountA = parseFloat((parseFloat(amount) * balance0 / totalSupply).toFixed(0))
    const tokenAmountB = parseFloat((parseFloat(amount) * balance1 / totalSupply).toFixed(0))


    return [true, tokenAmountA, tokenAmountB]
  }
  catch (e) {
    console.error(e);
    return [false, null, null]
  }
}

export const ApproveToken = async (tokenA: string, tokenB: string, amount = MAX_UINT256): Promise<TransactionReceipt | null> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);

    // console.log(tokenA, tokenB);

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])


    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()
    // console.log("tx", pair_address);

    if (pair_address === ZERO_ADDRESS) return null

    const pair = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], pair_address);

    const data = pair.methods.approve(ContractMetadata[chainId].router.address, amount).encodeABI();
    const from = state.wallet.address;
    const to = pair_address

    const tx = {
      from,
      to,
      data,
    }

    const resp = await SendTransaction(tx)
    return resp

  }
  catch (e) {
    console.error(e);
    return null
  }
}

export const CheckPairExists = async (tokenA: string, tokenB: string): Promise<[boolean | null, string, string[]]> => {
  try {
    const state = store.getState()
    let chainId = DEFAULT_CHAIN_ID
    if (state.wallet.connected) {
      chainId = state.wallet.chain_id as string
    }

    const provider = new Xdc3.providers.HttpProvider(PROVIDERS[chainId])
    const xdc3 = new Xdc3(provider);

    [tokenA, tokenB] = RectifyParams([tokenA, tokenB])

    const factory = new xdc3.eth.Contract(FACTORY_ABI as AbiItem[], ContractMetadata[chainId].factory.address);

    const pair_address = await factory.methods.getPair(tokenA, tokenB).call()

    if (pair_address === ZERO_ADDRESS) return [false, "", []]
    const pair = new xdc3.eth.Contract(PAIR_ABI as AbiItem[], pair_address);

    const path = await Promise.all([pair.methods.token0().call(), pair.methods.token1().call()])
    return [true, pair_address, path]
  }
  catch (e) {
    console.log(e);
    return [null, "", []]
  }
}

export const RectifyParams = (params: any[]): any[] => {
  return params.map((x: any) => {
    if (typeof x === "string" && Utils.isAddress(x)) {
      return Utils.fromXdcAddress(x);
    }
    return x
  })
}
