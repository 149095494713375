import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import { connect } from 'react-redux';
import { DEX_API } from "../../../helper/constant";
import { PairTransaction } from "../../../types";
import * as actions from "../../../redux/actions/index";
import { Theme } from "../../../types";
import CustomTool from './CustomTool'
import { ChartLoader } from "../../common/ChartLoader";
import { LanguageContext } from "../../../Context/Language";
import { GetLanguage } from "../../../assets/translations";

interface StateInterface {
  liquidityData: PairTransaction[] | any;
  payload: { token0: number; token1: number } | null | undefined;
  loading: boolean,
  chartDataSwitch: boolean,
  windowWidth:any,
}

interface TokenLmt {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

interface PropsInterface extends actions.ActionsInterface {
  pair: string;
  first?: TokenLmt;
  second?: TokenLmt;
  theme: Theme;
}

class Liquidity extends React.Component<PropsInterface, StateInterface> {
  ONE_DAY: number = 86400 * 1000;
  limit: number = 30;
  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      liquidityData: [],
      payload: null,
      loading: true,
      chartDataSwitch: false,
      windowWidth: window.innerWidth
    };
  }

  componentDidMount() {
    this.getLiquidityChart();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };


  getLiquidityChart() {
    const fromTs = Date.now() - this.limit * this.ONE_DAY;
    const from = new Date(fromTs);
    axios
      .post(`${DEX_API}/pair-liquidity-chart-data`, {
        pair: this.props.pair,
        from,
      })
      .then((resp: any) => {
        if (resp && resp.data && resp.data.data) {
          const data = resp.data.data;
          const allLiqData = [];
          for (const liqData of data) {
            const temp = {
              _id: liqData._id,
              pair: liqData.pair,
              timestamp: liqData.timestamp,
              token0: liqData.token0,
              token0Name: liqData.token0Name,
              token0Price: liqData.token0Price,
              token1: liqData.token1,
              token1Name: liqData.token1Name,
              token1Price: liqData.token1Price,
              totalPrice: (liqData.token0 * liqData.token0Price) + (liqData.token1 * liqData.token1Price),
            }
            allLiqData.push(temp)
          }
          this.setState({ liquidityData: allLiqData, loading: false });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  toogleChartData = () => {
    this.setState({
      chartDataSwitch: !this.state.chartDataSwitch
    })
  }

  handleResize = (e:any) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    this.text = GetLanguage(this.context.language);
    let colorFill, colorFill1;
    if (this.props.theme === 'light' ) {
      colorFill = '#0fa9d2';
      colorFill1 = '#141867';
    }else if(this.props.theme === 'dark'){
      colorFill = '#C38FFF';
      colorFill1 = '#0db2d7';
    } else {
      colorFill = '#f85797';
      colorFill1 = '#b73690';
    }

    const symbol1 = this.props.first?.symbol;
    const symbol2 = this.props.second?.symbol;
    const chartToggle = this.state.chartDataSwitch;
    let disbaled;
    this.state.liquidityData.map((data:any) => {
     if(parseFloat(data.token0Price) === 0 || parseFloat(data.token1Price) === 0){
       disbaled=true;
       return disbaled;
     }
     return null;
    })


    let labels = true, labelWidth = 150;
    if(this.state.windowWidth <= 800){
      labels = false;
      labelWidth = 0;
    }


    return (

      <div className="chart-liqduidity">
        <div className="chart-liqduidity-legend" onChange={() => this.toogleChartData()}>
          <label className="switch"> <span>{this.renderLang('token_amt')}</span>
            <input id="switch" type="radio" name="amt" defaultChecked />
          </label>
          {disbaled ? null :
          (<label className="switch1"><span>{this.renderLang('total_usd')}</span>
            <input id="switch1" type="radio" name="amt" />
          </label>)
          }
        </div>
        {this.state.loading ? (
         <ChartLoader />
        )
          :
          (
            <div className="chart-liqduidity-canvas">
              <ResponsiveContainer width="100%" height="90%"  >
                <AreaChart
                  data={
                    this.state.liquidityData ? this.state.liquidityData : undefined
                  }
                  margin={{
                    right: 0,
                    bottom: 0,
                    left: 0,
                    top: 30
                  }}

                >
                  <defs>
                    <linearGradient id="token0" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={colorFill} stopOpacity={1} />
                      <stop offset="95%" stopColor={colorFill1} stopOpacity={1} />
                    </linearGradient>
                    <linearGradient id="token1" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={colorFill1} stopOpacity={1} />
                      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <XAxis stroke={colorFill} dataKey="_id" interval="preserveEnd" tick={labels} />
                  <YAxis stroke={colorFill} yAxisId="right" width={labelWidth} orientation="right"  tick={labels} />
                  <YAxis stroke={colorFill1} yAxisId="left" width={labelWidth} tick={labels} />

                  <Tooltip
                    content={<CustomTool chartType='liquidity' symbol1={symbol1} symbol2={symbol2} chartToggle={chartToggle} />}
                    cursor={true}
                    position={{ x: 10, y: -100 }}
                    viewBox={{ width: 400, height: 400 }}
                    active={true} />
                  {this.state.chartDataSwitch ?
                    (
                      <Area
                        isAnimationActive={true}
                        yAxisId="right"
                        type="monotone"
                        dataKey="totalPrice"
                        stroke={colorFill1}
                        fill="url(#token1)"
                      />
                    ) :
                    (

                      <>
                        <Area
                        isAnimationActive={true}
                          yAxisId="left"
                          type="monotone"
                          dataKey="token0"
                          stroke={colorFill}
                          fill="url(#token0)"
                        />

                        <Area
                        isAnimationActive={true}
                          yAxisId="right"
                          type="monotone"
                          dataKey="token1"
                          stroke={colorFill1}
                          fill="url(#token1)"
                        />
                      </>
                    )
                  }

                </AreaChart>
              </ResponsiveContainer>
            </div>
          )
        }
      </div>
    );
  }
}
function mapStateToProps({ theme }: { theme: Theme }) {
  return { theme };
}

export default connect(mapStateToProps, actions)(Liquidity);
