import { MonthNames, USDDecimals, Delimiter } from "../../helper/constant";

const CustomToolTip = ({ active, payload, label,chartType } :any) => {
  if (active && payload && payload.length) {
    const tolldate = new Date(payload[0].payload.day);
    const cdate = `${
      MonthNames[tolldate.getMonth()]
    } ${tolldate.getDate()},'${tolldate.getFullYear()}`;

    if(chartType === 'liquidity'){
      return (
        <div className="toolTip-wrapper">
          <p className="chart-title">Liquidity</p>
          <p>${Delimiter(payload[0].payload.totalLiquidity.toFixed(USDDecimals))}</p>
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    }else{
      return (
        <div className="toolTip-wrapper">
          <p className="chart-title">Volume</p>
          <p>${Delimiter(payload[0].payload.totalVolume.toFixed(USDDecimals))}</p>
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    }

  }
  return null;
};

export default CustomToolTip;
