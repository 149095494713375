export const WALLET_CONNECTED = "WALLET_CONNECTED";
export const WALLET_DISCONNECTED = "WALLET_DISCONNECTED";
export const WALLET_ADDRESS_CHANGED = "WALLET_ADDRESS_CHANGED";
export const WALLET_CHAIN_CHANGED = "WALLET_CHAIN_CHANGED";

export const NETWORK_VALID = "NETWORK_VALID";
export const NETWORK_INVALID = "NETWORK_INVALID";

export const THEME_CHANGED = "THEME_CHANGED"

export const SETTINGS_CHANGED = "SETTINGS_CHANGED"

export const CUSTOM_TOKEN_ADDED = "CUSTOM_TOKEN_ADDED"
export const CUSTOM_TOKEN_REMOVED = "CUSTOM_TOKEN_REMOVED"
export const CUSTOM_POOL_ADDED = "CUSTOM_POOL_ADDED"
export const CUSTOM_POOL_REMOVED = "CUSTOM_POOL_REMOVED"

export const FETCHED_LATEST_PRICE = "FETCHED_LATEST_PRICE"
export const ACKNOWLEDGE_FEE = "ACKNOWLEDGE_FEE"

export const LANGUAGE_SWITCH = "LANGUAGE_SWITCH"