import { ReactElement } from "react";
import { BalanceOf } from '../../helper/crypto';
import Types, { Wallet, Theme } from "../../types";
import { connect } from "react-redux";
import { FromDecimals, RemoveExpo } from "../../helper/decimals";
import DefaultLogo from "../../assets/img/default.png";
import * as actions from "../../redux/actions/index";
import { useDispatch } from "react-redux";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import { useEffect, useState } from "react";
import Utils from "xdc3-utils";
import { ChainIdToExplorer, DEFAULT_CHAIN_ID } from "../../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagic,
  faMoon,
  faSun,
  IconDefinition,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import * as ActionTypes from "../../redux/actions/types";
import { ForceShowModal, Disconnect } from "xdc-connect";
import Button from "@restart/ui/esm/Button";
import {Delimiter} from '../../helper/constant'

interface PropsInterface {
  tokenList: Types.TokenList;
  wallet: Wallet;
  theme: Theme;
}

const IconMap: { [k in Theme]: IconDefinition } = {
  light: faSun,
  dark: faMoon,
  iri: faMagic,
};


const WalletStatus = ({ tokenList, wallet, theme }: PropsInterface) => {
  const [bal, setBal] = useState('')
  const dispatch = useDispatch();

  const _first = DEFAULT_TOKEN_LIST.filter(
    (x) => x.symbol === 'XDC'
  );
  const logo = _first.length > 0 ? _first[0].logo : DefaultLogo;


  useEffect(() => {
    getBalance()
  })

  const getBalance = () => {
    const getToken = tokenList.find((token) => token.symbol === "XDC");
    if (getToken) {
      BalanceOf(getToken).then((balances) => {
        let balance = "0";
        if (!balances) {
          balance = "0";
          setBal(balance)
        } else {
          balance = RemoveExpo(parseFloat(
            FromDecimals(balances[0], getToken.decimals)
          ).toFixed(2));
          setBal(balance)
        }
      });
    }
  }


  const toggleTheme = (theme: Theme): Theme => {
    const themes: Theme[] = ["light", "dark", "iri"];
    return themes[(themes.indexOf(theme) + 1) % themes.length];
  }

  const renderThemeButton = (): ReactElement => {
    const themes = theme;
    return (
      <div
        className={"theme-button"}
        onClick={() => dispatch({
          type: ActionTypes.THEME_CHANGED,
          payload: toggleTheme(themes),
        })}
      >
        <FontAwesomeIcon icon={IconMap[themes]} />
      </div>
    );
  }

  const renderLogout = () => {
    if (!wallet.connected) return <div className="logout"></div>;
    return (
      <div className="logout" onClick={() => Disconnect()}>
        <FontAwesomeIcon icon={faSignOutAlt} />
      </div>
    );
  }

  const walletConnectProps = {
    disabled: false,
    btnName: "CONNECT WALLET",
  };

  if (wallet.connected) {
    if (wallet.valid_network === true) {
      walletConnectProps.disabled = true;
      walletConnectProps.btnName = "CONNECTED";
    } else {
      walletConnectProps.disabled = true;
      walletConnectProps.btnName = "INCORRECT NETWORK";
    }
  }

  let btnStatus = "";
  if (walletConnectProps.btnName === "CONNECTED") {
    btnStatus = "btn btn-dex-success";
  } else if (walletConnectProps.btnName === "INCORRECT NETWORK") {
    btnStatus = "btn btn-dex-danger";
  } else {
    btnStatus = "btn btn-dex-gradient-outline";
  }

  return (<div className="wallet-balance-main">
    {wallet.valid_network && wallet.connected ?
      <div className="wallet-status-container-footer">
        <div className={`balance ${bal.length > 12 ? 'ft-12':''}`}>
          <img src={logo} alt="xdc" height="28" />{" "}
          {Delimiter(bal)}
        </div>
        <div className="account">
          <div className="account-inner">
            <a
              href={`${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/address/${Utils.toXdcAddress(wallet.address)}`}
              target="_blank"
              rel="noreferrer"
            >
              <span>
                {Utils.toXdcAddress(wallet.address.toString().slice(0, 5))}
              </span>
            </a>

          </div>
        </div>
      </div>
      : (<div className="xdc-connect-wrapper-footer"><Button
        className={btnStatus}
        onClick={() => ForceShowModal()}
        disabled={walletConnectProps.disabled}
      >
        {walletConnectProps.btnName}
      </Button></div>)
    }
    <div className="ac-logout">{renderLogout()}</div>
    <div className="theme-changer">{renderThemeButton()}</div>
  </div>);
}

function mapStateToProps({
  wallet,
  tokenList,
  theme
}: {
  wallet: Wallet;
  tokenList: Types.TokenExtended[];
  theme: Theme;
}) {
  return { tokenList, wallet, theme };
}

export default connect(mapStateToProps, actions)(WalletStatus);