import * as types from "../actions/types";


interface AssetPrice {
  [assetName: string]: number;
}

const initialPrice: AssetPrice = {}

interface Payload {
  payload: any;
  type: string;
}

const PriceReducer = (state = initialPrice, payload: Payload): AssetPrice => {
  switch (payload.type) {
    case types.FETCHED_LATEST_PRICE: {
      return payload.payload.assetPrice
    }
    default: return state
  }
}


export default PriceReducer
