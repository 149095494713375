import { Component } from "react";
import { SEO } from "../common/SEO";
export default class LearnMore extends Component {
  render() {
    return (
      <div className="learn-more-container">
        <SEO
          title="Learn More GlobianceDEX Incentives"
          description="GlobianceDEX is built on Automated-Market-Maker ( AMM ) first implemented by Uniswap. To incentivize liquidity providers, 0.3% fee is cut as a trade fee and re-distributed to all the liquidity providers of that pool."
          pathSlug="/learn-more-incentives"
          keywords={["globiance", "dex", "learn", "more"]}
        />
        <div className="text-container">
          <h4>GlobianceDEX Incentives</h4>
          <p>
            GlobianceDEX is built on Automated-Market-Maker ( AMM ) first
            implemented by Uniswap. To incentivize liquidity providers, 0.3% fee
            is cut as a trade fee and re-distributed to all the liquidity
            providers of that pool.
          </p>
          <p>
            On top of this standard fee, an additional incentivizing model is
            being developed to further incentivize the liquidity providers. This
            model is based on a{" "}
            <span className="highlight">
              Game&nbsp;Theoretical&nbsp;Approach
            </span>{" "}
            which rewards long-term liquidity providers in tokens. <br />
            Globiance is working in tandem with multiple tokens to get implement
            this incentive model.
          </p>
        </div>
      </div>
    );
  }
}
