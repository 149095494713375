import { TokenList } from "../../types";
import { CUSTOM_TOKEN } from "../../helper/localNS";
import DefaultLogo from "../../assets/img/default.png";
import * as types from "../actions/types";
import { DEFAULT_TOKEN_LIST } from '@globiance/default-token-list';


interface Token {
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  custom?: boolean;
  feeOnTransfer?: boolean;
}

// localStorage.setItem(CUSTOM_TOKEN, JSON.stringify([]))

function getCurrentCustom(): Token[] {
  const tokenListStr = localStorage.getItem(CUSTOM_TOKEN)
  if (!tokenListStr) return []
  try {
    return JSON.parse(tokenListStr)

  }
  catch (e) {
    return []
  }
}

function getCustomToken(): TokenList {
  const curtomList = getCurrentCustom()
  return curtomList.map((x) => { return { ...x, logo: DefaultLogo, custom: true } })

}

const initialState = [...DEFAULT_TOKEN_LIST, ...getCustomToken()]

interface Payload {
  payload: any;
  type: string;
}


const TokenListReducer = (state = initialState, payload: Payload): TokenList => {
  switch (payload.type) {
    case types.CUSTOM_TOKEN_ADDED: {
      const updated = [...getCurrentCustom(), { ...payload.payload, custom: true }]
      localStorage.setItem(CUSTOM_TOKEN, JSON.stringify(updated))
      return [...DEFAULT_TOKEN_LIST, ...updated]
    }

    case types.CUSTOM_TOKEN_REMOVED: {
      const custom: any = getCurrentCustom();
      for (let i = 0; i < custom.length; i++) {
        if (custom[i].symbol === payload.payload.symbol) {
          custom.splice(i, 1)
          localStorage.setItem(CUSTOM_TOKEN, JSON.stringify(custom))
          return [...DEFAULT_TOKEN_LIST, ...custom]
        }
      }

      return state
    }
    default: return state
  }
}


export default TokenListReducer