import React from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import CopyClipboard from "../common/CopyClipboard";
import { SEO } from "../common/SEO";
// import { Link } from "react-router-dom";

function ListView(content: any): React.ReactElement {
  return (
    <li>
      <div className="list-style">
        <FontAwesomeIcon icon={faCheckCircle} />
        {content}
      </div>
    </li>
  );
}

class Faq extends React.Component {
  static contextType = LanguageContext;
  text: any;

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };

  render() {
    this.text = GetLanguage(this.context.language);
    return (
      <div className="faq-container">
        <SEO
          keywords={["globiance", "globiancedex", "dex", "token", "prices"]}
          title="Frequently Asked Questions (FAQ) | GlobianceDEX"
          pathSlug="/faq"
          description="This frequently asked questions when you use GlobianceDEX, find the answers here."
        />
        <div className="main-faq-title">{this.renderLang("faq_full")}</div>
        <div className="faq-content-section">
          <div className="section-wrapper">
            <div className="section-wrapper-title" id="general">
              <div className="section-b">
                {this.renderLang('general')}
                <CopyClipboard copyText="https://dex.globiance.com/faq#general" />{" "}
              </div>
            </div>
            <div className="section-wrapper-body">
              <div id="general--what-is-globiance-dex" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_what_globiance')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#general--what-is-globiance-dex" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_what_globiance_desc'))}
                  {ListView(this.renderLang('faq_new_what_globiance_desc1'))}
                </ul>
              </div>
            </div>

            <div className="section-wrapper-body">
              <div
                id="general--what-is-globiance-dex-site"
                className="section-one"
              >
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_gb_official')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#general--what-is-globiance-dex-site" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(<>
                    {this.renderLang('faq_new_gb_official_desc')}
                    <a href={this.renderLang('base_link')}>{this.renderLang('base_link')}</a>
                  </>

                  )}
                </ul>
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="general--what-is-dex" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_decentral_exg')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#general--what-is-dex" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(
                    this.renderLang('faq_new_decentral_exg_desc')
                  )}
                </ul>
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="general--how-does-a-dex-work" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_decentral_exg_work')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#general--how-does-a-dex-work" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(
                    this.renderLang('faq_new_decentral_exg_work_desc')
                  )}
                  {ListView(
                    this.renderLang('faq_new_decentral_exg_work_desc1')
                  )}
                  {ListView(
                    this.renderLang('faq_new_decentral_exg_work_desc2')
                  )}
                </ul>
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="overview" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_deff_cent_decent')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#general--difference-between-cex-dex" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(
                    this.renderLang('faq_new_deff_cent_decent_desc')
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper-title" id="liquidity">
              <div className="section-b">
                {this.renderLang('liquidity')}
                <CopyClipboard copyText="https://dex.globiance.com/faq#liquidity" />{" "}
              </div>
            </div>
            <div className="section-wrapper-body">
              <div
                id="liquidity--how-does-globiance-dex-work"
                className="section-one"
              >
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_dex_work')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#liquidity--how-does-globiance-dex-work" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_dex_work_desc'))}
                  {ListView(this.renderLang('faq_new_dex_work_desc1'))}
                  {ListView(this.renderLang('faq_new_dex_work_desc2'))}
                  {ListView(this.renderLang('faq_new_dex_work_desc3'))}
                  {ListView(this.renderLang('faq_new_dex_work_desc4'))}
                  {ListView(this.renderLang('faq_new_dex_work_desc5'))}
                </ul>
              </div>

              <div
                id="liquidity--how-does-liquidity-work-globiance-dex"
                className="section-one"
              >
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_liq_gb_work')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#liquidity--how-does-liquidity-work-globiance-dex" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_liq_gb_work_desc'))}
                  {ListView(this.renderLang('faq_new_liq_gb_work_desc1'))}
                  {ListView(this.renderLang('faq_new_liq_gb_work_desc2'))}
                  {ListView(this.renderLang('faq_new_liq_gb_work_desc3'))}
                </ul>
              </div>

              <div
                id="liquidity--how-to-provide-liquidity"
                className="section-one"
              >
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_liq_to_exg')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#liquidity--how-to-provide-liquidity" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_liq_to_exg_desc'))}
                  {ListView(this.renderLang('faq_new_liq_to_exg_desc1'))}
                  {ListView(this.renderLang('faq_new_liq_to_exg_desc2'))}
                  {ListView(this.renderLang('faq_new_liq_to_exg_desc3'))}
                </ul>
              </div>

              {/* <div className="section-wrapper-body">
                <div id="liquidity--gbex-rewards" className="section-one">
                  <div className="section-title">
                    <div className="section-b">
                      {this.renderLang('faq_new_rewards_lock')}
                      <CopyClipboard copyText="https://dex.globiance.com/faq#liquidity--gbex-rewards" />{" "}
                    </div>
                  </div>
                  <ul className="data-content">
                    {ListView(this.renderLang('faq_new_rewards_lock_desc'))}
                    {ListView(this.renderLang('faq_new_rewards_lock_desc1'))}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper-title" id="token-listing">
              <div className="section-b">
                {this.renderLang('token_listing')}
                <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing" />{" "}
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="token-listing--tokens-listed" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_listed_token')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing--tokens-listed" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_listed_token_desc'))}
                  {ListView(this.renderLang('faq_new_listed_token_desc1'))}
                </ul>
              </div>

              <div id="token-listing--swap-eurg-usdg" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_swap')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing--swap-eurg-usdg" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_swap_desc'))}
                </ul>
              </div>

              <div id="token-listing--trade-custom" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_token_trade')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing--trade-custom" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_token_trade_desc'))}
                </ul>
              </div>

              <div id="token-listing--share-custom" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_community_token')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing--share-custom" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_community_token_desc'))}
                  {ListView(this.renderLang('faq_new_community_token_desc1'))}
                  {ListView(
                    <>
                      {this.renderLang('examples')}:{" "}
                      <ul>
                        <li>
                          {this.renderLang('faq_swap')}:&nbsp;
                          <a
                            href={this.renderLang('faq_swap_output_link')}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.renderLang('faq_swap_output_link')}
                          </a>
                        </li>
                        <li>
                          {this.renderLang('faq_add')}:&nbsp;
                          <a
                            href={this.renderLang('faq_add_output_link')}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.renderLang('faq_add_output_link')}
                          </a>
                        </li>
                      </ul>
                    </>
                  )}
                  {ListView(
                    <>
                      {this.renderLang('faq_new_community_token_desc2')}
                      <br />
                      {this.renderLang('examples')}:{" "}
                      <ul>
                        <li>
                          {this.renderLang('faq_swap')}:&nbsp;
                          <a
                            href={this.renderLang('faq_new_swap_eg_link')}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.renderLang('faq_new_swap_eg_link')}
                          </a>
                        </li>
                        <li>
                          {this.renderLang('faq_add')}:&nbsp;
                          <a
                            href={this.renderLang('faq_new_add_eg_link')}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.renderLang('faq_new_add_eg_link')}
                          </a>{" "}
                        </li>
                      </ul>
                    </>
                  )}
                </ul>
              </div>

              <div id="token-listing--listing-token" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_community_rq_token_listing')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing--listing-token" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_community_rq_token_listing1'))}

                  {ListView(
                    <>
                      {this.renderLang('faq_new_community_rq_token_listing2')}{" "}
                      <a
                        href={this.renderLang('token_listing_form_link')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {this.renderLang('token_listing_form_link')}
                      </a>
                      .
                    </>
                  )}

                  {ListView(this.renderLang('faq_new_community_rq_token_listing3'))}
                </ul>
              </div>

              <div
                id="token-listing--listing-requirement"
                className="section-one"
              >
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_token_listing_details')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#token-listing--listing-requirement" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(
                    <>
                      {this.renderLang('faq_new_token_listing_details_form')}{" "}
                      <a
                        href={this.renderLang('token_listing_form_link')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {this.renderLang('token_listing_form_link')}
                      </a>{" "}
                      {this.renderLang('faq_new_token_listing_details_form1')}
                      <br />
                      <br />
                      <ol>
                        <li>{this.renderLang('faq_new_token_listing_details1')}</li>
                        <li>{this.renderLang('faq_new_token_listing_details2')}</li>
                        <li>{this.renderLang('faq_new_token_listing_details3')}</li>
                        <li>{this.renderLang('faq_new_token_listing_details4')}</li>
                        <li>{this.renderLang('faq_new_token_listing_details5')}</li>
                        <li>{this.renderLang('faq_new_token_listing_details6')}</li>
                      </ol>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper-title" id="pricing">
              <div className="section-b">
                {this.renderLang('pricing')}
                <CopyClipboard copyText="https://dex.globiance.com/faq#pricing" />{" "}
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="pricing--usd-prices" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_usd_asset')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#pricing--usd-prices" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_usd_asset_desc'))}
                  {ListView(this.renderLang('faq_new_usd_asset_desc1'))}
                  {ListView(this.renderLang('faq_new_usd_asset_desc2'))}
                  {ListView(this.renderLang('faq_new_usd_asset_desc3'))}
                </ul>
              </div>

              <div id="pricing--view-prices" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_price_diff')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#pricing--view-prices" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_price_diff_desc'))}
                </ul>
              </div>
            </div>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper-title" id="wallets">
              <div className="section-b">
                {this.renderLang('wallets')}
                <CopyClipboard copyText="https://dex.globiance.com/faq#wallets" />{" "}
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="wallets--supported-wallets" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_wallet_connect')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#wallets--supported-wallets" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(
                    <>
                      {this.renderLang('faq_new_wallet_connect1')}
                      <br />
                      <br />
                      <ol>
                        <li>{this.renderLang('faq_new_wallet_connect2')}</li>
                        <li>{this.renderLang('faq_new_wallet_connect3')}</li>
                        <li>{this.renderLang('faq_new_wallet_connect4')}</li>
                        <li>{this.renderLang('faq_new_wallet_connect5')}</li>
                      </ol>
                      <br />
                      {this.renderLang('faq_new_wallet_connect6')}
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="wallets--xdcpay" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_issue_xdcpay')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#wallets--xdcpay" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_issue_xdcpay1'))}
                  {ListView(
                    <>
                      {this.renderLang('faq_new_issue_xdcpay2')}
                      <br />
                      <br />
                      <ol>
                        <li>{this.renderLang('faq_new_issue_xdcpay3')}</li>
                        <li>{this.renderLang('faq_new_issue_xdcpay4')}</li>
                        <li>{this.renderLang('faq_new_issue_xdcpay5')}</li>
                      </ol>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="wallets--rewards" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_rewards_liq')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#wallets--rewards" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_rewards_liq1'))}

                  {ListView(this.renderLang('faq_new_rewards_liq2'))}

                  {ListView(
                    <>
                      {this.renderLang('faq_new_rewards_liq3')}{" "}
                      <a href={this.renderLang('learn_more_incentives_link')}>{this.renderLang('more')}</a>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="section-wrapper">
            <div className="section-wrapper-title" id="upcoming">
              <div className="section-b">
                {this.renderLang('upcoming')}
                <CopyClipboard copyText="https://dex.globiance.com/faq#upcoming" />{" "}
              </div>
            </div>

            <div className="section-wrapper-body">
              <div id="upcoming--list" className="section-one">
                <div className="section-title">
                  <div className="section-b">
                    {this.renderLang('faq_new_next_in_dex')}
                    <CopyClipboard copyText="https://dex.globiance.com/faq#upcoming--list" />{" "}
                  </div>
                </div>
                <ul className="data-content">
                  {ListView(this.renderLang('faq_new_next_in_dex1'))}

                  {ListView(this.renderLang('faq_new_next_in_dex2'))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
