import React from "react";
import { RouteComponentProps, Link } from "react-router-dom";

import { connect } from "react-redux";
import axios from "axios";
import _ from "lodash";

import PairLogo from "../common/PairLogo";
import * as actions from "../../redux/actions/index";
import DefaultLogo from "../../assets/img/default.png";
import { DEX_API, Delimiter, RoundUptoSignificant } from "../../helper/constant";
import Transactions from "./transactions";
import { Theme, TokenList as TokenListType, AssetPrice, AddressPrice } from "../../types";
import Charts from "./charts";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import { BalanceOfGeneral } from "../../helper/crypto";
import { PriceItem } from "../../types";
import { FromDecimals, RemoveExpo } from "../../helper/decimals";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { SEO } from "../common/SEO";
import Utils from "xdc3-utils";
import { CompareAddressValue } from "../../helper/addresses";

interface TokenLmt {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}
interface PropsInterface extends RouteComponentProps<{ pair: string }> {
  theme: Theme;
  tokenList: TokenListType;
  assetPrice: AssetPrice;
  addressPrice: AddressPrice;
  FetchAssetPrice: (data: PriceItem[]) => {};
}

interface StateInterface {
  firstLogo: any;
  secondLogo: any;
  firstBalance: any;
  secondBalance: any;
  loading: boolean;
  pair: {
    lastPrice: any;
    address: string;
    first: TokenLmt;
    second: TokenLmt;
    change: {
      token0: number;
      token1: number;
    };
    changePercent: {
      token0: number;
      token1: number;
    };
    vol24: {
      token0: number;
      token1: number;
    };
    price: {
      token0: number;
      token1: number;
    }
  } | null;
}

class PoolInfo extends React.Component<PropsInterface, StateInterface> {
  pair: string;
  ONE_DAY: number = 86400 * 1000;

  static contextType = LanguageContext;
  text: any = GetLanguage(this.context?.language);

  constructor(props: PropsInterface) {
    super(props);
    this.pair = this.props.match.params.pair;

    this.state = {
      firstLogo: null,
      secondLogo: null,
      firstBalance: null,
      secondBalance: null,
      pair: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.getPairInfo();

    axios
      .post(`${DEX_API}/get-token-price`)
      .then((resp) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          this.props.FetchAssetPrice(data.data);
        }
      })
      .catch((e) => console.error(e));
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };

  getPairInfo() {
    axios
      .post(`${DEX_API}/get-pair-info`, { pair: this.pair })
      .then((resp: any) => {
        if (resp && resp.data && resp.data.data) {
          const pair = resp.data.data;

          const _first = DEFAULT_TOKEN_LIST.filter(
            (x: any) => CompareAddressValue(x.address,pair.first.address)
          );
          const _second = DEFAULT_TOKEN_LIST.filter(
            (x: any) => CompareAddressValue(x.address,pair.second.address)
          );
          const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
          const second = _second.length > 0 ? _second[0].logo : DefaultLogo;
          if(pair.first.symbol === 'WXDC' ){
            pair.first.symbol = 'XDC';
          }else if(pair.second.symbol === 'WXDC'){
            pair.second.symbol = 'XDC';
          }
          this.setState({ pair, firstLogo: first, secondLogo: second  });

          Promise.all([
            BalanceOfGeneral(pair.first.address, pair.address),
            BalanceOfGeneral(pair.second.address, pair.address),
          ]).then((balances: any) => {
            if (!balances[0]) return;
            const [bal0, bal1] = balances;
            this.setState({
              firstBalance: bal0,
              secondBalance: bal1,
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  renderHeader() {
    if (!this.state.pair || _.isEmpty(this.state.pair))
      return (
        <div className="text">
          <Skeleton width={250} height={50} />
        </div>
      );

    return (
      <>
        <div className="icon">
          <PairLogo
            frontImage={this.state.firstLogo}
            backImage={this.state.secondLogo}
          />
        </div>
        <div className="text">{`${this.state.pair.first.symbol} - ${this.state.pair.second.symbol}`}</div>
      </>
    );
  }

  renderStats() {
    if (
      !this.state.pair ||
      _.isEmpty(this.state.pair) ||
      !this.props.assetPrice ||
      !this.props.addressPrice
    )
      return (
        <div className="stats">
          <div className="stat">
            <div className="stats-block">
              <div className="label-box">
                <div className="main-label">{this.renderLang("liquidity")}</div>
                <span className="amount">
                  <Skeleton width={100} height={25} />
                </span>
                <span className="amount">
                  <Skeleton width={100} height={20} />
                </span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("volume24H")}</div>
                <span className="amount">
                  <Skeleton width={100} height={25} />
                </span>
                <span className="amount">
                  <Skeleton width={100} height={20} />
                </span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("last_price")}</div>
                <span className="token-amount-usd">
                  <Skeleton width={200} height={20} />
                </span>
                <span className="token-amount-usd">
                  <Skeleton width={200} height={20} />
                </span>
              </div>
            </div>
            <div className="locked-token-text">
              {this.renderLang("total_token_loked")}
            </div>
            <div className="locked-tokens">
              <div className="token">
                <span>
                  <Skeleton width={50} height={40} borderRadius={10} />
                </span>
                <div className="token-data">
                  <span className="token-amount">
                    <Skeleton width={100} height={25} />
                  </span>
                  <span className="token-amount-usd">
                    <Skeleton width={100} height={20} />
                  </span>
                </div>
              </div>
              <div className="token">
                <span>
                  <Skeleton width={50} height={40} borderRadius={10} />
                </span>
                <div className="token-data">
                  <span className="token-amount">
                    <Skeleton width={100} height={25} />
                  </span>
                  <span className="token-amount-usd">
                    <Skeleton width={100} height={20} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    let liquidity = "-";

    const first = this.state.pair.first;
    const second = this.state.pair.second;

    let changeStr = <>-</>;


    const vol = this.state.pair.vol24.token0;
    let volUsd = "-";

    let firstBalanceUsd = "-";
    let secondBalanceUsd = "-";

    const firstBal:any = this.state.firstBalance!==null && parseFloat(
      FromDecimals(this.state.firstBalance, first.decimals)
    );
    const secondBal:any = this.state.secondBalance!==null && parseFloat(
      FromDecimals(this.state.secondBalance, second.decimals)
    );

    const frmtFirstAddress = (Utils.fromXdcAddress(first.address) as string).toLowerCase()
    const frmtSecondAddress = (Utils.fromXdcAddress(second.address) as string).toLowerCase()

    if (
      this.props.addressPrice[frmtFirstAddress] &&
      this.props.addressPrice[frmtSecondAddress]
    ) {
      let totUsd;
      if(this.state.firstBalance!==null || this.state.secondBalance!==null){
        totUsd = (
          firstBal * this.props.addressPrice[frmtFirstAddress] +
          secondBal * this.props.addressPrice[frmtSecondAddress]
        ).toFixed(2)
        liquidity =
          "$" + totUsd
          ;
      }else{
        totUsd ="0";
        liquidity="0";
      }

      volUsd = (vol * this.props.addressPrice[frmtFirstAddress]).toFixed(2);

      firstBalanceUsd = (
        firstBal * this.props.addressPrice[frmtFirstAddress]
      ).toFixed(0);

      secondBalanceUsd = (
        secondBal * this.props.addressPrice[frmtSecondAddress]
      ).toFixed(0);

      const usdChange =
        this.props.addressPrice[frmtSecondAddress] * this.state.pair.change.token1 +
        this.props.addressPrice[frmtFirstAddress] * this.state.pair.change.token0;

      const changeNumber = ((usdChange / parseFloat(totUsd))*100).toFixed(0)

      if (parseFloat(changeNumber) > 0) {
        changeStr = (
          <span className="green">
            {changeNumber}% <FontAwesomeIcon icon={faArrowUp} />
          </span>
        );
      } else if (parseFloat(changeNumber) === 0.0) {
        changeStr = <span className="gray">{changeNumber} </span>;
      } else {
        changeStr = (
          <span className="red">
            {changeNumber}% <FontAwesomeIcon icon={faArrowDown} />
          </span>
        );
      }
    }
    if (this.state.pair.change)
      return (
        <div className="stats">
          <div className="stat">
            <div className="stats-block">
              <div className="label-box">
                <div className="main-label">{this.renderLang("liquidity")}</div>
                <span className="amount">{Delimiter(liquidity)}</span>
                <span className="percentage">{changeStr}</span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("volume24H")}</div>
                <span className="amount">{Delimiter(vol.toFixed(3))} </span>
                <span className="percentage">{'$'}{Delimiter(volUsd)}</span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("last_price")}</div>

                <span className="token-amount-usd">
                  {RoundUptoSignificant(RemoveExpo(this.state.pair.lastPrice))} / {" "}
                 <span className="text-muted">{'$'}{RoundUptoSignificant(RemoveExpo((this.state.pair.lastPrice * this.state.pair.price.token1)))}</span>
                </span>
                {/* <span className="token-amount-usd"><img src={this.state.firstLogo} alt="" className="img-stats-span" /> {this.state.pair.first.symbol} /  <img src={this.state.secondLogo}  className="img-stats-span" alt="" /> <span className="text-muted">{this.state.pair.second.symbol}</span></span> */}
              </div>
            </div>
            <div className="locked-token-text">
              {this.renderLang("total_token_loked")}
            </div>
            <div className="locked-tokens">
              <div className="token">
                <Link
                  to={`/info/token/${Utils.toXdcAddress(
                    this.state.pair.first.address
                  )}`}
                >
                  <span>
                    <img src={this.state.firstLogo} alt="" /> {first.symbol}
                  </span>
                </Link>
                <div className="token-data">
                  <span className="token-amount">
                    {Delimiter(firstBal===false? 0: firstBal.toFixed(2))}
                  </span>
                  <span className="token-amount-usd">
                    {'$'}{Delimiter(firstBalanceUsd)}
                  </span>
                </div>
              </div>
              <div className="token">
                <Link
                  to={`/info/token/${Utils.toXdcAddress(
                    this.state.pair.second.address
                  )}`}
                >
                  <span>
                    <img src={this.state.secondLogo} alt="" />
                    {second.symbol}
                  </span>
                </Link>
                <div className="token-data">
                  <span className="token-amount">
                    {Delimiter(secondBal===false?0:secondBal.toFixed(2))}
                  </span>
                  <span className="token-amount-usd">
                    {'$'}{Delimiter(secondBalanceUsd)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }

  render() {
    this.text = GetLanguage(this.context.language);
    return (
      <div className="pool-info">
        <SEO
          keywords={["globiance", "globiancedex", "dex", "pool"]}
          title="Pool Transactions | Add Liquidity | Trade | GlobianceDEX"
          pathSlug="pool"
          description="Join the GlobianceDEX pool will help users to trade volume, liquidity & trading view."
        />
        <div className="header">
          <div className="title">{this.renderHeader()}</div>
          {this.state.pair ? (
            <div className="button-group">
              <Link
                to={`/add?outputCurrency=${this.state.pair.second.symbol==='XDC'||this.state.pair.second.symbol==='WXDC'?this.state.pair.second.symbol:this.state.pair.second.address}&inputCurrency=${this.state.pair.first.symbol==='XDC'||this.state.pair.first.symbol==='WXDC'?this.state.pair.first.symbol:this.state.pair.first.address}`}
                className="btn btn-add"
              >
                {this.renderLang("add_liquidity")}
              </Link>
              <Link
                to={`/swap?outputCurrency=${this.state.pair.second.symbol==='XDC'||this.state.pair.second.symbol==='WXDC'?this.state.pair.second.symbol:this.state.pair.second.address}&inputCurrency=${this.state.pair.first.symbol==='XDC'||this.state.pair.first.symbol==='WXDC'?this.state.pair.first.symbol:this.state.pair.first.address}`}
                className="btn btn-trade"
              >
                {this.renderLang("trade")}
              </Link>
            </div>
          ) : null}
        </div>

        <div className="trading-stats">
          {this.renderStats()}

          <Charts
            pair={this.pair}
            first={this.state.pair?.first}
            second={this.state.pair?.second}
            theme={this.props.theme}
          />
        </div>
        <Transactions
          decimals0={this.state.pair?.first.decimals}
          decimals1={this.state.pair?.second.decimals}
          pair={this.pair}
          tokenSymbol0={this.state.pair?.first.symbol}
          tokenSymbol1={this.state.pair?.second.symbol}
        />
      </div>
    );
  }
}

function mapStateToProps({
  theme,
  tokenList,
  assetPrice,
  addressPrice
}: {
  theme: Theme;
  tokenList: TokenListType;
  assetPrice: AssetPrice;
  addressPrice: AddressPrice
}) {
  return { theme, tokenList, assetPrice, addressPrice };
}

export default connect(mapStateToProps, actions)(PoolInfo);
