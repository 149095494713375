import { Component } from "react";
import { LanguageContext } from "../Context/Language";
import { GetLanguage } from "../assets/translations";
import { SEO } from "./common/SEO";

export default class LearnMore extends Component {
  static contextType = LanguageContext;
    text: any;
  render() {
    this.text = GetLanguage(this.context.language);
    return (
      <div className="learn-more-container">
        <SEO
            keywords={["globiance", "globiancedex", "dex", "token", "prices"]}
            title="Fee On Transfer Tokens | GlobianceDEX"
            pathSlug="/fott"
            description="Certain token have an inclusive fees deducted on the transfer of their tokens, like GBEX. Inorder to account for this Fee / Tax traders will need to adjust the slippage to compensate for the tax deduction in transfer."
          />
        <div className="text-container">
          <h4>{this.text.fott_fee_on_token}</h4>
          <p>
          {this.text.fott_desc} <b>{this.text.gbex}</b>.
          {this.text.fott_desc1}
          </p>
        </div>
      </div>
    );
  }
}
