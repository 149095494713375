interface Providers {
  [k: string]: string
}

const PROVIDERS: Providers = {
  "551": "https://devnetrpc.apothem.network",
  "51": "https://rpc.apothem.network",
  "50": "https://rpcxdc.globiance.com/"
}

export default PROVIDERS;