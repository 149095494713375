import { LANGUAGE_LS } from "../../helper/localNS";
import * as types from "../actions/types";

function GetLang(): string {
  const localLang = localStorage.getItem(LANGUAGE_LS)
  if (localLang) return localLang
  return "EN"
}

const initialState = GetLang()

interface Payload {
  payload: any;
  type: string;
}

const LangReducer = (state = initialState, payload: Payload): string => {
  switch (payload.type) {
    case types.LANGUAGE_SWITCH: {
      localStorage.setItem(LANGUAGE_LS, payload.payload)
      return payload.payload
    }
    default: return state
  }
}


export default LangReducer
