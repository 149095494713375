import { AbiItem, fromXdcAddress } from "xdc3-utils"

import ROUTER_ABI from "../ABI/router.json"
import FACTORY_ABI from "../ABI/factory.json"
import ERC20_ABI from "../ABI/erc20.json"
import WXDC_ABI from "../ABI/wxdc.json"
import PAIR_ABI from "../ABI/pair.json"

interface ContractMetadataInterface {
  [network: string]: { [contract_name: string]: { abi: AbiItem[], address: string } };
}

export const ContractMetadata: ContractMetadataInterface = {
  "551": {
    "router": { abi: ROUTER_ABI as AbiItem[], address: "0x3b7BCAF7944CC60d8ea55d5E0Ee767a7c97969f8" },
    "factory": { abi: FACTORY_ABI as AbiItem[], address: "0xd0d807433d4e1C229f61E15bD5E3E0CcB5C7B1A7" },
    "wxdc": { abi: WXDC_ABI as AbiItem[], address: "0x6A67DE9634A30901E11fCC0C9A23b9A11b84a264" },
    "xrc20": { abi: ERC20_ABI as AbiItem[], address: "" },
    "pair": { abi: PAIR_ABI as AbiItem[], address: "" },
  },
  "51": {
    "router": { abi: ROUTER_ABI as AbiItem[], address: "xdc7E9188a1de444Ee2Bee2f75E99582310185f1513" },
    "factory": { abi: FACTORY_ABI as AbiItem[], address: "xdc0fF0FAdcA8A54C93704a718bCC5eda11dD683C5A" },
    "wxdc": { abi: WXDC_ABI as AbiItem[], address: "xdcc039850F937C623024DA66D6dF370022E6F16e30" },
    "xrc20": { abi: ERC20_ABI as AbiItem[], address: "" },
    "pair": { abi: PAIR_ABI as AbiItem[], address: "" },
  },
  "50": {
    "router": { abi: ROUTER_ABI as AbiItem[], address: "xdc90055EdC794e839567a5631d42752dB732E10C8F" },
    "factory": { abi: FACTORY_ABI as AbiItem[], address: "xdcA8334Aae58e5bDee692B26679c1817F9c42f8f51" },
    "wxdc": { abi: WXDC_ABI as AbiItem[], address: "xdc8A3cc832Bb6B255622E92dc9d4611F2A94d200DA" },
    "xrc20": { abi: ERC20_ABI as AbiItem[], address: "" },
    "pair": { abi: PAIR_ABI as AbiItem[], address: "" },
  }
}

export const CompareAddressValue = (a:string, b:string):boolean => {
  return (fromXdcAddress(a) as string).toLowerCase()===(fromXdcAddress(b) as string).toLowerCase()
}