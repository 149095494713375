import { PoolList, Token } from '../../types';
import { CUSTOM_POOL } from '../../helper/localNS';
import * as types from "../actions/types";
import { DEFAULT_POOL_LIST } from '@globiance/default-token-list';


interface Pool {
  first: Token, second: Token, address: string;
  custom?: boolean;
}

// localStorage.setItem(CUSTOM_TOKEN, JSON.stringify([]))

function getCurrentCustom(): Pool[] {
  const poolListStr = localStorage.getItem(CUSTOM_POOL)
  if (!poolListStr) return []
  try {
    return JSON.parse(poolListStr)

  }
  catch (e) {
    return []
  }
}

function getCustomPool(): PoolList {
  const curtomList = getCurrentCustom()
  return curtomList.map((x) => { return { ...x, custom: true } })

}

const initialState = [...DEFAULT_POOL_LIST, ...getCustomPool()]

interface Payload {
  payload: any;
  type: string;
}


const PoolListReducer = (state = initialState, payload: Payload): PoolList => {
  switch (payload.type) {
    case types.CUSTOM_POOL_ADDED: {
      const current = getCurrentCustom();
      if (current.filter(({ address }) => address === payload.payload.address).length > 0) return [...DEFAULT_POOL_LIST, ...current];
      const updated = [...current, { ...payload.payload, custom: true }]
      localStorage.setItem(CUSTOM_POOL, JSON.stringify(updated))
      return [...DEFAULT_POOL_LIST, ...updated]
    }

    case types.CUSTOM_POOL_REMOVED: {
      const custom: PoolList = getCurrentCustom();
      for (let i = 0; i < custom.length; i++) {
        if (custom[i].address === payload.payload) {
          custom.splice(i, 1)
          localStorage.setItem(CUSTOM_POOL, JSON.stringify(custom))
          return [...DEFAULT_POOL_LIST, ...custom]
        }
      }

      return state
    }
    default: return state
  }
}


export default PoolListReducer