import { useState, useContext } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { SettingsModal as SettingsType } from "../../types";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import * as Types from "../../redux/actions/types";

interface PropInterface {
  slippage: number;
  deadline: number;
  cb: (settings: SettingsType) => void;
}

interface ThemeState {
  theme: string;
}

function Settings({ slippage, deadline, cb }: PropInterface) {
  const language = useContext(LanguageContext);
  const text: any = GetLanguage(language.language);
  const dispatch = useDispatch();

  const [slippage_, setslippage] = useState(slippage);
  const [deadline_, setdeadline] = useState(deadline);
  const themes = useSelector((state: ThemeState) => state.theme);
  return (
    <div className={`settings ${themes}-theme`}>
      <div className="field">
        <div className="label">
          {text.settings_slippage}
          <div className="popover-box">
            <span className="qs">
              ?{" "}
              <span className="popover above">
                {" "}
                {text.settings_slippage_info}{" "}
              </span>
            </span>
          </div>
        </div>
        <div className="value">
          <input
            onChange={(e) => {
              let amount = e.target.value;
              amount = amount.replace(/^0+/, "");
              amount = amount.replace(/^\./, "0.");

              if (_.isEmpty(amount)) amount = "0";

              if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g.test(amount) === false) {
                return;
              }
              setslippage(parseFloat(amount));
            }}
            value={slippage_}
          />
        </div>
      </div>

      <div className="field">
        <div className="label">
          {text.settings_deadline}
          <br />
          {text.in_seconds}
        </div>
        <div className="value">
          <input
            onChange={(e) => {
              let amount = e.target.value;
              amount = amount.replace(/^0+/, "");
              amount = amount.replace(/^\./, "0.");

              if (_.isEmpty(amount)) amount = "0";

              if (/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g.test(amount) === false) {
                return;
              }

              setdeadline(parseFloat(amount));
            }}
            value={deadline_}
          />
        </div>
      </div>
      <div className="field">
        <div className="label">Language</div>
        <div className="value">
          <select
            value={language.language}
            onChange={(e) =>
              dispatch({
                type: Types.LANGUAGE_SWITCH,
                payload: e.target.value,
              })
            }
          >
            <option value="EN">EN</option>
            <option value="AR">AR</option>
            <option value="GE">GE</option>
            <option value="TH">TH</option>
            <option value="TR">TR</option>
            <option value="ES">ES</option>
            <option value="FR">FR</option>
            <option value="ID">ID</option>
            <option value="MY">MY</option>
            <option value="VN">VN</option>
            <option value="CN">CN</option>
            <option value="PT">PT</option>
            <option value="PH">PH</option>
            <option value="KR">KR</option>
            <option value="JP">JP</option>
          </select>
        </div>
      </div>
      <div className="save">
        <button
          className="btn btn-modal-button"
          onClick={() => cb({ slippage: slippage_, deadline: deadline_ })}
        >
          {text.save}
        </button>
      </div>
    </div>
  );
}

export default Settings;
