import { THEME_NS } from "../../helper/localNS";
import * as types from "../actions/types";


function GetTheme(): string {
  const theme = localStorage.getItem(THEME_NS)
  if (theme) return theme
  return "iri"
}

const initialState = GetTheme()

interface Payload {
  payload: any;
  type: string;
}

const ThemeReducer = (state = initialState, payload: Payload): string => {
  switch (payload.type) {
    case types.THEME_CHANGED: {
      localStorage.setItem(THEME_NS, payload.payload)
      return payload.payload
    }
    default: return state
  }
}


export default ThemeReducer
