import { MonthNames, USDDecimals, Delimiter } from "../../../helper/constant";
import { ROUND_DECIMALS_AMOUNT } from "../../../helper/decimals";


const CustomTool = ({
  active,
  payload,
  label,
  chartType,
  symbol1,
  symbol2,
  chartToggle,
}: any) => {

  const renderAmount = (symbol: any) => {
    return (
      ROUND_DECIMALS_AMOUNT[
      symbol
      ] || [2]
    );
  }

  if (active && payload && payload.length) {
    const datetool = new Date(payload[0].payload._id);
    const cdate = `${MonthNames[datetool.getMonth()]
      } ${datetool.getDate()},'${datetool.getFullYear()}`;
    if (chartType === "liquidity" && chartToggle === false) {
      return (
        <div className="toolTip-wrapper">
          <p className="total">
            {symbol1}: {Delimiter(payload[0].payload.token0.toFixed(renderAmount(symbol1)))}
          </p>
          <p className="total">
            {symbol2}: {Delimiter(payload[0].payload.token1.toFixed(renderAmount(symbol2)))}
          </p>
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    } else if (chartType === "liquidity" && chartToggle === true) {
      if(payload[0].payload.token0Price !== "0.00" || payload[0].payload.token1Price !== "0.00"){
        return (
          <div className="toolTip-wrapper">
            <p className="totalusd">
              $
              {Delimiter((
                payload[0].payload.totalPrice
              ).toFixed(USDDecimals))}
            </p>

            <p className="date-tooltip">{cdate}</p>
          </div>
        );
      }else{
        return null;
      }
    } else {
      let symbol,date = '';
      if (payload[0].payload.token0Price===0) {
        return (
          <div className="toolTip-wrapper">
            <p className="totalusd">

              {Delimiter((
                payload[0].payload.total0
              ).toFixed(USDDecimals))}{" "}{symbol1}

            </p>
            <p className="date-tooltip">{cdate}</p>
          </div>
        );
      }
      if ((payload[0].payload.total0 * payload[0].payload.token0Price).toFixed(USDDecimals) === "0.00") {
        symbol = symbol1;
        date = '';
      } else {
        symbol = "$";
        date = cdate;
      }
      return (
        <div className="toolTip-wrapper">
          <p className="totalusd">
            {symbol}{Delimiter((
              payload[0].payload.total0 * payload[0].payload.token0Price
            ).toFixed(USDDecimals))}
          </p>
          <p className="date-tooltip">{date}</p>
        </div>
      );
    }
  }
  return null;
};

export default CustomTool;
