import { COMMON_BASES } from "@globiance/default-token-list";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { fromXdcAddress } from "xdc3-utils";

import DefaultLogo from "../assets/img/default.png";
import { IsErc20Address, BalanceOf } from "../helper/crypto";

import * as actions from "../redux/actions/index";
import Types, { Wallet } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import Spinner from "../assets/loader/spinner.gif";
import { FromDecimals, RemoveExpo } from "../helper/decimals";
import { Token, Theme } from "../types";
import { LanguageContext } from "../Context/Language";
import { GetLanguage } from "../assets/translations";

type ListType = "token" | "base";

interface PropsnInterface extends actions.ActionsInterface {
  onTokenSelect: (token: Types.TokenExtended) => any;
  selected?: string;
  disabled?: string[];
  tokenList: Types.TokenList;
  wallet: Wallet;
  theme: Theme;
}

interface StateInterface {
  COMMON_BASES: Types.TokenList;
  filter: string;
  new_custom: Types.TokenExtended | null;
  balances: { [k: string]: any };
}

const formatAddress = (x: string) => {
  x = fromXdcAddress(x).toLowerCase();
  if (!x.startsWith("0x")) x = `0x${x}`;
  return x;
};

class TokenList extends React.Component<PropsnInterface, StateInterface> {
  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsnInterface) {
    super(props);

    this.state = {
      COMMON_BASES,
      filter: "",
      new_custom: null,
      balances: {},
    };
  }

  componentDidMount() {
    this.loadBalance();
  }

  componentDidUpdate(prevProps: PropsnInterface, prevState: StateInterface) {
    if (this.state.filter !== prevState.filter) {
      if (
        !this.props.tokenList.some(
          (x) => formatAddress(x.address) === formatAddress(this.state.filter)
        )
      )
        IsErc20Address(this.state.filter).then(
          ({ isErc20, name, symbol, decimals }) => {
            if (isErc20 && name && symbol && decimals) {
              this.setState({
                new_custom: {
                  logo: DefaultLogo,
                  name,
                  symbol,
                  decimals: parseInt(decimals),
                  address: this.state.filter,
                },
              });
            }
          }
        );
      else {
        this.setState({
          new_custom: null,
        });
      }
    }
  }

  loadBalance() {
    if (!this.props.wallet.connected) return;
    const loaders = this.props.tokenList.reduce((acc: any, cur: Token) => {
      acc[cur.symbol] = <img alt="logo" src={Spinner} />;
      return acc;
    }, {});
    this.setState({ balances: { ...loaders } }, () =>
      this.props.tokenList.forEach((token) => {
        BalanceOf(token).then((balances) => {
          let balance = "0";
          if (!balances) balance = "0";
          else
            balance = RemoveExpo(parseFloat(
              FromDecimals(balances[0], token.decimals)
            ).toFixed(2));

          this.setState({
            balances: { ...this.state.balances, [token.symbol]: balance },
          });
        });
      })
    );
  }

  addCustomToken(token: Types.TokenExtended) {
    this.props.AddCustomToken(token);
    this.setState({ new_custom: null });
  }

  selectToken(type: ListType, x: Types.TokenExtended) {
    this.props.onTokenSelect(x);
  }

  getTokenList(): Types.TokenExtended[] {
    const query = this.state.filter.toLowerCase().trim();
    if (query === "") return this.props.tokenList;
    return this.props.tokenList.filter(
      (x) =>
        formatAddress(x.address) === formatAddress(query) ||
        x.name.toLowerCase().includes(query) ||
        x.symbol.toLowerCase().includes(query)
    );
  }

  renderTokenList() {
    const list = this.getTokenList();

    if (list.length === 0 && !this.state.new_custom) {
      return <li className="no-token">{this.renderLang('no_token_found')}</li>;
    } else if (list.length === 0 && this.state.new_custom) {
      const custom = this.state.new_custom;
      return [
        <li key={`token-list-xxx-0`} className="no-token">
          {this.renderLang('no_token_found')}
        </li>,
        <li
          className={"custom__new"}
          // onClick={() => this.selectToken("token", custom)}
          key={`token-list-${custom.name}-${1}`}
        >
          <LazyLoadImage
            className="logo"
            src={custom.logo}
            alt={custom.symbol}
          />
          <div className="body">
            <div className="symbol">{custom.symbol}</div>
            <div className="name">{custom.name}</div>
          </div>
          <button
            onClick={() => this.addCustomToken(custom)}
            className="btn add-custom"
          >
            {this.renderLang('add')}
          </button>
        </li>,
      ];
    }

    return list.map((x: Types.TokenExtended, i: number) => {
      let className = "";

      if (this.props.disabled?.includes(x.symbol)) {
        className += " disabled";
      }

      if (x.custom) {
        className += " custom";
        return (
          <li className={className} key={`token-list-${x.name}-${i}`}>
            <div className="token" onClick={() => this.selectToken("token", x)}>
              <LazyLoadImage className="logo" src={x.logo} alt={x.symbol} />

              <div className="body">
                <div className="symbol">{x.symbol}</div>
                <div className="name">{x.name}</div>
              </div>
            </div>
            <div className="balance">{this.state.balances[x.symbol] || ""}</div>
            <div className="action">
              <FontAwesomeIcon
                onClick={() => this.props.RemoveCustomToken(x)}
                icon={faTrash}
              />
            </div>
          </li>
        );
      }
      return (
        <li
          className={className}
          onClick={() => this.selectToken("token", x)}
          key={`token-list-${x.name}-${i}`}
        >
          <LazyLoadImage className="logo" src={x.logo} alt={x.symbol} />
          <div className="body">
            <div className="symbol">{x.symbol}</div>
            <div className="name">{x.name}</div>
          </div>
          <div className="balance">{this.state.balances[x.symbol] || ""}</div>
        </li>
      );
    });
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null
    return langText;
  }

  renderCommonBases() {
    return COMMON_BASES.map((x: Types.TokenExtended, i: number) => {
      let className = "";

      if (this.props.disabled?.includes(x.symbol)) {
        className += " disabled";
      }

      return (
        <li
          className={className}
          onClick={() => this.selectToken("base", x)}
          key={`common-bases-${x.name}-${i}`}
        >
          <LazyLoadImage className="logo" src={x.logo} alt={x.symbol} />
          <div className="body">{x.symbol}</div>
        </li>
      );
    });
  }

  render() {
    this.text = GetLanguage(this.context.language);
    const themeClassname = `${this.props.theme}-theme`;

    return (
      <div className={`token-list-wrapper ${themeClassname}`}>
        <div className="search-box">
          <input
            placeholder="Token Symbol or Address"
            type="text"
            className="form-control"
            value={this.state.filter}
            onChange={(e) => this.setState({ filter: e.target.value })}
          />
        </div>
        <div className="common-bases-text">{this.renderLang('common_bases')}</div>
        <ul className="common-bases">{this.renderCommonBases()}</ul>
        <div className="common-bases-text">{this.renderLang('token_list')}</div>
        <ul className="token-list">{this.renderTokenList()}</ul>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  tokenList,
  theme,
}: {
  wallet: Wallet;
  tokenList: Types.TokenExtended[];
  theme: Theme;
}) {
  return { tokenList, wallet, theme };
}

export default connect(mapStateToProps, actions)(TokenList);
