import React, { Component } from "react";

class StakeDetails extends Component {
  render() {
    return (
      <div className="stake-wrapper">
        <div className="stats">
          <div className="stats-counter">
            <div className="stats-data">
              <h2 className="title">
                MINIMUM
                <br />
                REPUTATION
              </h2>
              <p>
                <span className="counts">10</span>
              </p>
            </div>
            <div className="stats-data">
              <h2 className="title">
                MINIMUM
                <br />
                STAKE AMOUNT
              </h2>
              <p>
                <span className="counts">10</span>
              </p>
            </div>
            <div className="stats-data">
              <h2 className="title">
                MAXIMUM
                <br />
                STAKE AMOUNT
              </h2>
              <p>
                <span className="counts">10</span>
              </p>
            </div>
            <div className="stats-data">
              <h2 className="title">
                CURRENT
                <br />
                REPUTATION
              </h2>
              <p>
                <span className="counts">NOT A STAKER</span>
              </p>
            </div>
            <div className="stats-data">
              <h2 className="title">
                HOSTING
                <br />
                REWARDS
              </h2>
              <p>
                <span className="counts">9,000 SRX P.A</span>
              </p>
            </div>
            <div className="stats-data">
              <h2 className="title">
                MAXIMUM
                <br />
                STAKE AMOUNT
              </h2>
              <p>
                <span className="counts">NOT A STAKER</span>
              </p>
            </div>
          </div>
        </div>
        <div className="stake-box">
          <div className="details">
            <div className="title">Stake Details</div>
            <ul>
              <li>
                <span>Staked</span>{" "}
                <span className="status pill-danger">False</span>
              </li>
              <li>
                <span>Staked</span>{" "}
                <span className="status pill-success">True</span>
              </li>
              <li>
                <span>Staked</span>{" "}
                <span className="status pill-warning">Failed</span>
              </li>
            </ul>
          </div>
          <div className="details">
            <div className="title">Stake</div>
            <div className="amount">
              <span>Approved Amount</span> <span>0</span>
            </div>
            <div className="stake-input-group">
              <input type="number" placeholder="Enter staking amount" />
              <button className="btn btn-stake" type="submit">
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StakeDetails;
