import React from "react";

import Liquidity from "./charts/Liquidity";
import TradeVolume from "./charts/TradeVolume";

import { TradingView } from "../TradingView/index";
// import { fas } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";

type ChartView = "liquidity" | "trade-volume" | "hloc";
interface TokenLmt {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

interface PropsInterface {
  pair: string;
  first?: TokenLmt;
  second?: TokenLmt;
  theme:string;
}

interface StateInterface {
  chartView: ChartView;
  tradeVolume: boolean;
  liquidity: boolean;
  tradingView: boolean;
  loading: boolean;
}

class Charts extends React.Component<PropsInterface, StateInterface> {
  ONE_DAY: number = 86400 * 1000;
  limit: number = 30;


  static contextType = LanguageContext;
  text: any;


  state: StateInterface = {
    chartView: "liquidity",
    tradeVolume: false,
    liquidity: true,
    tradingView: false,
    loading:true,
  };

  renderLang = (textName: string) => {
    const langData = textName;
    const  langText = this.text ? this.text[langData] : ""
    return langText;
  }


  renderChart() {
    if (this.state.chartView === "liquidity") {
      return <Liquidity {...this.props} />;
    } else if (this.state.chartView === "trade-volume") {
      return <TradeVolume {...this.props} />;
    } else {
      return (
        <TradingView
          address={this.props.pair}
          currentPair={`${this.props.first?.symbol}-${this.props.second?.symbol}`}
          theme={this.props.theme}
        />
      );
    }
  }

  render() {

    this.text = GetLanguage(this.context.language);

    return (
      <div className="chart">
        <div className="chart-select">
          <button className={`${this.state.tradeVolume ? 'active' : ''}`} onClick={() => this.setState({ chartView: "trade-volume", tradeVolume: true, liquidity: false, tradingView: false })}>
          {this.renderLang('trade_volume')}
          </button>
          <button className={`${this.state.liquidity ? 'active' : ''}`} onClick={() => this.setState({ chartView: "liquidity", liquidity: true, tradeVolume: false, tradingView: false })}>
          {this.renderLang('liquidity')}
          </button>
          <button className={`${this.state.tradingView ? 'active' : ''}`} onClick={() => this.setState({ chartView: "hloc", tradingView: true, tradeVolume: false, liquidity: false })}>
          {this.renderLang('trading_view')}
          </button>
        </div>
        <div className="chart-container">{this.renderChart()}</div>
      </div>
    );
  }
}

export default Charts;
