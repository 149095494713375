import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ChainIdToExplorer, DEFAULT_CHAIN_ID } from "../../helper/constant";

interface PropInterface {
  address: object;
}

const TokenAddress = ({ address }: PropInterface) => {
  const truncatedAdd = `${address.toString().slice(0, 10)}...`;
  return (
    <div className="address-links">
      <a
        href={`${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/token/${address}`}
        target="_blank"
        rel="noreferrer"
      >
        <span>
          {truncatedAdd} <FontAwesomeIcon icon={faExternalLinkAlt} />
        </span>
      </a>
    </div>
  );
};

export default TokenAddress;
