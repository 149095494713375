import React from "react";
import axios from "axios";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import DefaultLogo from "../../assets/img/default.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DEX_API, RoundUptoSignificant, Delimiter } from "../../helper/constant";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import Utils from "xdc3-utils";
import TokenAddress from '../common/TokenAddress';
import Skeleton from "react-loading-skeleton";
import { RemoveExpo } from "../../helper/decimals";
import { Link } from "react-router-dom";
import { SEO } from '../common/SEO';

const tokenAPI = `${DEX_API}/get-token-list`;

interface PropsInterface {
    width: any;
    height: any;
}

interface StateInterface {
    token: any;
    loading: any;
    page: any;
    pageLimit: any;
    searchPhrase: any;
    searchData: any;
    width: any;
    height: any;
}

class Token extends React.Component<PropsInterface, StateInterface> {
    static contextType = LanguageContext;
    text: any;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            token: [],
            page: 0,
            pageLimit: 5,
            searchPhrase: "",
            searchData: [],
            width: 0,
            height: 0,
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.getTokenList();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    getTokenList = () => {
        this.setState({ loading: true });
        return axios
            .post(tokenAPI)
            .then((res: any) => {
                const data = res.data.data;
                this.setState({
                    token: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    renderLang = (textName: string) => {
        const langData = textName;
        const langText = this.text ? this.text[langData] : null;
        return langText;
    };

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }



    renderTokenList() {
        let data: any = [];
        if (this.state.searchData.length !== 0) {
            data = this.state.searchData;
        } else if (
            this.state.searchPhrase !== "" &&
            this.state.searchData.length === 0
        ) {
            data = null;
        } else {
            if (this.state.page === 0) {
                data = this.state.token.slice(0, this.state.pageLimit);
            } else {
                data = this.state.token.slice(
                    this.state.pageLimit * this.state.page,
                    this.state.pageLimit + this.state.pageLimit * this.state.page
                );
            }
        }

        if (data === null) {
            return (
                <div className="token-header">
                    <div className="no-data">{this.renderLang("no_token_found")}</div>
                </div>
            );
        }
        const getToken = data.map((token: any) => {
            const _first = DEFAULT_TOKEN_LIST.filter(
                (x) => Utils.fromXdcAddress(x.address.toLowerCase()) === Utils.fromXdcAddress(token.tokenAddress.toLowerCase())
            );
            const logo = _first.length > 0 ? _first[0].logo : DefaultLogo;
            return (<div className="token-header">
                <div className="tk-image">
                    <img src={logo} alt="" /> {token.symbol === 'WXDC' ? 'XDC' : token.symbol}
                </div>
                <div className="tk-price"> {Delimiter(RoundUptoSignificant(RemoveExpo(token.price)))}</div>
                <div className="tk-address"> <TokenAddress address={Utils.toXdcAddress(token.tokenAddress)} /></div>
                <div className="tk-button"> <Link to={`/info/token/${Utils.toXdcAddress(token.tokenAddress)}`} className="btn btn-solid
                ">{this.renderLang('info')}</Link></div>
            </div>
            )
        });
        return getToken;
    }

    renderTokenListCard() {
        let data: any = [];
        if (this.state.searchData.length !== 0) {
            data = this.state.searchData;
        } else if (
            this.state.searchPhrase !== "" &&
            this.state.searchData.length === 0
        ) {
            data = null;
        } else {
            if (this.state.page === 0) {
                data = this.state.token.slice(0, this.state.pageLimit);
            } else {
                data = this.state.token.slice(
                    this.state.pageLimit * this.state.page,
                    this.state.pageLimit + this.state.pageLimit * this.state.page
                );
            }
        }

        if (data === null) {
            return (
                <div className="token-header">
                    <div className="no-data">{this.renderLang("no_token_found")}</div>
                </div>
            );
        }
        const getToken = data.map((token: any) => {
            const _first = DEFAULT_TOKEN_LIST.filter(
                (x) => Utils.fromXdcAddress(x.address.toLowerCase()) === Utils.fromXdcAddress(token.address.toLowerCase())
            );
            const logo = _first.length > 0 ? _first[0].logo : DefaultLogo;
            return (<div className="token-header-card">
                <div className="data">
                    <div className="label">
                        Symbol
                    </div>
                    <div className="img-data">
                        <img src={logo} alt="" /> {token.symbol === 'WXDC' ? 'XDC' : token.symbol}
                    </div>
                </div>
                <div className="data">
                    <div className="label">
                        Price
                    </div>
                    <div className="img-data">
                        {Delimiter(RoundUptoSignificant(RemoveExpo(token.price)))}
                    </div>
                </div>
                <div className="data">
                    <div className="label">
                        Address
                    </div>
                    <div className="img-data">
                    <TokenAddress address={Utils.toXdcAddress(token.tokenAddress)} />
                    </div>
                </div>
                <Link to={`/info/token/${Utils.toXdcAddress(token.tokenAddress)}`} className="btn btn-solid
                ">{this.renderLang('info')}</Link>
            </div>
            )
        });
        return getToken;
    }

    renderTokenView() {
        if (this.state.width >= 720) {
            return (<><div className="token-titles">
                <div className="token-name">{this.text.symbol}</div>
                <div className="token-name">{this.text.price}</div>
                <div className="token-name">{this.text.address}</div>
                <div className="token-name">&nbsp;&nbsp;&nbsp;</div>
            </div>
                {this.renderTokenList()}
            </>)
        } else {
            return this.renderTokenListCard();
        }
    }

    handleSearch(e: any) {
        const search = e.target.value.toUpperCase();
        const tokenData = this.state.token;
        if (search !== "") {
            const data = tokenData.filter((d: any) => {
                return d.symbol.includes(search);
            });
            this.setState({ searchData: data, searchPhrase: search });
        }
        return this.setState({ searchPhrase: search });

    }

    renderPageButton(page: number) {
        let className = "pg-button";
        if (page <= 0) {
            className += " active";
        }

        const activePage = this.state.page + 1;
        if (page === activePage) {
            className += " active";
        }
        return (
            <button
                key={`pg-button-${page} `}
                className={className}
                onClick={() => this.setState({ page: page - 1 })}
            >
                {page}
            </button>
        );
    }

    renderPrevButton() {
        let className = "pg-button";
        if (this.state.page <= 0) {
            className += " disabled";
        }
        return (
            <button
                key={`pg-button-prev`}
                className={className}
                onClick={() => this.setState({ page: this.state.page - 1 })}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        );
    }

    renderNextButton() {
        const pageCount = Math.ceil(
            this.state.token.length / this.state.pageLimit
        );

        let className = "pg-button";
        if (this.state.page >= pageCount - 1) {
            className += " disabled";
        }
        return (
            <button
                key={`pg-button-Next`}
                className={className}
                onClick={() => this.setState({ page: this.state.page + 1 })}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );
    }

    renderPagination() {
        const pageCount = Math.ceil(
            this.state.token.length / this.state.pageLimit
        );

        const first = this.renderPrevButton();
        const last = this.renderNextButton();

        if (pageCount <= 1) {
            return this.renderPageButton(1);
        } else if (pageCount < 3) {
            const btns = [];

            for (let i = 0; i < pageCount; i++) {
                btns.push(this.renderPageButton(i + 1));
            }
            return [first, ...btns, last];
        } else {
            const btns = [];
            if (this.state.page === 0) {
                btns.push(this.renderPageButton(1));
                btns.push(this.renderPageButton(2));
                btns.push(this.renderPageButton(3));
            } else if (this.state.page === this.state.token.length - 1) {
                btns.push(this.renderPageButton(this.state.token.length - 2));
                btns.push(this.renderPageButton(this.state.token.length - 1));
                btns.push(this.renderPageButton(this.state.token.length));
            } else {
                btns.push(this.renderPageButton(this.state.page));
                if (this.state.page < pageCount)
                    btns.push(this.renderPageButton(this.state.page + 1));
                if (this.state.page + 1 < pageCount)
                    btns.push(this.renderPageButton(this.state.page + 2));
            }
            return [first, ...btns, last];
        }
    }

    skeletonLoader() {
        if (this.state.width >= 720) {
            return (<div>
                <Skeleton count={3} height={80} width={"100%"} />
            </div>)
        } else {
            return (
                <div className="token-data">
                    <div className='token-header-card'>
                        <div className="data">
                            <div className="label">
                                Symbol
                            </div>
                            <div className="img-data">
                                <Skeleton height={40} width={40} />
                                <Skeleton height={20} width={"100%"} />
                            </div>
                        </div>
                        <div className="data">
                            <div className="label">
                                Price
                            </div>
                            <div className="img-data">
                                <Skeleton height={20} width={"100%"} />
                            </div>
                        </div>
                        <div className="data">
                            <div className="label">
                                Address
                            </div>
                            <div className="img-data">
                                <Skeleton height={20} width={"100%"} />
                            </div>
                        </div>
                        <div className="data">
                            <Skeleton height={30} width={"100%"} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        this.text = GetLanguage(this.context.language);

        return (
            <div className="token-wrapper">
                <SEO
                    keywords={["globiance", "globiancedex", "dex", "token list", "tokens"]}
                    title="Token List | GlobianceDEX"
                    pathSlug="token"
                    description=""
                />
                <div className="search-token">
                    <FontAwesomeIcon icon={faSearch} className="icon" />
                    <input
                        className="search-field"
                        placeholder="Search Token"
                        onChange={(e) => this.handleSearch(e)}
                    />
                </div>
                <div className="token-data-container">




                    {this.state.loading ? (
                        this.skeletonLoader()
                    )
                        :
                        (<div className={`token-data`}>
                            {this.renderTokenView()}
                        </div>)
                    }


                    {this.state.searchData.length === 0 &&
                        this.state.searchPhrase === "" ? (
                        <div className="pagination">{this.renderPagination()}</div>
                    ) : null}
                </div>
            </div>
        )
    }
}

export default Token;
