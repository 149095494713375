import { SETTINGS_NS } from "../../helper/localNS";
import * as types from "../actions/types";


interface Settings {
  slippage: number;
  deadline: number;
  acknowledgedFee: string[];
}

const initialSettings: Settings = {
  slippage: 5,
  deadline: 60,
  acknowledgedFee: []
}


function GetSettings(): any {
  const settingsStr = localStorage.getItem(SETTINGS_NS)

  if (!settingsStr) return initialSettings
  return {
    ...initialSettings, ...JSON.parse(settingsStr)
  }
}

const initialState = GetSettings()

interface Payload {
  payload: any;
  type: string;
}

const SettingsReducer = (state = initialState, payload: Payload): Settings => {
  switch (payload.type) {
    case types.SETTINGS_CHANGED: {
      const updated = { ...state, ...payload.payload }
      localStorage.setItem(SETTINGS_NS, JSON.stringify(updated))
      return updated
    }
    case types.ACKNOWLEDGE_FEE: {
      const updated: Settings = { ...state };

      if (!updated.acknowledgedFee.includes(payload.payload)) {
        updated.acknowledgedFee.push(payload.payload);
      }
      localStorage.setItem(SETTINGS_NS, JSON.stringify(updated))
      return updated
    }
    default: return state
  }
}


export default SettingsReducer
