import { useState, useContext } from "react";

import { Theme, Token } from "../../types";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";

interface PropsInterface {
  token: Token;
  theme: Theme;
  cb: (token: Token, dontShowAgain: boolean) => void;
}

function AcknowledgeFee({ token, cb, theme }: PropsInterface) {

  const language = useContext(LanguageContext)
  const text:any = GetLanguage(language.language)

  const [dontShowAgain, setdontShowAgain] = useState(false);

  return (
    <div className={`acknowledgeFee ${theme}-theme`}>
      <div className="acknowledgeFee__text">
        {text.acknowledge} {token.symbol} {text.acknowledge_1}
      </div>

      <div className="acknowledgeFee__note">
        <input
          type="checkbox"
          checked={dontShowAgain}
          onChange={(e) => {
            setdontShowAgain(e.target.checked);
          }}
        />{" "}
       {text.acknowledge_dont_show}
      </div>

      <button onClick={() => cb(token, dontShowAgain)} className="btn adjust-btn">
        {text.adjust}
      </button>
    </div>
  );
}

export default AcknowledgeFee;
