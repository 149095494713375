import { useContext } from "react";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";

export const ChartLoader = () => {

  const language = useContext(LanguageContext)
  const text: any = GetLanguage(language.language)

  return (
    <div>
      <div className="chartLoading">
        <div className="chartLoading-1"></div>
        <div className="chartLoading-2"></div>
        <div className="chartLoading-3"></div>
        <div className="chartLoading-4"></div>
      </div>
      <div className="load">{text.loading}</div>
    </div>
  )
}
