import React from "react";

interface PropsInterface {
  backImage: any;
  frontImage: any;
}

class PairLogo extends React.Component<PropsInterface> {
  render() {
    return (
      <div>
        <div className="coin-wrapper">
          <div className="image-top ">
            <img src={this.props.backImage} alt="$" />
          </div>
          <div className="image-bottom">
            <img src={this.props.frontImage} alt="$" />
          </div>
        </div>
      </div>
    );
  }
}

export default PairLogo;
