import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { Theme } from "../../types";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { DEX_API, ChartDateFilter } from "../../helper/constant";
import CustomToolTip from "./CustomToolTip";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import { ChartLoader } from "../common/ChartLoader";

const liquidityAPI = `${DEX_API}/get-total-liquidity-chart`;

interface StateInterface {
  loading: boolean;
  data: any[];
  activeDay: string;
}

interface PropsInterface extends actions.ActionsInterface {
  theme: Theme;
}


class LiquidityChart extends React.Component<PropsInterface, StateInterface> {
  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      activeDay: '1W'
    };
  }

  componentDidMount() {
    const DateVal = new Date();
    DateVal.setTime(DateVal.getTime() - 7 * 24 * 60 * 60 * 1000);
    const currentDate = DateVal.toISOString();

    this.getLiquidityChart(currentDate);
  }



  getLiquidityChart = (fromDate: string) => {
    this.setState({ loading: true });
    return axios
      .post(liquidityAPI, {
        from: fromDate,
      })
      .then((res: any) => {
        const data = res.data.data;
        this.setState({
          data: data.reverse(),
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  switchLiquidityDay = (days: string) => {
    const newDate = new Date();
    if (days === "1W") {
      newDate.setTime(newDate.getTime() - 7 * 24 * 60 * 60 * 1000);
      const nextDate = newDate.toISOString();
      this.setState({ activeDay: days });
      this.getLiquidityChart(nextDate);
    } else if (days === "1M") {
      newDate.setMonth(newDate.getMonth() - 1);
      const nextDate = newDate.toISOString();
      this.setState({ activeDay: days });
      this.getLiquidityChart(nextDate);
    } else if (days === "ALL") {
      newDate.setMonth(newDate.getMonth() - 24);
      const nextDate = newDate.toISOString();
      this.setState({ activeDay: days });
      this.getLiquidityChart(nextDate);
    }
  };

  renderLang = (textName: string) => {
    const langData = textName;
    const  langText = this.text ?  this.text[langData] : ""
    return langText;
  }

  render() {
    let colorFillLight: string, stroke: string;
    if (this.props.theme === "light") {
      colorFillLight = "#00b0f9";
      stroke = "#0fa9d2";

    }else if(this.props.theme === "dark"){
      colorFillLight = "#C38FFF";
      stroke = "#919191";
    } else {
      colorFillLight = "#f85797";
      stroke = "#a4124d";
    }

    this.text = GetLanguage(this.context.language);

    return (
      <div className="chart">
        <h3>{this.renderLang('liquidity')}</h3>
        <div className="chartData">
          <div className="day-filter">
            {ChartDateFilter.map((day, index) => (
              <div
                key={index}
                className={`day ${this.state.activeDay === day ? 'active' : null}`}
                onClick={() => this.switchLiquidityDay(day)}
              >
                {day}
              </div>
            ))}
          </div>
          {this.state.loading ?
            (
              <ChartLoader/>
            )
            :
            (
              <ResponsiveContainer
                width="100%"
                height="100%"
              // margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
              // stroke="#0fa9d2"
              >
                <AreaChart
                  data={this.state.data}
                  margin={{
                    right: 0,
                    bottom: 0,
                    left: 0,
                    top: 60,
                  }}
                >

                  <XAxis
                    dataKey="day"
                    tick={false}
                  // animationEasing={"linear"}
                  />
                  {/* <YAxis tick={true} /> */}
                  <Tooltip
                    wrapperStyle={{
                      visibility: "visible",
                      fontSize: "22px",
                      fontWeight: 600,
                    }}
                    cursor={true}
                    content={<CustomToolTip chartType="liquidity" />}
                    // labelFormatter={function(value) {
                    //   return  value;
                    // }}
                    position={{ x: 10, y: -70 }}
                    active={true}
                  />
                  <Area
                    type="monotone"
                    dataKey="totalLiquidity"
                    stroke={stroke}
                    fill={colorFillLight}
                  />
                </AreaChart>
              </ResponsiveContainer>)
          }
        </div>
      </div >
    );
  }
}
function mapStateToProps({ theme }: { theme: Theme }) {
  return { theme };
}

export default connect(mapStateToProps, actions)(LiquidityChart);
