import React from "react";
import { Language } from "../helper/languages";

export const LanguageContext = React.createContext<{
  language: Language;
  updateLanguage: (language: Language) => void;
}>({
  language: "EN",
  // tslint:disable-next-line:no-empty
  updateLanguage: () => {},
});
