import { ROUND_DECIMALS_PAIR } from "../../../helper/decimals";
import HistoryProvider from "./historyProvider";

const supportedResolutions = [
  "1",
  "3",
  "5",
  "15",
  "30",
  "60",
  "120",
  "240",
  "1D",
  "1W",
  "M",
];

const config = {
  supported_resolutions: supportedResolutions,
};

const TradingViewDatFeed = (address) => {
  const historyProvider = HistoryProvider(address);

  return {
    onReady: (cb) => {
      setTimeout(() => cb(config), 0);
    },
    searchSymbols: (
      userInput,
      exchange,
      symbolType,
      onResultReadyCallback
    ) => {},
    resolveSymbol: (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback
    ) => {
      var symbolStub = {
        name: symbolName,
        description: "",
        type: "crypto",
        session: "24x7",
        timezone: "UTC",
        ticker: symbolName,
        minmov: 1,
        pricescale: Math.pow(10, ROUND_DECIMALS_PAIR[symbolName]?.[1] || 5),
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        supported_resolution: supportedResolutions,
        volume_precision: 2,
        data_status: "streaming",
        has_empty_bars: true,
      };

      setTimeout(function () {
        onSymbolResolvedCallback(symbolStub);
      }, 0);
    },
    getBars: function (
      symbolInfo,
      resolution,
      from,
      to,
      onHistoryCallback,
      onErrorCallback,
      firstDataRequest
    ) {
      historyProvider
        .getBars(symbolInfo, resolution, from, to, firstDataRequest)
        .then((bars) => {
          if (bars.length > 0) {
            onHistoryCallback(bars, { noData: false });
          } else {
            onHistoryCallback(bars, { noData: true });
          }
        })
        .catch((err) => {
          onErrorCallback(err);
        });
    },

    calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
      return resolution < 30
        ? { resolutionBack: "D", intervalBack: "1" }
        : undefined;
    },
    getMarks: (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution
    ) => {},
    getTimeScaleMarks: (
      symbolInfo,
      startDate,
      endDate,
      onDataCallback,
      resolution
    ) => {},
    getServerTime: (cb) => {},
  };
};

export default TradingViewDatFeed;
