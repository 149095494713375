import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { AreaChart, Area, Bar, BarChart, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { Theme } from "../../types";
import { DEX_API, USDDecimals, FormatTime, GenerateTxLink, Delimiter, RoundUptoSignificant } from "../../helper/constant";
import { FromDecimals, RemoveExpo, USD_DECIMALS } from "../../helper/decimals";
import ToolTipInfo from "./ToolTipInfo";
import Skeleton from "react-loading-skeleton";
import Utils from "xdc3-utils";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import DefaultLogo from "../../assets/img/default.png";
// import PriceCharts from "./PriceCharts";
import TokenAddress from "../common/TokenAddress";
import { ChartLoader } from "../common/ChartLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronLeft, faChevronRight, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import AccountAddress from './../common/AccountAddress';
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import BootstrapTable from "react-bootstrap-table-next";
import {SEO} from '../common/SEO';
import { CompareAddressValue } from "../../helper/addresses";
import _ from 'lodash';
interface PropsInterface extends actions.ActionsInterface, RouteComponentProps<{ token: string }> {
    theme: Theme;
}
interface StateInterface {
    loading: boolean;
    liquidity: any[];
    volume: any[];
    chartType: string;
    token: string;
    tokenInfoData: any;
    tokenMarket: any;
    tokenTransaction: any;
    limit: number;
    page: number;
    count: number;
    filteredData: any;
    focusBar: any;
    mouseLeave: any;
    statsLoader: any;
    windowWidth: any;
    chartDataSwitch: any;
    tableLoader:boolean;
    marketTableLoader:boolean;
}
const liquidityAPI = `${DEX_API}/get-token-liquidity-chart`;
const volumeAPI = `${DEX_API}/get-token-volume-chart`;
const tokenInfoAPI = `${DEX_API}/get-token-info`;
const tokenMarketAPI = `${DEX_API}/get-token-market`;
const tokenTransactionAPI = `${DEX_API}/get-token-transactions`;

const DataTableSpinner = () => <Skeleton count={3} height={60} />;

const TokenMarket = [
    { dataField: "name", text: "Name" },
    { dataField: "liquidity", text: "Liquidity"},
    { dataField: "trades", text: "Trades"},
    { dataField: "trades7d", text: "Trades 7D (AVG)" },
    { dataField: "trades1m", text: "Trades 1M (AVG)" },
    { dataField: "action", text:"Actions"}
];


class TokenInfo extends React.Component<PropsInterface, StateInterface> {
    ONE_DAY: number = 86400 * 1000;
    limit: number = 30;
    token: string;
    static contextType = LanguageContext;
    text: any;

    constructor(props: PropsInterface) {
        super(props);
        this.token = this.props.match.params.token;
        this.state = {
            loading: true,
            statsLoader: true,
            liquidity: [],
            volume: [],
            chartType: "liquidity",
            token: '',
            tokenInfoData: '',
            tokenMarket: [],
            tokenTransaction: [],
            filteredData: ["Burn", "Swap", "Mint"],
            limit: 10,
            page: 0,
            count: 0,
            focusBar: null,
            mouseLeave: true,
            windowWidth: window.innerWidth,
            chartDataSwitch: false,
            tableLoader:true,
            marketTableLoader:true
        };
    }

    componentDidMount() {
        const fromTs = Date.now() - this.limit * this.ONE_DAY;
        const monthBack = new Date(fromTs);
        this.getTokenInfo(this.token);
        this.getLiquidityChart(monthBack, this.token);
        this.getVolumeChart(monthBack, this.token);
        this.getTokenMarket(this.token);
        this.getTransactions();
        window.addEventListener("resize", this.handleResize);
    }

    componentDidUpdate(prevProps: PropsInterface, prevState: StateInterface) {
        if (this.state.page !== prevState.page || this.state.filteredData !== prevState.filteredData) {
            this.getTransactions();
        }
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
    }

    getLiquidityChart = (fromDate: any, address: string) => {
        this.setState({ loading: true });
        return axios
            .post(liquidityAPI, {
                from: fromDate,
                tokenAddress: address
            })
            .then((res: any) => {
                const data = res.data.data;
                this.setState({
                    liquidity: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getVolumeChart = (fromDate: any, address: string) => {
        this.setState({ loading: true });
        return axios
            .post(volumeAPI, {
                from: fromDate,
                tokenAddress: address
            })
            .then((res: any) => {
                const data = res.data.data;
                this.setState({
                    volume: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    getTokenInfo = (address: string) => {
        this.setState({ loading: true });
        return axios
            .post(tokenInfoAPI, {
                tokenAddress: address
            })
            .then((res: any) => {
                const data = res.data.data;
                this.setState({
                    tokenInfoData: data,
                });
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ statsLoader: false });
            });
    }

    getTokenMarket = (address: string) => {
        this.setState({ marketTableLoader: true });
        return axios
            .post(tokenMarketAPI, {
                tokenAddress: address
            })
            .then((res: any) => {
                const data = res.data.data;
                const marketData = [];
                for (const tokenMarket of data) {
                    const _first = DEFAULT_TOKEN_LIST.filter(
                        (x) => CompareAddressValue(x.address, tokenMarket.first.address)
                    );
                    const _second = DEFAULT_TOKEN_LIST.filter(
                        (x) => CompareAddressValue(x.address , tokenMarket.second.address)
                    );
                    const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
                    const second = _second.length > 0 ? _second[0].logo : DefaultLogo;
                    const symbols = [first, second];
                    let liquidityUSD, tradeUSD1D, tradeUSD7D, tradeUSD1M;
                    if (tokenMarket.first.symbol === this.state.tokenInfoData.symbol) {
                        liquidityUSD = tokenMarket.liquidity.token0USD;
                        tradeUSD1D = tokenMarket.trades.total0USD
                        tradeUSD7D = tokenMarket.trades.last7Token0USD
                        tradeUSD1M = tokenMarket.trades.lastMonthToken0USD
                    } else {
                        liquidityUSD = tokenMarket.liquidity.token1USD;
                        tradeUSD1D = tokenMarket.trades.total1USD
                        tradeUSD7D = tokenMarket.trades.last7Token1USD
                        tradeUSD1M = tokenMarket.trades.lastMonthToken1USD
                    }
                    if(tokenMarket.first.symbol === 'WXDC'){
                        tokenMarket.first.symbol = 'XDC';
                    }else if(tokenMarket.second.symbol === 'WXDC'){
                        tokenMarket.second.symbol = 'XDC';
                    }
                    const temp = {
                        first: tokenMarket.first.symbol,
                        second: tokenMarket.second.symbol,
                        firstAddress: tokenMarket.first.address,
                        secondAddress: tokenMarket.second.address,
                        address: tokenMarket.address,
                        liquidity: parseFloat(liquidityUSD.toFixed(USDDecimals)),
                        trades1D: parseFloat(tradeUSD1D.toFixed(USDDecimals)),
                        trades7D: parseFloat(tradeUSD7D.toFixed(USDDecimals)),
                        trades1M: parseFloat(tradeUSD1M.toFixed(USDDecimals)),
                        symbolsImg: symbols
                    };
                    marketData.push(temp);
                }

                marketData.sort((a,b) => (a.liquidity > b.liquidity ? -1:1))
                this.setState({
                    tokenMarket: marketData
                })
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ marketTableLoader: false });
            });
    }

    getTransactions = () => {
        return axios
            .post<any>(tokenTransactionAPI, {
                tokenAddress: this.token,
                limit: this.state.limit,
                page: this.state.page,
                events: this.state.filteredData
            })
            .then((res: any) => {
                const data = res.data.data;
                this.setState({
                    tokenTransaction: data,
                    count: res.data.count,

                })
            })
            .catch((err) => console.log(err))
            .finally(() => {
                this.setState({ loading: false,tableLoader:false  });
            });
    }


    renderCharts(colorFillLight: string, stroke: string, colorFillDark: string, labels: any, chartDataSwitch: any, labelDynamic: any) {

        if (this.state.loading) {
            return <ChartLoader />
        } else {
            // if (this.state.chartType === 'price') {
            //     return (
            //         <PriceCharts tokenAddress={this.token} />
            //     )
            // } else
            if (this.state.chartType === 'volume') {
                this.state.volume.forEach((data: any) => {
                    if ((data.total.toFixed(0).toString().length > 11 || data.total.toFixed(0).length > 11) && this.state.windowWidth > 600) {
                        labelDynamic = 200;
                    }
                })
                return (
                    <ResponsiveContainer
                        width="100%"
                        height="90%"
                    >
                        <BarChart
                            data={this.state.volume ? this.state.volume : undefined}
                            barGap={0}
                            barCategoryGap={0}
                            maxBarSize={20}
                            margin={{
                                right: 20,
                                bottom: 0,
                                left: 20,
                                top: 120,
                            }}
                            onMouseMove={(state: any) => {
                                if (state.isTooltipActive) {
                                    this.setState({ focusBar: state.activeTooltipIndex });
                                    this.setState({ mouseLeave: false });
                                } else {
                                    this.setState({ focusBar: null });
                                    this.setState({ mouseLeave: true });
                                }
                            }}
                        >
                            <XAxis
                                dataKey="_id"
                                tick={labels}
                                interval="preserveEnd"
                                stroke={colorFillLight}
                            />

                            <YAxis stroke={colorFillLight} orientation="right" tick={labels} width={labelDynamic} />

                            <Tooltip
                                wrapperStyle={{
                                    visibility: "visible",
                                    fontSize: "22px",
                                    fontWeight: 600,
                                }}
                                content={<ToolTipInfo chartType="volume" chartDataSwitch={chartDataSwitch} />}
                                cursor={false}
                                position={{ x: 20, y: 20 }}
                                active={true}
                            />
                            {this.state.chartDataSwitch ? (
                                <Bar
                                    dataKey="totalUSD"
                                    type="monotone"
                                    stroke={stroke}
                                    fill={colorFillLight}
                                    minPointSize={10}
                                >
                                    {this.state.volume?.map((entry, index) => (
                                        <Cell
                                            key={index}
                                            fill={
                                                this.state.focusBar === index || this.state.mouseLeave
                                                    ? colorFillLight
                                                    : colorFillDark
                                            }
                                        />
                                    ))}
                                </Bar>
                            ) : (
                                <Bar
                                    dataKey="total"
                                    type="monotone"
                                    stroke={stroke}
                                    fill={colorFillLight}
                                    minPointSize={10}
                                >
                                    {this.state.volume?.map((entry, index) => (
                                        <Cell
                                            key={index}
                                            fill={
                                                this.state.focusBar === index || this.state.mouseLeave
                                                    ? colorFillLight
                                                    : colorFillDark
                                            }
                                        />
                                    ))}
                                </Bar>
                            )}

                        </BarChart>
                    </ResponsiveContainer>
                );
            } else {
                this.state.volume.forEach((data: any) => {
                    if ((data.total.toFixed(0).length > 11 || data.total.toFixed(0).length > 11) && this.state.windowWidth > 600) {
                        labelDynamic = 200;
                    }
                })
                return (
                    <ResponsiveContainer
                        width="100%"
                        height="90%"
                    >
                        <AreaChart
                            data={this.state.liquidity}
                            margin={{
                                right: 20,
                                bottom: 0,
                                left: 20,
                                top: 120,
                            }}
                        >
                            <defs>
                                <linearGradient id="token1" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={colorFillLight} stopOpacity={1} />
                                    <stop offset="95%" stopColor="#FFFFFF" stopOpacity={1} />
                                </linearGradient>
                            </defs>
                            <XAxis
                                stroke={colorFillLight}
                                dataKey="_id"
                                tick={labels}
                                interval="preserveEnd"
                            />
                            <YAxis stroke={colorFillLight} yAxisId="right" orientation="right" width={labelDynamic} tick={labels} />
                            <Tooltip
                                wrapperStyle={{
                                    visibility: "visible",
                                    fontSize: "22px",
                                    fontWeight: 600,
                                }}
                                content={<ToolTipInfo chartType="liquidity" chartDataSwitch={chartDataSwitch} />}
                                cursor={true}
                                position={{ x: 20, y: 20 }}
                                active={true}
                            />
                            {this.state.chartDataSwitch ? (

                                <Area
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="totalUSD"
                                    stroke={stroke}
                                    fill="url(#token1)"
                                />
                            ) : (
                                <Area
                                    yAxisId="right"
                                    type="monotone"
                                    dataKey="total"
                                    stroke={stroke}
                                    fill="url(#token1)"
                                />
                            )}
                        </AreaChart>
                    </ResponsiveContainer>
                );
            }
        }
    }

    renderTokenInfo() {
        const token = this.state.tokenInfoData;
        if (this.state.statsLoader) {
            return (
                <div className="token-stats">
                    <div className="token-data">
                        <div className="token-img"><Skeleton width={50} height={40} borderRadius={10} /></div>
                        <div className="token-label">{this.renderLang('symbol')} </div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('rank')} </div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('name')}</div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('decimals')} </div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('liquidity')} </div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('volume')} </div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('address')} </div>
                        <div className="token-amt"><Skeleton width={200} height={10} borderRadius={10} /></div>
                    </div>
                </div>
            )
        } else {
            const first = DEFAULT_TOKEN_LIST.filter(
                (x) => CompareAddressValue(x.address, token.address)
            );
            const logo = first.length > 0 ? first[0].logo : DefaultLogo;
            // let tokenVol,tokenVolUSD;
            // if(token.totalVolume !== 'undefined'){
            //     tokenVol = Delimiter(parseFloat(token.totalVolume).toFixed(2));
            // }else{
            //     tokenVol = 0;
            // }

            // if(token.totalVolumeUSD !== 'undefined'){
            //     tokenVolUSD = Delimiter(parseFloat(token.totalVolumeUSD).toFixed(USD_DECIMALS));
            // }else{
            //     tokenVolUSD = 0;
            // }
            return (
                <div className="token-stats">
                    <div className="token-data">
                        <div className="token-img">
                            <img src={logo} alt={token.symbol} />
                        </div>
                        <div className="token-label">{this.renderLang('symbol')}</div>
                        <div className="token-amt">{token.symbol === 'WXDC' ? 'XDC':token.symbol}</div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('rank')}</div>
                        <div className="token-amt">#{token.rank}</div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('name')} </div>
                        <div className="token-amt">{token.symbol === 'WXDC' ? 'XDC':token.name}</div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('decimals')} </div>
                        <div className="token-amt">{token.decimals}</div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('liquidity')} </div>
                        <div className="token-amt">{Delimiter(token.totalLiquidity.toFixed(2))}</div>
                        <div className="token-amt-usd">${Delimiter(token.totalLiquidityUSD.toFixed(USD_DECIMALS))}</div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('volume')} </div>
                        <div className="token-amt">{Delimiter(parseFloat(token.totalVolume).toFixed(2)) === 'NaN' ? 0 : Delimiter(parseFloat(token.totalVolume).toFixed(2))}</div>
                        <div className="token-amt-usd">${Delimiter(parseFloat(token.totalVolumeUSD).toFixed(USD_DECIMALS)) === 'NaN' ? 0 : Delimiter(parseFloat(token.totalVolumeUSD).toFixed(USD_DECIMALS))}</div>
                    </div>
                    <div className="token-data">
                        <div className="token-label">{this.renderLang('address')}</div>
                        <div className="token-amt"><TokenAddress address={Utils.toXdcAddress(token.address)} /></div>
                    </div>
                </div>
            )
        }
    }

    handleResize = (e: any) => {
        this.setState({ windowWidth: window.innerWidth });
    };


    renderLang = (textName: string) => {
        const langData = textName;
        const langText = this.text ? this.text[langData] : ""
        return langText;
    }


    renderTransactions() {
        if (!this.state.tokenTransaction || this.state.tableLoader){
            return (
                <>
                    <tr>
                        <td colSpan={7}><Skeleton width={"100%"} height={40} /></td>
                    </tr>
                    <tr>
                        <td colSpan={7}><Skeleton width={"100%"} height={40} /></td>
                    </tr>
                    <tr>
                        <td colSpan={7}><Skeleton width={"100%"} height={40} /></td>
                    </tr>
                </>
            );
        }else if(this.state.tokenTransaction.length === 0){
            return (
                <>
                    <tr>
                        <td colSpan={7} className="text-center">No data available</td>
                    </tr>
                </>
            );
        }
        else{
            return this.state.tokenTransaction.map((tx: any, i: any) => {
                if (!tx.associated_address) tx.associated_address = ""
                let tokenAmt;

                if(tx.pair.first.symbol === 'WXDC'){
                    tx.pair.first.symbol = 'XDC';
                }else if (tx.pair.second.symbol === 'WXDC'){
                    tx.pair.second.symbol = 'XDC';
                }
                switch (tx.name) {
                    case "Swap": {


                        if (Utils.fromXdcAddress(this.token).toLowerCase() === Utils.fromXdcAddress(tx.pair.first.address).toLowerCase()) {
                            tokenAmt = RoundUptoSignificant(RemoveExpo((FromDecimals(parseFloat(tx.data.amount0In) + parseFloat(tx.data.amount0Out), tx.pair.first.decimals))));
                        } else {
                            tokenAmt = RoundUptoSignificant(RemoveExpo((FromDecimals(parseFloat(tx.data.amount1In) + parseFloat(tx.data.amount1Out), tx.pair.second.decimals))));
                        }
                        let swapName;

                        if (tx.data.amount0In !== '0' && tx.data.amount1Out !== '0') {
                            swapName = (<>
                                {tx.pair.first.symbol}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />{" "}
                                {tx.pair.second.symbol}
                            </>
                            )
                        } else if (tx.data.amount1In !== '0' && tx.data.amount0Out !== '0') {
                            swapName = (<>
                                {tx.pair.second.symbol}{" "}
                                <FontAwesomeIcon icon={faArrowRight} />{" "}
                                {tx.pair.first.symbol}
                            </>
                            )
                        } else {
                            swapName = tx.name;
                        }

                        return (
                            <tr key={`pool-tx-${i}`}>
                                <td>Swap (&nbsp;{swapName}&nbsp;)</td>
                                <td>
                                    {Delimiter(tokenAmt)}
                                </td>
                                <td>
                                    {tx.pair.first.symbol}-{tx.pair.second.symbol}
                                </td>
                                <td>
                                    <AccountAddress address={Utils.toXdcAddress(tx.pair.address)} />
                                </td>
                                <td><AccountAddress address={Utils.toXdcAddress(tx.associated_address)} /></td>
                                <td>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={GenerateTxLink(tx.tx_hash)}
                                    >
                                        {tx.tx_hash.slice(0, 10)}...<FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </a>
                                </td>
                                <td>{FormatTime(new Date(tx.timestamp))}</td>
                            </tr>
                        );
                    }
                    case "Mint": {


                        if (Utils.fromXdcAddress(this.token).toLowerCase() === Utils.fromXdcAddress(tx.pair.first.address).toLowerCase()) {
                            tokenAmt = RoundUptoSignificant(RemoveExpo((FromDecimals(parseFloat(tx.data.amount0), tx.pair.first.decimals))));
                        } else {
                            tokenAmt = RoundUptoSignificant(RemoveExpo((FromDecimals(parseFloat(tx.data.amount1), tx.pair.second.decimals))));
                        }

                        return (
                            <tr key={`pool-tx-${i}`}>
                                <td>{this.renderLang('add_liquidity')}</td>
                                <td>
                                    {Delimiter(tokenAmt)}
                                </td>
                                <td>
                                    {tx.pair.first.symbol}-{tx.pair.second.symbol}
                                </td>
                                <td>
                                    <AccountAddress address={Utils.toXdcAddress(tx.pair.address)} />
                                </td>
                                <td><AccountAddress address={Utils.toXdcAddress(tx.associated_address)} /></td>
                                <td>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={GenerateTxLink(tx.tx_hash)}
                                    >
                                        {tx.tx_hash.slice(0, 10)}...<FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </a>
                                </td>
                                <td>{FormatTime(new Date(tx.timestamp))}</td>
                            </tr>
                        );
                    }
                    case "Burn": {

                        if (Utils.fromXdcAddress(this.token).toLowerCase() === Utils.fromXdcAddress(tx.pair.first.address).toLowerCase()) {
                            tokenAmt = RoundUptoSignificant(RemoveExpo((FromDecimals(parseFloat(tx.data.amount0), tx.pair.first.decimals))));
                        } else {
                            tokenAmt = RoundUptoSignificant(RemoveExpo((FromDecimals(parseFloat(tx.data.amount1), tx.pair.second.decimals))));
                        }

                        return (
                            <tr key={`pool-tx-${i}`}>
                                <td>{this.renderLang('remove_liquidity')}</td>
                                <td>
                                    {Delimiter(tokenAmt)}
                                </td>
                                <td>
                                    {tx.pair.first.symbol}-{tx.pair.second.symbol}
                                </td>
                                <td>
                                    <AccountAddress address={Utils.toXdcAddress(tx.pair.address)} />
                                </td>

                                <td><AccountAddress address={Utils.toXdcAddress(tx.associated_address)} /></td>
                                <td>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={GenerateTxLink(tx.tx_hash)}
                                    >
                                        {tx.tx_hash.slice(0, 10)}...<FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </a>
                                </td>
                                <td>{FormatTime(new Date(tx.timestamp))}</td>
                            </tr>
                        );
                    }
                    default:
                        return <></>;
                }
            });
        }
    }

    renderPageButton(page: number) {
        let className = "pg-button";
        if (page <= 0) {
            className += " active";
        }

        const activePage = this.state.page + 1;
        if (page === activePage) {
            className += " active";
        }
        return (
            <button
                key={`pg-button-${page} `}
                className={className}
                onClick={() => this.setState({ page: page - 1 })}
            >
                {page}
            </button>
        );
    }

    renderPrevButton() {
        let className = "pg-button";
        if (this.state.page <= 0) {
            className += " disabled";
        }
        return (
            <button
                key={`pg-button-prev`}
                className={className}
                onClick={() => this.setState({ page: this.state.page - 1 })}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        );
    }

    renderNextButton() {
        const pageCount = Math.ceil(this.state.count / this.state.limit);

        let className = "pg-button";
        if (this.state.page >= pageCount - 1) {
            className += " disabled";
        }
        return (
            <button
                key={`pg-button-Next`}
                className={className}
                onClick={() => this.setState({ page: this.state.page + 1 })}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );
    }

    renderPagination() {
        const pageCount = Math.ceil(this.state.count / this.state.limit);

        const first = this.renderPrevButton();
        const last = this.renderNextButton();

        if (pageCount <= 1) {
            return this.renderPageButton(1);
        } else if (pageCount < 3) {
            const btns = [];

            for (let i = 0; i < pageCount; i++) {
                btns.push(this.renderPageButton(i + 1));
            }
            return [first, ...btns, last];
        } else {
            const btns = [];
            if (this.state.page === 0) {
                btns.push(this.renderPageButton(1));
                btns.push(this.renderPageButton(2));
                btns.push(this.renderPageButton(3));
            } else if (this.state.page === this.state.count - 1) {
                btns.push(this.renderPageButton(this.state.count - 2));
                btns.push(this.renderPageButton(this.state.count - 1));
                btns.push(this.renderPageButton(this.state.count));
            } else {
                btns.push(this.renderPageButton(this.state.page));
                if (this.state.page < pageCount)
                    btns.push(this.renderPageButton(this.state.page + 1));
                if (this.state.page + 1 < pageCount)
                    btns.push(this.renderPageButton(this.state.page + 2));
            }
            return [first, ...btns, last];
        }
    }


    filterTransaction = (e: any) => {
        const filterValue = e.target.value;
        if (filterValue === 'add') {
            this.setState({
                filteredData: ["Mint"],
                page: 0,
                tableLoader:true,
            })
        } else if (filterValue === 'remove') {
            this.setState({
                filteredData: ["Burn"],
                page: 0,
                tableLoader:true,
            })
        } else if (filterValue === 'swap') {
            this.setState({
                filteredData: ["Swap"],
                page: 0,
                tableLoader:true,
            })
        } else {
            this.setState({
                filteredData: ["Swap", "Burn", "Mint"],
                tableLoader:true,
            })
        }
    }

    toogleChartData = () => {
        this.setState({
            chartDataSwitch: !this.state.chartDataSwitch
        })
    }

    render() {
        this.text = GetLanguage(this.context.language);
        let colorFillLight: string, stroke: string, colorFillDark: string;
        if (this.props.theme === "light") {
            colorFillLight = "#00b0f9";
            colorFillDark = "#2b5876";
            stroke = "#0fa9d2";

        } else if (this.props.theme === "dark") {
            colorFillLight = "#C38FFF";
            colorFillDark = "#666666";
            stroke = "#919191";
        } else {
            colorFillLight = "#f85797";
            colorFillDark = "#a4124d";
            stroke = "#a4124d";
        }

        const tokenMarketDataTable = this.state.tokenMarket;
        const tokenMarketDataTableData = [];
        if (tokenMarketDataTable) {
            for (const data of tokenMarketDataTable) {
                const tkMarket = {
                    id: `market-${data.first}-${data.second}`,
                    name: (
                        <div className="img-group" key={data.address} >
                            <Link to={`/info/pool/${Utils.toXdcAddress(data.address)}`}>
                                <img src={data.symbolsImg[0]} alt="" />
                                <img className="img2" src={data.symbolsImg[1]} alt="" />{" "}
                                {data.first}-{data.second}
                            </Link>
                        </div>
                    ),
                    liquidity: "$" + Delimiter(data.liquidity),
                    trades: "$" + Delimiter(data.trades1D),
                    trades7d: "$" + Delimiter(data.trades7D),
                    trades1m: "$" + Delimiter(data.trades1M),
                    action: (<><Link
                      className="btn btn-outline-small"
                      to={`/add?outputCurrency=${data.second==='XDC'||data.second==='WXDC'?data.second:Utils.toXdcAddress(data.secondAddress)}&inputCurrency=${data.first==='XDC'||data.first==='WXDC'?data.first:Utils.toXdcAddress(data.firstAddress)}`}
                    >
                      {this.text.add_liquidity}
                    </Link><Link
                      className="btn btn-outline-small"
                      to={`/swap?outputCurrency=${data.second==='XDC'||data.second==='WXDC'?data.second:Utils.toXdcAddress(data.secondAddress)}&inputCurrency=${data.first==='XDC'||data.first==='WXDC'?data.first:Utils.toXdcAddress(data.firstAddress)}`}
                    >
                      {this.text.swap}
                    </Link></>)
                };
                tokenMarketDataTableData.push(tkMarket);
            }
        }



        let disbaled, labelDynamic = 100, labels = true;
        this.state.liquidity.map((data: any) => {
            if (parseFloat(data.totalUSD) === 0) {
                disbaled = true;
                return disbaled;
            }
            return null;
        })
        this.state.volume.map((data: any) => {
            if (parseFloat(data.totalUSD) === 0) {
                disbaled = true;
                return disbaled;
            }
            return null;
        })

        if (this.state.windowWidth <= 600) {
            labels = false;
            labelDynamic = 0;
        }


        return (
            <div className="token-info-wrapper">
                <SEO
                    keywords={["globiance", "globiancedex", "dex", "token info", "token"]}
                    title={`${this.state.statsLoader ? '' : this.state.tokenInfoData.symbol === 'WXDC' ? 'XDC' : this.state.tokenInfoData.symbol} Token Market & Transaction | GlobianceDEX`}
                    pathSlug="info/token"
                    description="Get detailed information about GBEX token market, transaction, liquidity & trades in GlobianceDEX."
                />
                <div className="token-container">
                    {this.renderTokenInfo()}
                    <div className="chart-stats">
                        <div className="chart-button">
                            <button className={`ct-btn ${this.state.chartType === 'liquidity' && 'active-btn'}`} onClick={() => this.setState({ chartType: "liquidity" })}>{this.renderLang('liquidity')}</button>
                            <button className={`ct-btn ${this.state.chartType === 'volume' && 'active-btn'}`} onClick={() => this.setState({ chartType: "volume" })}>{this.renderLang('volume')}</button>
                            {/* <button className={`ct-btn ${this.state.chartType === 'price' && 'active-btn'}`} onClick={() => this.setState({ chartType: "price" })}>{this.renderLang('price')}</button> */}
                        </div>
                        <div className="charts">
                            <div className="chart-liqduidity-legend" onChange={() => this.toogleChartData()}>
                                <label className="switch"> <span>{this.renderLang('token_amt')}</span>
                                    <input id="switch" type="radio" name="amt" defaultChecked />
                                </label>
                                {disbaled ? null :
                                    (<label className="switch1"><span>{this.renderLang('total_usd')}</span>
                                        <input id="switch1" type="radio" name="amt" />
                                    </label>)
                                }
                            </div>
                            {this.renderCharts(colorFillLight, stroke, colorFillDark, labels, this.state.chartDataSwitch, labelDynamic)}
                        </div>
                    </div>
                </div>
                <div className="table-container">
                    <div className="table">
                        <h2>{this.text.token_market}</h2>
                        <BootstrapTable
                            keyField="id"
                            data={tokenMarketDataTableData}
                            columns={TokenMarket}
                            defaultSortDirection="desc"
                            noDataIndication={this.state.marketTableLoader ? <DataTableSpinner/> :  <h6>No data available</h6> }
                        />
                        <div className="tx-table">
                            <h2>{this.text.token_transaction}</h2>
                            <div className="tx-table__title">
                                <div className="filter" onChange={this.filterTransaction.bind(this)}>
                                    <div className="filter-label">
                                        <label>
                                            <input
                                                type="radio"
                                                value="all"
                                                name="filter" defaultChecked
                                            />
                                            <span>{this.text.all}</span>
                                        </label>
                                    </div>
                                    <div className="filter-label">
                                        <label>
                                            <input
                                                type="radio"
                                                value="swap"
                                                name="filter"
                                            />
                                            <span>{this.text.swap}</span></label>
                                    </div>
                                    <div className="filter-label">
                                        <label>
                                            <input
                                                type="radio"
                                                value="add"
                                                name="filter"
                                            />
                                            <span>{this.text.add}</span></label>
                                    </div>
                                    <div className="filter-label">
                                        <label>
                                            <input
                                                type="radio"
                                                value="remove"
                                                name="filter"
                                            />
                                            <span>{this.text.remove}</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="tx-table__table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{this.text.eventName}</th>
                                            <th>{this.text.token_amt}</th>
                                            <th>{this.text.pair_name}</th>
                                            <th>{this.text.pair_address}</th>
                                            <th>{this.text.account}</th>
                                            <th>{this.text.tx_hash}</th>
                                            <th>{this.text.time}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderTransactions()}</tbody>
                                </table>
                                <div className="tx-table__table__pagination">{this.renderPagination()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({ theme }: { theme: Theme }) {
    return { theme };
}
export default connect(mapStateToProps, actions)(TokenInfo);
