import { useContext } from "react";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../../Context/Language";

interface PropsInterface {
  title: string;
  description: string;
  pathSlug: string;
  keywords: string[];
}

export const SEO = ({ title, description, pathSlug, keywords }: PropsInterface) => {
  const lang = useContext(LanguageContext);
  const url = `https://dex.globiance.com/${pathSlug}`;
  return (
    <Helmet
      htmlAttributes={{ lang: lang.language }}
      title={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "keywords",
          content: keywords.join(),
        },
      ]}
      link={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
      defer={false}
    />
  );
};

