import React from "react";
import axios from "axios";
import { DEX_API, FormatTime, GenerateTxLink, Delimiter } from "../../helper/constant";
import { PairTransaction } from "../../types";
import { FromDecimals } from "../../helper/decimals";
import Utils from "xdc3-utils";
import AccountAddress from './../common/AccountAddress';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faExternalLinkAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import Skeleton from "react-loading-skeleton";

interface PropsInterface {
  pair: string;
  decimals0: number | undefined;
  decimals1: number | undefined;
  tokenSymbol0: string | undefined;
  tokenSymbol1: string | undefined;
}

interface StateInterface {
  limit: number;
  page: number;
  transactions: PairTransaction[];
  count: number;
  loading: boolean;
  filteredData: any;
  actionData: any;
  tableLoader:boolean;
}

class Transactions extends React.Component<PropsInterface, StateInterface> {
  ONE_DAY: number = 86400 * 1000;

  static contextType = LanguageContext;
  text: any;

  constructor(props: PropsInterface) {
    super(props);

    this.state = {
      limit: 10,
      page: 0,
      transactions: [],
      count: 0,
      loading: true,
      filteredData: ["Burn", "Swap", "Mint"],
      actionData: null,
      tableLoader:true
    };
  }

  componentDidMount() {
    this.getPairTransactions();
    this.setState({
      loading: false
    })
  }

  componentDidUpdate(prevProps: PropsInterface, prevState: StateInterface) {
    if (this.state.page !== prevState.page || this.state.filteredData !== prevState.filteredData) {
      this.getPairTransactions();
    }
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : ""
    return langText;
  }

  getPairTransactions() {
    axios
      .post<any>(`${DEX_API}/pair-transactions`, {
        pair: this.props.pair,
        limit: this.state.limit,
        page: this.state.page,
        events: this.state.filteredData
      })
      .then((resp) => {
        if (resp && resp.data && resp.data.data) {
          if(resp.data.pair.first.symbol === 'WXDC'){
            resp.data.pair.first.symbol = 'XDC';
          }else if(resp.data.pair.second.symbol === 'WXDC'){
            resp.data.pair.second.symbol = 'XDC';
          }
          this.setState({
            transactions: resp.data.data,
            count: resp.data.count,
            actionData: resp.data.pair,
            tableLoader:false
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  renderTransactions() {
    if (!this.state.transactions || this.state.tableLoader){
      return (
        <>
          <tr>
            <td colSpan={6}><Skeleton width={"100%"} height={40} /></td>
          </tr>
          <tr>
            <td colSpan={6}><Skeleton width={"100%"} height={40} /></td>
          </tr>
          <tr>
            <td colSpan={6}><Skeleton width={"100%"} height={40} /></td>
          </tr>
        </>
      );
    }else if(this.state.transactions.length === 0){
      return (
        <>
          <tr>
            <td colSpan={6} className="text-center">No data available</td>
          </tr>

        </>
      );
    }else{
      return this.state.transactions.map((tx, i) => {

        if (!tx.associated_address) tx.associated_address = ""

        switch (tx.name) {
          case "Swap": {

            let swapName;
            if (tx.data.amount0In !== '0' && tx.data.amount1Out !== '0') {
              swapName = (<>Swap({" "}
                {this.state.actionData.first.symbol}{" "}
                <FontAwesomeIcon icon={faArrowRight} />{" "}
                {this.state.actionData.second.symbol} {" "})
              </>
              )
            } else if (tx.data.amount1In !== '0' && tx.data.amount0Out !== '0') {
              swapName = (<>Swap({" "}
                {this.state.actionData.second.symbol}{" "}
                <FontAwesomeIcon icon={faArrowRight} />{" "}
                {this.state.actionData.first.symbol}{" "})
              </>
              )
            } else {
              swapName = tx.name;
            }

            return (
              <tr key={`pool-tx-${i}`}>
                <td>{swapName}</td>
                <td>
                  {Delimiter((
                    parseFloat(
                      FromDecimals(tx.data.amount0In, this.props.decimals0)
                    ) +
                    parseFloat(
                      FromDecimals(tx.data.amount0Out, this.props.decimals0)
                    )
                  ).toFixed(2))}
                </td>
                <td>
                  {Delimiter((
                    parseFloat(
                      FromDecimals(tx.data.amount1In, this.props.decimals1)
                    ) +
                    parseFloat(
                      FromDecimals(tx.data.amount1Out, this.props.decimals1)
                    )
                  ).toFixed(2))}
                </td>
                <td><AccountAddress address={Utils.toXdcAddress(tx.associated_address)} /></td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={GenerateTxLink(tx.tx_hash)}
                  >
                    {tx.tx_hash.slice(0, 10)}...<FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </td>
                <td>{FormatTime(new Date(tx.timestamp))}</td>
              </tr>
            );
          }
          case "Mint": {
            return (
              <tr key={`pool-tx-${i}`}>
                <td>{this.renderLang('add_liquidity')}</td>
                <td>
                  {Delimiter(parseFloat(
                    FromDecimals(tx.data.amount0, this.props.decimals0)
                  ).toFixed(2))}
                </td>
                <td>
                  {Delimiter(parseFloat(
                    FromDecimals(tx.data.amount1, this.props.decimals1)
                  ).toFixed(2))}
                </td>
                <td><AccountAddress address={Utils.toXdcAddress(tx.associated_address)} /></td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={GenerateTxLink(tx.tx_hash)}
                  >
                    {tx.tx_hash.slice(0, 10)}...<FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </td>
                <td>{FormatTime(new Date(tx.timestamp))}</td>
              </tr>
            );
          }
          case "Burn": {
            return (
              <tr key={`pool-tx-${i}`}>
                <td>{this.renderLang('remove_liquidity')}</td>
                <td>
                  {Delimiter(parseFloat(
                    FromDecimals(tx.data.amount0, this.props.decimals0)
                  ).toFixed(2))}
                </td>
                <td>
                  {Delimiter(parseFloat(
                    FromDecimals(tx.data.amount1, this.props.decimals1)
                  ).toFixed(2))}
                </td>
                <td><AccountAddress address={Utils.toXdcAddress(tx.associated_address)} /></td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={GenerateTxLink(tx.tx_hash)}
                  >
                    {tx.tx_hash.slice(0, 10)}...<FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                </td>
                <td>{FormatTime(new Date(tx.timestamp))}</td>
              </tr>
            );
          }
          default:
            return <></>;
        }
      });
    }
  }

  renderPageButton(page: number) {
    let className = "pg-button";
    if (page <= 0) {
      className += " active";
    }

    const activePage = this.state.page + 1;
    if (page === activePage) {
      className += " active";
    }
    return (
      <button
        key={`pg-button-${page} `}
        className={className}
        onClick={() => this.setState({ page: page - 1 })}
      >
        {page}
      </button>
    );
  }

  renderPrevButton() {
    let className = "pg-button";
    if (this.state.page <= 0) {
      className += " disabled";
    }
    return (
      <button
        key={`pg-button-prev`}
        className={className}
        onClick={() => this.setState({ page: this.state.page - 1 })}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    );
  }

  renderNextButton() {
    const pageCount = Math.ceil(this.state.count / this.state.limit);

    let className = "pg-button";
    if (this.state.page >= pageCount - 1) {
      className += " disabled";
    }
    return (
      <button
        key={`pg-button-Next`}
        className={className}
        onClick={() => this.setState({ page: this.state.page + 1 })}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    );
  }

  renderPagination() {
    const pageCount = Math.ceil(this.state.count / this.state.limit);

    const first = this.renderPrevButton();
    const last = this.renderNextButton();

    if (pageCount <= 1) {
      return this.renderPageButton(1);
    } else if (pageCount < 3) {
      const btns = [];

      for (let i = 0; i < pageCount; i++) {
        btns.push(this.renderPageButton(i + 1));
      }
      return [first, ...btns, last];
    } else {
      const btns = [];
      if (this.state.page === 0) {
        btns.push(this.renderPageButton(1));
        btns.push(this.renderPageButton(2));
        btns.push(this.renderPageButton(3));
      } else if (this.state.page === this.state.count - 1) {
        btns.push(this.renderPageButton(this.state.count - 2));
        btns.push(this.renderPageButton(this.state.count - 1));
        btns.push(this.renderPageButton(this.state.count));
      } else {
        btns.push(this.renderPageButton(this.state.page));
        if (this.state.page < pageCount)
          btns.push(this.renderPageButton(this.state.page + 1));
        if (this.state.page + 1 < pageCount)
          btns.push(this.renderPageButton(this.state.page + 2));
      }
      return [first, ...btns, last];
    }
  }

  filterTransaction = (e: any) => {
    const filterValue = e.target.value;
    if (filterValue === 'add') {
      this.setState({
        filteredData: ["Mint"],
        page: 0,
        tableLoader:true,
      })
    } else if (filterValue === 'remove') {
      this.setState({
        filteredData: ["Burn"],
        page: 0,
        tableLoader:true,
      })
    } else if (filterValue === 'swap') {
      this.setState({
        filteredData: ["Swap"],
        page: 0,
        tableLoader:true,
      })
    } else {
      this.setState({
        filteredData: ["Swap", "Burn", "Mint"],
        tableLoader:true,
      })
    }
  }

  render() {
    this.text = GetLanguage(this.context.language);

    return (
      <div className="tx-table">
        <div className="tx-table__title">
          <div className="text">{this.renderLang('pool-transaction')}</div>
          <div className="filter" onChange={this.filterTransaction.bind(this)}>
            <div className="filter-label">
              <label>
                <input
                  type="radio"
                  value="all"
                  name="filter" defaultChecked
                />
                <span>{this.text.all}</span>
              </label>
            </div>
            <div className="filter-label">
              <label>
                <input
                  type="radio"
                  value="swap"
                  name="filter"
                />
                <span>{this.text.swap}</span></label>
            </div>
            <div className="filter-label">
              <label>
                <input
                  type="radio"
                  value="add"
                  name="filter"
                />
                <span>{this.text.add}</span></label>
            </div>
            <div className="filter-label">
              <label>
                <input
                  type="radio"
                  value="remove"
                  name="filter"
                />
                <span>{this.text.remove}</span></label>
            </div>
          </div>
        </div>
        <div className="tx-table__table">
          <table>
            <thead>
              <tr>
                <th>{this.renderLang('action')}</th>
                <th>{this.props.tokenSymbol0}</th>
                <th>{this.props.tokenSymbol1}</th>
                <th>{this.renderLang('account')}</th>
                <th>{this.renderLang('hash')}</th>
                <th>{this.renderLang('time')}</th>
              </tr>
            </thead>
            <tbody>{this.renderTransactions()}</tbody>
          </table>
          <div className="tx-table__table__pagination">{this.renderPagination()}</div>
        </div>
      </div>
    );
  }
}

export default Transactions;
