import React, { ReactElement } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import Utils from "xdc3-utils";
import DefaultLogo from "../../assets/img/default.png";
import {
  BalanceOfGeneral,
  GetLPFromTokens,
  GetTknFromLiquidity,
} from "../../helper/crypto";
import PairLogo from "../common/PairLogo";
import _ from "lodash";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import {
  Delimiter,
  DEX_API,
  RoundUptoSignificant
} from "../../helper/constant";
import {
  Wallet,
  PriceItem,
  Theme,
  TokenList as TokenListType,
  AssetPrice,
  AddressPrice
} from "../../types";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import { FromDecimals, ToDecimals, RemoveExpo } from "../../helper/decimals";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { ForceShowModal } from "xdc-connect";
import {SEO} from '../common/SEO';
import { CompareAddressValue } from "../../helper/addresses";

interface TokenLmt {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

interface StateInterface {
  firstLogo: any;
  secondLogo: any;
  firstBalance: any;
  secondBalance: any;
  lpEq0: any;
  lpEq1: any;
  pair: {
    lastPrice:any;
    address: string;
    first: TokenLmt;
    second: TokenLmt;
    change: {
      token0: number;
      token1: number;
    };
    changePercent: {
      token0: number;
      token1: number;
    };
    vol24: {
      token0: number;
      token1: number;
    };
    price: {
      token0: number;
      token1: number;
    };
  } | null;
  lpBalance: any;
  button: ReactElement;
  amtLoader:boolean;
}

interface PropsInterface extends RouteComponentProps<{ pair: string }> {
  wallet: Wallet;
  theme: Theme;
  tokenList: TokenListType;
  assetPrice: AssetPrice;
  addressPrice: AddressPrice;
  FetchAssetPrice: (data: PriceItem[]) => {};
}

class ManagePool extends React.Component<PropsInterface, StateInterface> {
  pair: string;

  static contextType = LanguageContext;
  text: any = GetLanguage(this.context?.language);
  debounceRate: any;

  constructor(props: PropsInterface) {
    super(props);
    this.pair = this.props.match.params.pair;

    this.state = {
      firstLogo: null,
      secondLogo: null,
      firstBalance: null,
      secondBalance: null,
      lpEq0: null,
      lpEq1: null,
      pair: null,
      lpBalance: "",
      button: <button onClick={() => ForceShowModal()}>CONNECT WALLET</button>,
      amtLoader:true
    };

    this.debounceRate = _.debounce(this.getRate, 500);
  }

  componentDidMount() {
    this.getPairInfo();
    axios
      .post(`${DEX_API}/get-token-price`)
      .then((resp) => {
        if (resp.status === 200) {
          const data: any = resp.data;
          this.props.FetchAssetPrice(data.data);
        }
      })
      .catch((e) => console.error(e));
  }

  componentDidUpdate(prevProps: PropsInterface) {
    if (
      prevProps.wallet.connected === false &&
      this.props.wallet.connected === true
    ) {
      this.updateLPBalance();
    }
  }

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };

  getPairInfo() {
    axios
      .post(`${DEX_API}/get-pair-info`, { pair: this.pair })
      .then((resp: any) => {
        if (resp && resp.data && resp.data.data) {
          const pair = resp.data.data;

          const _first = DEFAULT_TOKEN_LIST.filter(
            (x: any) => CompareAddressValue(x.address,pair.first.address)
          );
          const _second = DEFAULT_TOKEN_LIST.filter(
            (x: any) => CompareAddressValue(x.address,pair.second.address)
          );
          const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
          const second = _second.length > 0 ? _second[0].logo : DefaultLogo;

          this.setState({ pair, firstLogo: first, secondLogo: second });

          Promise.all([
            BalanceOfGeneral(pair.first.address, pair.address),
            BalanceOfGeneral(pair.second.address, pair.address),
          ]).then((balances: any) => {
            if (!balances[0]) return;
            const [bal0, bal1] = balances;
            this.setState(
              {
                firstBalance: bal0,
                secondBalance: bal1,
              },
              () => this.updateLPBalance()
            );
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  renderStats() {
    if (
      !this.state.pair ||
      _.isEmpty(this.state.pair) ||
      !this.props.assetPrice ||
      !this.props.addressPrice ||
      !this.state.firstBalance ||
      !this.state.secondBalance
    )
      return (
        <div className="stats">
          <div className="stat">
            <div className="stats-block">
              <div className="label-box">
                <div className="main-label">{this.renderLang("liquidity")}</div>
                <span className="amount">
                  <Skeleton width={100} height={20} />
                </span>
                <span className="amount">
                  <Skeleton width={100} height={15} />
                </span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("volume24H")}</div>
                <span className="amount">
                  <Skeleton width={100} height={20} />
                </span>
                <span className="amount">
                  <Skeleton width={100} height={15} />
                </span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("last_price")}</div>
                <span className="token-amount-usd">
                  <Skeleton width={200} height={20} />
                </span>
                <span className="token-amount-usd">
                  <Skeleton width={200} height={20} />
                </span>
              </div>
            </div>
            <div className="locked-token-text">
              {this.renderLang("total_token_loked")}
            </div>
            <div className="locked-tokens">
              <div className="token">
                <span>
                  <Skeleton width={50} height={30} borderRadius={10} />
                </span>
                <div className="token-data">
                  <span className="token-amount">
                    <Skeleton width={100} height={20} />
                  </span>
                  <span className="token-amount-usd">
                    <Skeleton width={100} height={15} />
                  </span>
                </div>
              </div>
              <div className="token">
                <span>
                  <Skeleton width={50} height={30} borderRadius={10} />
                </span>
                <div className="token-data">
                  <span className="token-amount">
                    <Skeleton width={100} height={20} />
                  </span>
                  <span className="token-amount-usd">
                    <Skeleton width={100} height={15} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    let liquidity = "-";

    const first = this.state.pair.first;
    const second = this.state.pair.second;

    let changeStr = <>-</>;


    const frmtFirstAddress = (Utils.fromXdcAddress(first.address) as string).toLowerCase()
    const frmtSecondAddress = (Utils.fromXdcAddress(second.address) as string).toLowerCase()


    if (
      this.props.addressPrice[frmtFirstAddress] &&
      this.props.addressPrice[frmtSecondAddress]
    ) {
      const changeNumber = (
        (this.props.addressPrice[frmtSecondAddress] * this.state.pair.change.token1 +
          this.props.addressPrice[frmtFirstAddress] * this.state.pair.change.token0) /
        100
      ).toFixed(2);
      if (parseFloat(changeNumber) > 0) {
        changeStr = (
          <span className="green">
            {changeNumber}% <FontAwesomeIcon icon={faArrowUp} />
          </span>
        );
      } else if (parseFloat(changeNumber) === 0.0) {
        changeStr = <span className="gray">{changeNumber} </span>;
      } else {
        changeStr = (
          <span className="red">
            {changeNumber}% <FontAwesomeIcon icon={faArrowDown} />
          </span>
        );
      }
    }

    const vol = this.state.pair.vol24.token0;
    let volUsd = "-";

    let firstBalanceUsd = "-";
    let secondBalanceUsd = "-";

    const firstBal = parseFloat(
      FromDecimals(this.state.firstBalance, first.decimals)
    );
    const secondBal = parseFloat(
      FromDecimals(this.state.secondBalance, second.decimals)
    );

    if (
      this.props.addressPrice[frmtFirstAddress] &&
      this.props.addressPrice[frmtSecondAddress]
    ) {

      const totUsd = (
        firstBal * this.props.addressPrice[frmtFirstAddress] +
        secondBal * this.props.addressPrice[frmtSecondAddress]
      ).toFixed(2)
      liquidity = totUsd;

      volUsd = (vol * this.props.addressPrice[frmtFirstAddress]).toFixed(2);

      firstBalanceUsd = (
        firstBal * this.props.addressPrice[frmtFirstAddress]
      ).toFixed(0);

      secondBalanceUsd = (
        secondBal * this.props.addressPrice[frmtSecondAddress]
      ).toFixed(0);

      const usdChange =
        this.props.addressPrice[frmtSecondAddress] * this.state.pair.change.token1 +
        this.props.addressPrice[frmtFirstAddress] * this.state.pair.change.token0;

      const changeNumber = ((usdChange / parseFloat(totUsd)) * 100).toFixed(0)

      if (parseFloat(changeNumber) > 0) {
        changeStr = (
          <span className="green">
            {changeNumber}% <FontAwesomeIcon icon={faArrowUp} />
          </span>
        );
      } else if (parseFloat(changeNumber) === 0.0) {
        changeStr = <span className="gray">{changeNumber} </span>;
      } else {
        changeStr = (
          <span className="red">
            {changeNumber}% <FontAwesomeIcon icon={faArrowDown} />
          </span>
        );
      }
    }

    if (this.state.pair.change)
      return (
        <div className="stats">
          <div className="stat">
            <div className="stats-block">
              <div className="label-box">
                <div className="main-label">{this.renderLang("liquidity")}</div>
                <span className="amount">{'$'}{Delimiter(liquidity)}</span>
                <span className="percentage">{changeStr}</span>
              </div>
              <div className="label-box">
                <div className="main-label">{this.renderLang("volume24H")}</div>
                <span className="amount">{Delimiter(vol.toFixed(3))} </span>
                <span className="percentage">{'$'}{Delimiter(volUsd)}</span>
              </div>
              <div className="label-box">
                  <div className="main-label">{this.renderLang("last_price")}</div>
                  <span className="token-amount-usd">
                  {RoundUptoSignificant(RemoveExpo(this.state.pair.lastPrice))} / {" "}
                 <span className="text-muted">$ {RoundUptoSignificant(RemoveExpo((this.state.pair.lastPrice * this.state.pair.price.token1)))}</span>
                </span>
                {/* <span className="token-amount-usd"><img src={this.state.firstLogo} alt="" className="img-stats-span" /> {this.state.pair.first.symbol} /  <img src={this.state.secondLogo}  className="img-stats-span" alt="" /> <span className="text-muted">{this.state.pair.second.symbol}</span></span> */}
                </div>
            </div>
            <div className="locked-token-text">
              {this.renderLang("total_token_loked")}
            </div>
            <div className="locked-tokens">
              <div className="token">
                <span>
                  <img src={this.state.firstLogo} alt="" /> {first.symbol}
                </span>
                <div className="token-data">
                  <span className="token-amount">
                    {Delimiter(firstBal.toFixed(2))}
                  </span>
                  <span className="token-amount-usd">
                    {'$'}{Delimiter(firstBalanceUsd)}
                  </span>
                </div>
              </div>
              <div className="token">
                <span>
                  <img src={this.state.secondLogo} alt="" />
                  {second.symbol}
                </span>
                <div className="token-data">
                  <span className="token-amount">
                    {Delimiter(secondBal.toFixed(2))}
                  </span>
                  <span className="token-amount-usd">
                    {'$'}{Delimiter(secondBalanceUsd)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }

  updateLPBalance() {
    if (!this.state.pair || !this.props.wallet.connected) return;

    GetLPFromTokens(
      this.state.pair.first.address,
      this.state.pair.second.address
    ).then(([lpBalance]) => {
      this.setState(
        {
          lpBalance: RoundUptoSignificant(FromDecimals(lpBalance as number))

        },
        () => this.getRate(),
      );
    });
  }

  getRate() {
    if (!this.state.pair || this.state.lpBalance === null) return;

    if (this.state.lpBalance === 0) {
      return;
    }

    GetTknFromLiquidity(
      ToDecimals(this.state.lpBalance),
      this.state.pair.first.address,
      this.state.pair.second.address
    )
      .then(([exists, tokenAmountA, tokenAmountB]) => {
        if (!exists) {
          return;
        }

        if (tokenAmountA === null || tokenAmountB === null) return;

        this.setState({
          lpEq0: (RoundUptoSignificant(
            FromDecimals(tokenAmountA, this.state.pair?.first.decimals)
          )),
          lpEq1: (RoundUptoSignificant(
            FromDecimals(tokenAmountB, this.state.pair?.second.decimals)
          )),
          amtLoader:false
        });
      })
      .catch(console.error);
  }

  handleLPBalance(e: any) {
    this.setState(
      {
        lpBalance: e.target.value,
      },
      () => this.debounceRate()
    );
  }

  renderHeader() {
    if (!this.state.pair || _.isEmpty(this.state.pair))
      return (
        <div className="text">
          <Skeleton width={250} height={50} />
        </div>
      );

    return (
      <>
        <div className="icon">
          <PairLogo
            frontImage={this.state.firstLogo}
            backImage={this.state.secondLogo}
          />
        </div>
        <div className="text">{`${this.state.pair.first.symbol} - ${this.state.pair.second.symbol}`}</div>
      </>
    );
  }

  render() {
    this.text = GetLanguage(this.context.language);


    const frmtFirstAddress = (Utils.fromXdcAddress(this.state.pair?.first.address as string) as string)?.toLowerCase()
    const frmtSecondAddress = (Utils.fromXdcAddress(this.state.pair?.second.address as string) as string)?.toLowerCase()


    const lpEq0Usd =
      this.state.lpEq0 &&
        this.props.addressPrice[frmtFirstAddress]
        ? RoundUptoSignificant(
          parseFloat(this.state.lpEq0) *
          this.props.addressPrice[frmtFirstAddress] +
          ""
        )
        : "";

    const lpEq1Usd =
      this.state.lpEq1 &&
        this.props.addressPrice[frmtSecondAddress]
        ? RoundUptoSignificant(
          parseFloat(this.state.lpEq1) *
          this.props.addressPrice[frmtSecondAddress] +
          ""
        )
        : "";

    let totalUsd: string | number = 0;

    if (!_.isNaN(parseFloat(lpEq1Usd)) && !_.isNaN(parseFloat(lpEq0Usd))) {
      const val = parseFloat(lpEq0Usd) + parseFloat(lpEq1Usd)
      totalUsd = RoundUptoSignificant(val + "");
    }

    if (_.isNaN(totalUsd)) totalUsd = 0;

    const lp0 = this.state.lpEq0 !== null ? Delimiter(this.state.lpEq0) : ""
    const lp1 = this.state.lpEq1 !== null ? Delimiter(this.state.lpEq1) : ""


    return (
      <div className="pool-info">
        <SEO
            keywords={["globiance", "globiancedex", "dex", "Manage info", "pool manage"]}
            title="Connect Wallet To View Pool Balance | GlobianceDEX"
            pathSlug='info/manage/'
            description="Connect your wallet to view your pool balance in GlonbiaceDEX."
        />
        <div className="header">
          <div className="title">{this.renderHeader()}</div>
        </div>
        <div className="trading-stats">
          {this.renderStats()}
          <div className="manage-pool-data">
            {this.props.wallet.connected === false ? (
              <>
                <h4 className="text-muted">
                  {this.text.manage_pool_connect_wallet}
                </h4>
                {this.state.button}
              </>
            ) : (
              <>
                <div className="current-lp">
                  <span>{this.text.manage_pool_lp_balance}</span>
                  <input
                    type="number"
                    value={this.state.lpBalance}
                    name="lpBalance"
                    placeholder={this.text.enterAmt}
                    onChange={this.handleLPBalance.bind(this)}
                  />
                  <FontAwesomeIcon icon={faEdit} />
                </div>
                <div className="pair-balance">
                  <div className="title">
                    {this.text.manage_pool_bal_for}{" "}
                    {this.state.pair?.first.symbol} -{" "}
                    {this.state.pair?.second.symbol}
                  </div>
                  {this.state.amtLoader ? <div className="skt-loader"><Skeleton width={"100%"} height={80} /></div>
                  :
                    <div className="pair">
                    <div className="first-pair">
                      <img src={this.state.firstLogo} alt="" />{" "}
                      <span>{lp0}</span>
                    </div>
                    <FontAwesomeIcon icon={faPlus} />
                    <div className="second-pair">
                      <img src={this.state.secondLogo} alt="" />{" "}
                      <span>{lp1}</span>
                    </div>
                    </div>
                  }

                </div>
                <div className="pair-balance">
                  <div className="title">
                    {this.text.manage_pool_USD} {this.state.pair?.first.symbol}{" "}
                    - {this.state.pair?.second.symbol}
                  </div>
                  {this.state.amtLoader ? <div className="skt-loader"><Skeleton width={"100%"} height={80} /></div>
                  :
                  <div className="pair">
                    <div className="first-pair">
                      <img src={this.state.firstLogo} alt="" />{" "}
                      <span>$ {Delimiter(lpEq0Usd)}</span>
                    </div>
                    <FontAwesomeIcon icon={faPlus} />
                    <div className="second-pair">
                      <img src={this.state.secondLogo} alt="" />{" "}
                      <span>$ {Delimiter(lpEq1Usd)}</span>{" "}
                    </div>
                  </div>
                  }
                </div>

                <div className="pair-balance">
                  <div className="title">
                    {this.renderLang("total_usd_est")}
                  </div>
                  {this.state.amtLoader ? <div className="skt-loader"><Skeleton width={"100%"} height={80} /></div>
                  :
                  <div className="pair">
                    <div className="first-pair">
                      <span>{'$'}{Delimiter(totalUsd)}</span>
                    </div>
                  </div>
                   }
                </div>
                {this.state.pair ? (
                  <div className="manage-group">
                    <Link
                      className="btn btn-outline"
                      to={`/swap?outputCurrency=${this.state.pair.second.symbol==='XDC'||this.state.pair.second.symbol==='WXDC'?this.state.pair.second.symbol:this.state.pair.second.address}&inputCurrency=${this.state.pair.first.symbol==='XDC'||this.state.pair.first.symbol==='WXDC'?this.state.pair.first.symbol:this.state.pair.first.address}`}
                    >
                      {this.text.swap}
                    </Link>
                    <Link
                      className="btn btn-outline"
                      to={`/add?outputCurrency=${this.state.pair.second.symbol==='XDC'||this.state.pair.second.symbol==='WXDC'?this.state.pair.second.symbol:this.state.pair.second.address}&inputCurrency=${this.state.pair.first.symbol==='XDC'||this.state.pair.first.symbol==='WXDC'?this.state.pair.first.symbol:this.state.pair.first.address}`}
                    >
                      {this.text.add_liquidity}
                    </Link>
                    <Link
                      className="btn btn-outline"
                      to={`/remove?outputCurrency=${this.state.pair.second.symbol==='XDC'||this.state.pair.second.symbol==='WXDC'?this.state.pair.second.symbol:this.state.pair.second.address}&inputCurrency=${this.state.pair.first.symbol==='XDC'||this.state.pair.first.symbol==='WXDC'?this.state.pair.first.symbol:this.state.pair.first.address}`}
                    >
                      {this.text.remove_liquidity}
                    </Link>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  wallet,
  theme,
  tokenList,
  assetPrice,
  addressPrice
}: {
  wallet: Wallet;
  theme: Theme;
  tokenList: TokenListType;
  assetPrice: AssetPrice;
  addressPrice:AddressPrice
}) {
  return { wallet, theme, tokenList, assetPrice, addressPrice };
}
export default connect(mapStateToProps, actions)(ManagePool);
