import React from "react";
import axios from "axios";
import { DEX_API, USDDecimals, Delimiter } from "../../helper/constant";
// import { ROUND_DECIMALS_AMOUNT, ROUND_DECIMALS_PAIR } from "../../helper/decimals";
import {Link} from 'react-router-dom';
import DefaultLogo from "../../assets/img/default.png";
import { DEFAULT_TOKEN_LIST } from "@globiance/default-token-list";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import LiquidityChart from "./LiquidityChart";
import VolumeChart from "./VolumeChart";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";
import { SEO } from "../common/SEO";
import Utils from "xdc3-utils";
import Skeleton from "react-loading-skeleton";
import CopyClipboard from "../common/CopyClipboard";
import { CompareAddressValue } from "../../helper/addresses";

const liquidityPairAPI = `${DEX_API}/get-top-pairs-by-liquidity`;
const liquidityTokenPairAPI = `${DEX_API}/get-top-tokens-by-liquidity`;
const volumePairAPI = `${DEX_API}/get-top-pairs-by-volume`;
const volumeTokenAPI = `${DEX_API}/get-top-tokens-by-volume`;


const Liqcolumns = [
  { dataField: "name", text: "Name" },
  { dataField: "liquidity", text: "Liquidity" },
  // { dataField: "liquidity7d", text: "Liquidity 7D (AVG)" },
  // { dataField: "liquidity1m", text: "Liquidity 1M (AVG)" },
];

const Volcolumns = [
  { dataField: "name", text: "Name" },
  { dataField: "volume", text: "Volume" },
  { dataField: "volume7d", text: "Volume (7D)" },
  { dataField: "volume1m", text: "Volume (1M)" },
];

const DataTableSpinner = () => <Skeleton count={3} height={60} />;
interface StateInterface {
  loading: boolean;
  liquidityChart: any;
  volumeChart: any;
  liquidityPair: any;
  liquidityTokenPair: any;
  volumePair: any;
  volumeToken: any;
  pairData: any;
}

class Analytics extends React.Component<StateInterface> {
  static contextType = LanguageContext;
  text: any;

  state: StateInterface = {
    loading: true,
    liquidityChart: [],
    volumeChart: [],
    liquidityPair: [],
    liquidityTokenPair: [],
    volumePair: [],
    volumeToken: [],
    pairData: [],
  };

  componentDidMount() {
    this.getLiquidityPair();
    this.getLiquidityToken();
    this.getVolumePair();
    this.getVolumeToken();
  }

  getLiquidityPair = () => {
    return axios
      .post(liquidityPairAPI)
      .then((res: any) => {
        const data = res.data.data;
        const liqPairData = [];
        for (const liqData of data) {
          const _first = DEFAULT_TOKEN_LIST.filter(
            (x) => CompareAddressValue(x.address, liqData.token0Address)
          );
          const _second = DEFAULT_TOKEN_LIST.filter(
            (x) => CompareAddressValue(x.address, liqData.token1Address)
          );
          const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
          const second = _second.length > 0 ? _second[0].logo : DefaultLogo;
          const symbols = [first, second];
          if(liqData.token0Name === 'WXDC'){liqData.token0Name = 'XDC';}
          if(liqData.token1Name === 'WXDC'){liqData.token1Name ='XDC';}
          const temp = {
            token0: liqData.token0,
            token1: liqData.token1,
            last7Token0: liqData.last7Token0,
            last7Token1: liqData.last7Token1,
            lastMonthToken0: liqData.lastMonthToken0,
            lastMonthToken1: liqData.lastMonthToken1,
            pair: liqData.pair,
            token0Name: liqData.token0Name,
            token1Name: liqData.token1Name,
            token0Price: liqData.token0Price,
            token1Price: liqData.token1Price,
            totalLiquidity: liqData.totalLiquidity,
            totalLiquidity7D:
              liqData.last7Token0 * liqData.token0Price +
              liqData.last7Token1 * liqData.token1Price,
            totalLiquidity1M:
              liqData.lastMonthToken0 * liqData.token0Price +
              liqData.lastMonthToken1 * liqData.token1Price,
            symbolImg: symbols,
          };
          liqPairData.push(temp);
        }
        this.setState({
          liquidityPair: liqPairData,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  getLiquidityToken = () => {
    return axios
      .post(liquidityTokenPairAPI)
      .then((res: any) => {
        const data = res.data.data;
        const liqToken = [];
        for (const liqTokenData of data) {
          const _first = DEFAULT_TOKEN_LIST.filter(
            (x) => CompareAddressValue(x.address,  liqTokenData.address)
          );

          const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
          if(liqTokenData.token === 'WXDC'){liqTokenData.token = 'XDC';}
          const temp = {
            totalToken: liqTokenData.totalToken,
            last7Token: liqTokenData.last7Token,
            lastMonthToken: liqTokenData.lastMonthToken,
            tokenPrice: liqTokenData.tokenPrice,
            address: liqTokenData.address,
            token: liqTokenData.token,
            totalLiquidity: liqTokenData.totalLiquidity,
            totalLiquidity7D: liqTokenData.last7Token * liqTokenData.tokenPrice,
            totalLiquidity1M:
              liqTokenData.lastMonthToken * liqTokenData.tokenPrice,
            symbolImg: first,
          };
          liqToken.push(temp);
        }
        this.setState({
          liquidityTokenPair: liqToken,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  getVolumePair = () => {
    return axios
      .post(volumePairAPI)
      .then((res: any) => {
        const data = res.data.data;
        const volPairData = [];
        for (const volData of data) {
          const _first = DEFAULT_TOKEN_LIST.filter(
            (x) => CompareAddressValue(x.address , volData.token0Address)
          );
          const _second = DEFAULT_TOKEN_LIST.filter(
            (x) => CompareAddressValue(x.address , volData.token1Address)
          );
          const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
          const second = _second.length > 0 ? _second[0].logo : DefaultLogo;
          const symbols = [first, second];
          if(volData.token0Name === 'WXDC'){volData.token0Name = 'XDC';}
          if(volData.token1Name === 'WXDC'){volData.token1Name ='XDC';}
          const temp = {
            total0: volData.total0,
            total1: volData.total1,
            last7Token0: volData.last7Token0,
            last7Token1: volData.last7Token1,
            lastMonthToken0: volData.lastMonthToken0,
            lastMonthToken1: volData.lastMonthToken1,
            token0Price: volData.token0Price,
            token1Price: volData.token1Price,
            token0Name: volData.token0Name,
            token1Name: volData.token1Name,
            pair: volData.pair,
            total: volData.total,
            totalVolume7D:
              volData.last7Token0 * volData.token0Price +
              volData.last7Token1 * volData.token1Price,
            totalVolume1M:
              volData.lastMonthToken0 * volData.token0Price +
              volData.lastMonthToken1 * volData.token1Price,
            symbolImg: symbols,
          };
          volPairData.push(temp);
        }
        this.setState({
          volumePair: volPairData,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  getVolumeToken = () => {
    return axios
      .post(volumeTokenAPI)
      .then((res: any) => {
        const data = res.data.data;
        const volToken = [];
        for (const volTokenData of data) {
          const _first = DEFAULT_TOKEN_LIST.filter(
            (x) => CompareAddressValue(x.address , volTokenData.address)
          );

          const first = _first.length > 0 ? _first[0].logo : DefaultLogo;
          if(volTokenData.token === 'WXDC'){volTokenData.token ='XDC';}
          const temp = {
            totalToken: volTokenData.totalToken,
            last7Token: volTokenData.last7Token,
            lastMonthToken: volTokenData.lastMonthToken,
            tokenPrice: volTokenData.tokenPrice,
            address: volTokenData.address,
            token: volTokenData.token,
            totalVolume: volTokenData.totalVolume,
            totalVolume7D: volTokenData.last7Token * volTokenData.tokenPrice,
            totalVolume1M:
              volTokenData.lastMonthToken * volTokenData.tokenPrice,
            symbolImg: first,
          };
          volToken.push(temp);
        }
        this.setState({
          volumeToken: volToken,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  renderLang = (textName: string) => {
    const langData = textName;
    const langText = this.text ? this.text[langData] : null;
    return langText;
  };


  render() {


    const liquidityPairDataTable = this.state.liquidityPair;
    const liquidityTokenDataTable = this.state.liquidityTokenPair;
    const volumePairDataTable = this.state.volumePair;
    const volumeTokenDataTable = this.state.volumeToken;
    const liquidityPairDataTableData = [];
    const liquidityTokenDataTableData = [];
    const volumePairDataTableData = [];
    const volumeTokenDataTableData = [];
    const paginationOption= {
                     sizePerPage: 5,
                     paginationSize: 3,
                     hideSizePerPage: true,
                     withFirstAndLast: false,
                     alwaysShowAllBtns: true,
                     hidePageListOnlyOnePage: true,
                  };

    this.text = GetLanguage(this.context.language);

    if (liquidityPairDataTable) {
      for (const data of liquidityPairDataTable) {
        const liq = {
          id: `top-liq-pair-${data.token0Name}-${data.token1Name}`,
          name: (
            <div className="img-group" key={data.totalLiquidity}>
              <Link to={`/info/pool/${Utils.toXdcAddress(data.pair)}`}>
              <img src={data.symbolImg[0]} alt="" />
              <img className="img2" src={data.symbolImg[1]} alt="" />{" "}
              {data.token0Name}-{data.token1Name}
              </Link>
            </div>
          ),
          liquidity: "$" + Delimiter(data.totalLiquidity.toFixed(USDDecimals)),
          // liquidity7d: "$" + Delimiter(data.totalLiquidity7D.toFixed(USDDecimals)),
          // liquidity1m: "$" + Delimiter(data.totalLiquidity1M.toFixed(USDDecimals)),
        };
        liquidityPairDataTableData.push(liq);
      }
    }

    if (liquidityTokenDataTable) {
      for (const data of liquidityTokenDataTable) {
        const liq = {
          id: `top-liq-token-${data.token}`,
          name: (

            <div className="img-group">
               <Link to={`/info/token/${Utils.toXdcAddress(data.address)}`}>
              <img src={data.symbolImg} alt="" /> {data.token}
              </Link>
            </div>
          ),
          liquidity: "$" + Delimiter(data.totalLiquidity.toFixed(USDDecimals)),
          // liquidity7d: "$" + Delimiter(data.totalLiquidity7D.toFixed(USDDecimals)),
          // liquidity1m: "$" + Delimiter(data.totalLiquidity1M.toFixed(USDDecimals)),
        };
        liquidityTokenDataTableData.push(liq);
      }
    }

    if (volumePairDataTable) {
      for (const data of volumePairDataTable) {
        const liq = {
          id: `top-volume-pair-${data.token0Name}-${data.token1Name}`,
          name: (
            <div className="img-group">
              <Link to={`/info/pool/${Utils.toXdcAddress(data.pair)}`}>
              <img src={data.symbolImg[0]} alt="" />
              <img className="img2" src={data.symbolImg[1]} alt="" />{" "}
              {data.token0Name}-{data.token1Name}
              </Link>
            </div>
          ),
          volume: "$" + Delimiter(data.total.toFixed(USDDecimals)),
          volume7d: "$" + Delimiter(data.totalVolume7D.toFixed(USDDecimals)),
          volume1m: "$" + Delimiter(data.totalVolume1M.toFixed(USDDecimals)),
        };
        volumePairDataTableData.push(liq);
      }
    }

    if (volumeTokenDataTable) {
      for (const data of volumeTokenDataTable) {
        const liq = {
          id: `top-volume-token-${data.token}`,
          name: (
            <div className="img-group">
              <Link to={`/info/token/${Utils.toXdcAddress(data.address)}`}>
              <img src={data.symbolImg} alt="" /> {data.token}
              </Link>
            </div>
          ),
          volume: "$" + Delimiter(data.totalVolume.toFixed(USDDecimals)),
          volume7d: "$" + Delimiter(data.totalVolume7D.toFixed(USDDecimals)),
          volume1m: "$" + Delimiter(data.totalVolume1M.toFixed(USDDecimals)),
        };
        volumeTokenDataTableData.push(liq);
      }
    }


    return (
      <div className="analytics">
        <SEO
          keywords={["globiance", "globiancedex", "dex", "analytics"]}
          title="Analytics | GlobianceDex"
          pathSlug="analytics"
          description="GlobianceDEX will help users get the deatils of top liquidity pairs, top liquidity tokens, top volume pairs and top volume tokens."
        />
        <div className="chart-wrapper">
          <LiquidityChart />
          <VolumeChart />
        </div>
        <div className="liquidityTable" >
          <h2 id="top-liquidity-pairs">{this.renderLang("top_liquidity_pair")} <CopyClipboard copyText="https://dex.globiance.com/analytics#top-liquidity-pairs" /></h2>
          <BootstrapTable
            keyField="id"
            // loading={true}
            pagination={paginationFactory(paginationOption)}
            data={liquidityPairDataTableData}
            columns={Liqcolumns}
            // sort={{ dataField: "liquidity", order: "desc" }}
            wrapperClasses=""
            noDataIndication={() => <DataTableSpinner />}
          />
          <h2 id="top-liquidity-tokens">{this.renderLang("top_liquidity_token")}  <CopyClipboard copyText="https://dex.globiance.com/analytics#top-liquidity-tokens" /></h2>
          <BootstrapTable
            keyField="id"
            // loading={true}
            data={liquidityTokenDataTableData}
            pagination={paginationFactory(paginationOption)}
            columns={Liqcolumns}
            // sort={{ dataField: "liquidity", order: "desc" }}
            // pagination={paginationFactory(options)}
            wrapperClasses=""
            noDataIndication={() => <DataTableSpinner />}
          />
          <h2 id="top-volume-pairs">{this.renderLang("top_volume_pair")} <CopyClipboard copyText="https://dex.globiance.com/analytics#top-volume-pairs" /></h2>
          <BootstrapTable
            keyField="id"
            // loading={true}
            data={volumePairDataTableData}
            pagination={paginationFactory(paginationOption)}
            columns={Volcolumns}
            // sort={{ dataField: "liquidity", order: "desc" }}
            // pagination={paginationFactory(options)}
            wrapperClasses=""
            noDataIndication={() => <DataTableSpinner />}
          />
          <h2 id="top-volume-tokens">{this.renderLang("top_volume_token")} <CopyClipboard copyText="https://dex.globiance.com/analytics#top-volume-tokens" /></h2>
          <BootstrapTable
            keyField="id"
            // loading={true}
            data={volumeTokenDataTableData}
            pagination={paginationFactory(paginationOption)}
            columns={Volcolumns}
            // sort={{ dataField: "liquidity", order: "desc" }}
            // pagination={paginationFactory(options)}
            wrapperClasses=""
            noDataIndication={() => <DataTableSpinner />}
          />
        </div>
      </div>
    );
  }
}

export default Analytics;
