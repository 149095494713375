import { useContext } from "react";
import { Link } from "react-router-dom";
import Utils from "xdc3-utils";
import { UserPool } from "../../types";
import { ChainIdToExplorer, DEFAULT_CHAIN_ID } from "../../helper/constant";

import PairLogo from "../common/PairLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";

export interface PoolDetails {
  pool: UserPool;
  loading?: boolean;
  removePool: () => {};
}

function PoolCard(props: PoolDetails) {
  const language = useContext(LanguageContext);
  const text: any = GetLanguage(language.language);

  return (
    <div className="pool-card">
      {props.pool.custom ? (
        <div className="action-rmv" onClick={() => props.removePool()}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      ) : (
        ""
      )}
      <div className="field header">
        <div className="label">
          <PairLogo
            frontImage={props.pool.first.logo}
            backImage={props.pool.second.logo}
          />
        </div>
        <div className="value">
          {props.pool.first.symbol}-{props.pool.second.symbol}
        </div>
      </div>

      {/* <div className="field">
        <div className="label">APY</div>
        <div className="value">{props.pool.apy} %</div>
      </div> */}

      <div className="field content">
        <div className="label">{text.lp_token}</div>
        <div className="value">
          {props.loading ? "..." : props.pool.balance || "-"}
        </div>
      </div>

      <div>
        <div className="pool-card__footer">
          <div className="info">
            <a
              className="btn"
              target="_blank"
              rel="noreferrer"
              href={`${
                ChainIdToExplorer[DEFAULT_CHAIN_ID]
              }/tokens/${Utils.toXdcAddress(props.pool.address)}`}
            >
              {text.pair_contract}
            </a>
          </div>

          <div className="action">
            <Link
              className="btn"
              to={`/info/pool/${Utils.toXdcAddress(props.pool.address)}`}
            >
              {text.info}
            </Link>
          </div>
          <div className="stake-btn-wrapper">
            <Link className="btn stake-btn" to={`/info/manage/${Utils.toXdcAddress(props.pool.address)}`}>{text.manage}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PoolCard;
