import React from 'react'
import { LanguageContext } from "../../Context/Language";
import { GetLanguage } from "../../assets/translations";

class Footer extends React.Component{
    static contextType = LanguageContext;
    text: any;

    render() {
        this.text = GetLanguage(this.context.language);
        const currentYear  = new Date().getFullYear();


        return (
            <footer className={`footer-section mb-30`}>
                <div className="copyright-content">
                    {this.text.footer_copyright_text.replace("2022",currentYear)}
                </div>
                <div className="copyright-content">
                    <a href="https://dexapi.globiance.com/Disclaimer_20.01.2023.docx" target="_blank">Disclaimer</a>
                </div>
                <div className="copyright-content">
                    <a href="https://dexapi.globiance.com/TermsOfService_20.01.2023.docx" target="_blank">Terms And Conditions</a>
                </div>
                <div className="social-links">
                    {/* <div className="social-icons">
                        <a rel="noreferrer" target="_blank" href="info@globiance.com">
                            <i className="fas fa-envelope"></i></a>
                    </div> */}
                    <div className="social-icons">
                        <a rel="noreferrer" target="_blank" href="https://t.me/globiancegroup">
                            <i className="fab fa-telegram-plane"></i></a>
                    </div>
                    <div className="social-icons">
                        <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCWcH7telVP7Laxh4LLZsrSg">
                            <i className="fab fa-youtube"></i></a>
                    </div>
                    <div className="social-icons">
                        <a rel="noreferrer" target="_blank" href="https://twitter.com/globiance">
                            <i className="fab fa-twitter"></i></a>
                    </div>
                    <div className="social-icons">
                        <a rel="noreferrer" target="_blank" href="https://www.facebook.com/Globiance/">
                            <i className="fab fa-facebook-f"></i></a>
                    </div>
                    <div className="social-icons">
                        <a rel="noreferrer" target="_blank" href="https://github.com/Globiance/">
                            <i className="fab fa-github"></i></a>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
