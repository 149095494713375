import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ChainIdToExplorer, DEFAULT_CHAIN_ID } from "../../helper/constant";
import Utils from "xdc3-utils";

interface PropInterface {
  address: any;
}

const AccountAddress = ({ address }: PropInterface) => {
  address = Utils.toXdcAddress(address)
  const truncatedAdd = `${address.toString().slice(0, 10)}...`;
  return (
    <div className="account-links">
      <a
        href={`${ChainIdToExplorer[DEFAULT_CHAIN_ID]}/address/${address}`}
        target="_blank"
        rel="noreferrer"
      >
        <span>
          {truncatedAdd} <FontAwesomeIcon icon={faExternalLinkAlt} />
        </span>
      </a>
    </div>
  );
};

export default AccountAddress;
