import axios from "axios";
import { DEX_API } from "../../../helper/constant";

const history = {};

const newApi = `${DEX_API}/get-trade-klines`;

const HistoryProvider = (address) => {
  return {
    history: history,

    getBars: function (symbolInfo, resolution, from, to) {
      return axios
        .post(newApi, {
          from: parseFloat(from) * 1000,
          pair: address,
        })
        .then((data) => {
          data = data.data.data;
          let bars = [];

          for (let cur of data) {
            const temp = {
              high: cur.high,
              low: cur.low,
              open: cur.open,
              close: cur.close,
              volume: cur.volume,
              time: new Date(cur.timestamp),
            };

            bars.push(temp);
          }
          return bars;
        });
    },
  };
};

export default HistoryProvider;
