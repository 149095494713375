import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faLink } from '@fortawesome/free-solid-svg-icons';

interface PropInterface {
    copyText: any;
  }
function CopyClipboard({copyText }: PropInterface) {
    const [text, setText] = useState('Copy')
    const handleCopyClick = () => {
        copyTextToClipboard(copyText)
    }

    async function copyTextToClipboard(text:any) {
        if ('clipboard' in navigator) {
          await navigator.clipboard.writeText(text);
          setText("Copied");
          setTimeout(() => {
            setText("Copy");
          }, 2000);
        } else {
          return document.execCommand('copy', true, text);
        }
      }

    return (
        <div className="clipboard">
            <div className="clip-pop">{text}</div>
            <input type="hidden" value={copyText} />
            <FontAwesomeIcon icon={faLink} onClick={handleCopyClick} className="copy-clipboard" />
        </div>
    )
}

export default CopyClipboard;
