import { MonthNames, USDDecimals, Delimiter } from "../../helper/constant";

const renderTotals = (total:any) => {
  if(total !== "0.00"){
    return (
        <p className="totalusd">{Delimiter(total.toFixed(USDDecimals))}</p>
    )
  }
}

const ToolTipInfo = ({ active, payload, label,chartType, chartDataSwitch } :any) => {
  if (active && payload && payload.length) {
    const tolldate = new Date(payload[0].payload._id);
    const cdate = `${
      MonthNames[tolldate.getMonth()]
    } ${tolldate.getDate()},'${tolldate.getFullYear()}`;

    if(chartType === 'liquidity' && chartDataSwitch === false){
      return (
        <div className="toolTip-wrapper">
          {renderTotals(payload[0].payload.total)}
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    }else if(chartType === 'volume' && chartDataSwitch === false){
      return (
        <div className="toolTip-wrapper">
          {renderTotals(payload[0].payload.total)}
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    }else if(chartType === 'volume' && chartDataSwitch === true){
      return (
        <div className="toolTip-wrapper">
          <p className="totalusd">${Delimiter(payload[0].payload.totalUSD.toFixed(USDDecimals))}</p>
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    }else if(chartType === 'liquidity' && chartDataSwitch === true){
      return (
        <div className="toolTip-wrapper">
          <p className="totalusd">${Delimiter(payload[0].payload.totalUSD.toFixed(USDDecimals))}</p>
          <p className="date-tooltip">{cdate}</p>
        </div>
      );
    }

  }
  return null;
};

export default ToolTipInfo;
