import { ChainIdToExplorer } from '../../helper/constant';
import * as types from "../actions/types";
import { provider as Provider } from 'xdc3-core';
import { VALID_CHAIN_TYPE } from '../../types';

export interface Wallet {
  connected: boolean,
  address: string,
  chain_id: VALID_CHAIN_TYPE | null,
  valid_network: boolean,
  explorer: string,
  loader: string,
  provider: Provider | null
}

const initialState = {
  connected: false,
  address: "",
  chain_id: null,
  valid_network: false,
  explorer: "",
  loader: "",
  provider: null
};


interface Payload {
  payload: any;
  type: string;
}


const WalletReducer = (state: Wallet = initialState, payload: Payload): Wallet => {
  switch (payload.type) {
    case types.WALLET_CONNECTED: {
      const { address, chain_id, loader, provider, valid_network }: { address: string, chain_id: VALID_CHAIN_TYPE, loader: string, provider: Provider, valid_network: boolean } = payload.payload;
      return {
        ...state,
        connected: true,
        address,
        chain_id,
        loader,
        explorer: ChainIdToExplorer[`${chain_id}`],
        provider,
        valid_network
      };
    }

    case types.WALLET_DISCONNECTED: {
      return { ...state, connected: false };
    }

    case types.WALLET_ADDRESS_CHANGED: {
      const { address } = payload.payload;
      return {
        ...state,
        address,
      };
    }

    case types.WALLET_CHAIN_CHANGED: {
      const { chain_id } = payload.payload;

      return {
        ...state,
        chain_id,
      };
    }

    case types.NETWORK_VALID: {
      return {
        ...state,
        valid_network: true,
      };
    }

    case types.NETWORK_INVALID: {
      return {
        ...state,
        valid_network: false,
      };
    }

    default:
      return state;
  }
};

export default WalletReducer;
